<script>
export default {
  name: "shopItem",
  props: {
    data: Object,
  },
  render() {
    return (
      <div
        class="shopItem"
        onclick={() => {
          this.$store.commit("MyModel/updateState", {
            integralId: this.data.id,
            integralState: "shopDetail",
          });
        }}
      >
        <img src={this.data.coverPhoto} />
        <h3>{this.data.title}</h3>
        <p>{this.data.subTitle}</p>
        <span>{this.data.scorePrice}积分</span>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.shopItem {
  width: 262px;
  display: flex;
  margin-right: 24px;
  margin-bottom: 20px;
  flex-direction: column;
  cursor: pointer;
  &:nth-child(3n) {
    margin-right: 0;
  }
  img {
    width: 262px;
    height: 167px;
    border-radius: 8px;
    object-fit: cover;
    margin-bottom: 12px;
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 10px;
  }
  p {
    color: #9ca6b2;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 15px;
  }
  span {
    display: block;
    height: 24px;
    background: #fdcbd4;
    border-radius: 8px;
    padding: 0 8px;
    font-size: 16px;
    font-weight: 500;
    color: #f94164;
    line-height: 24px;
    width: fit-content;
  }
}
</style>