<script>
import ModelBoxMy from "@components/ModelBoxMy.vue";
import VDistpicker from "v-distpicker";
export default {
  name: "baseInfo",
  components: { VDistpicker },
  data() {
    return {
      avatar: null,
      isEmailError: false,
      isShowPicker: false,
      morenaddress:'',
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("MyModel/getUserInfo");
    } catch (error) {
          if (error.code == 1016) {
            this.$message({ showClose: true, message: error.msg, type: "error" });
            window.$cookies.set("token",'')
          }
          return;
        }
    try {
      await this.$store.dispatch("MyModel/getCountryList");
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    try {
      await this.$store.dispatch("MyModel/getEnumList");
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    try {
      await this.$store.dispatch("MyModel/getUserAddressList");
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    const {
      countryList,
      userInfo,
      eduStateList,
      gradeList,
      baseInfoForm,
      userAddressList,
    } = this.$store.state.MyModel;
    // console.log(userAddressList)
    userAddressList.map((item) => {
      if (item.default == 1) {
        this.morenaddress = item.address
      }
    })
    this.$store.commit("MyModel/updateState", {
      baseInfoForm: {
        ...baseInfoForm,
        countryId: userInfo.countryId || countryList[0].id,
        eduStatusId: String(userInfo.eduStatusId) || eduStateList[0].value,
        gradeId: String(userInfo.gradeId) || gradeList[0].value,
        mobile: userInfo.mobile,
        nickname: userInfo.nickname,
        signature: userInfo.signature,
        region: userInfo.region,
        realName: userInfo.realName,
        email: userInfo.email,
      },
    });
    this.avatar = userInfo.head;
  },
  methods: {
    onNickNameInput(e) {
      const { baseInfoForm } = this.$store.state.MyModel;
      const { value } = e.target;
      this.$store.commit("MyModel/updateState", {
        baseInfoForm: {
          ...baseInfoForm,
          nickname: value,
        },
      });
    },
    onSignatureInput(e) {
      const { baseInfoForm } = this.$store.state.MyModel;
      const { value } = e.target;
      this.$store.commit("MyModel/updateState", {
        baseInfoForm: {
          ...baseInfoForm,
          signature: value,
        },
      });
    },
    onPhoneClick() {
      this.$store.commit("MyModel/updateState", { updatePhoneShow: true });
    },
    onEmailInput(e) {
      const { baseInfoForm } = this.$store.state.MyModel;
      const { value } = e.target;
      if (value === "") {
        this.isEmailError = false;
        return;
      }
      const reg = new RegExp(
        "^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$"
      );
      this.isEmailError = !reg.test(value);
      if (this.isEmailError) return;
      this.$store.commit("MyModel/updateState", {
        baseInfoForm: {
          ...baseInfoForm,
          email: value,
        },
      });
    },
    onEmailClick() {
      this.$store.commit("MyModel/updateState", { updateEmailShow: true });
    },
    onAddAddressClick() {
      this.$store.commit("MyModel/updateState", { infoState: "address" });
    },
    async onUploadChange(e) {
      const file = e.target.files[0];
      const type = file.type.split("/")[0];
      if (type === "image") {
        let param = new FormData();
        param.append("head", file);
        let msg;
        try {
          msg = await this.$store.dispatch("MyModel/upUserHead", param);
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
        this.$message({ showClose: true, message: msg, type: "success" });

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
          this.avatar = e.target.result;
        };
      } else {
        this.$message({
          showClose: true,
          message: "上传文件格式错误",
          type: "error",
        });
      }
    },
    onSelectPicker(data) {
      const { baseInfoForm } = this.$store.state.MyModel;
      this.$store.commit("MyModel/updateState", {
        baseInfoForm: {
          ...baseInfoForm,
          region: `${data.province.value} / ${data.city.value} / ${data.area.value}`,
        },
      });
      this.isShowPicker = false;
    },
    async onSaveClick() {
      const { baseInfoForm } = this.$store.state.MyModel;
      if (this.isEmailError) return;
      let msg;
      try {
        msg = await this.$store.dispatch(
          "MyModel/updateUserInfo",
          baseInfoForm
        );
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      try {
        msg = await this.$store.dispatch(
          "MyModel/completeUserInfo",
          baseInfoForm
        );
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.$message({ showClose: true, message: msg, type: "success" });
    },
  },
  render() {
    const {
      countryList,
      eduStateList,
      gradeList,
      baseInfoForm,
      userAddressList
    } = this.$store.state.MyModel;
    const modelData = {
      blue: "个人",
      yellow: "信息",
      content: () => (
        <div class="content">
          <div class="baseinfo-box">
            <div class="left">
              <div class="form-item">
                <div class="label">昵称</div>
                <div class="inputBox" style={{ width: "456px" }}>
                  <input
                    placeholder="这是一个ID"
                    maxLength="10"
                    value={baseInfoForm.nickname}
                    oninput={this.onNickNameInput}
                  />
                </div>
              </div>
              <div class="form-item">
                <div class="label">个性签名</div>
                <div class="inputBox" style={{ width: "456px" }}>
                  <input
                    placeholder="输入个性签名"
                    maxLength="20"
                    value={baseInfoForm.signature}
                    oninput={this.onSignatureInput}
                  />
                </div>
              </div>
              <div class="form-item">
                <div class="label">手机号码</div>
                <div
                  class="inputBox"
                  style={{ width: "456px" }}
                  onclick={this.onPhoneClick}
                >
                  <input
                    placeholder="请输入手机号码"
                    style={{ cursor: "pointer" }}
                    disabled="disabled"
                    value={baseInfoForm.mobile}
                  />
                </div>
              </div>
            </div>
            <div class="right">
              <div class="avatarBox">
                <img src={this.avatar} />
                <input
                  type="file"
                  name="pic1"
                  class="upload-pic-input"
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                  ref="upload"
                  style={{ display: "none" }}
                  onchange={this.onUploadChange}
                />
                <label
                  for="file"
                  class="btn"
                  onclick={() => this.$refs.upload.click()}
                >
                  修改头像
                </label>
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="label">意向国家/地区</div>
            {countryList.length ? (
              <div class="tagBox">
                {countryList.map((item) => (
                  <span
                    onclick={() => {
                      this.$store.commit("MyModel/updateState", {
                        baseInfoForm: {
                          ...baseInfoForm,
                          countryId: item.id,
                        },
                      });
                    }}
                    class={item.id === baseInfoForm.countryId ? "active" : ""}
                  >
                    {item.name}
                  </span>
                ))}
              </div>
            ) : null}
          </div>
          <div class="form-item">
            <div class="label">邮箱地址</div>
            <div class="inputBox email">
              <input
                type="email"
                placeholder="请输入邮箱地址"
                oninput={this.onEmailInput}
                value={baseInfoForm.email}
              />
              {this.isEmailError ? (
                <span class="error">
                  <i class="icon info" />
                  邮箱格式错误
                </span>
              ) : null}
            </div>
          </div>
          <div class="form-item">
            <div class="label">教育程度</div>
            {eduStateList.length ? (
              <div class="tagBox">
                {eduStateList.map((item) => (
                  <span
                    onclick={() => {
                      this.$store.commit("MyModel/updateState", {
                        baseInfoForm: {
                          ...baseInfoForm,
                          eduStatusId: item.value,
                        },
                      });
                    }}
                    class={
                      item.value === baseInfoForm.eduStatusId ? "active" : ""
                    }
                  >
                    {item.name}
                  </span>
                ))}
              </div>
            ) : null}
          </div>
          <div class="form-item">
            <div class="label">真实姓名</div>
            <div class="inputBox">
              <input
                placeholder="请输入真实姓名"
                value={baseInfoForm.realName}
                maxLength="10"
                oninput={(e) => {
                  const { value } = e.target;
                  this.$store.commit("MyModel/updateState", {
                    baseInfoForm: {
                      ...baseInfoForm,
                      realName: value,
                    },
                  });
                }}
              />
            </div>
          </div>
          {/*   <div class="form-item">
            <div class="label">身份证号</div>
            <div class="inputBox">
              <input placeholder="请输入身份证号" />
            </div>
          </div>
           */}
          <div class="form-item">
            <div class="label">所在区域</div>
            <div class={`inputBox ${this.isShowPicker ? "picker" : ""}`}>
              {!this.isShowPicker ? (
                <input
                  placeholder="选择所在省/市/区"
                  style={{ cursor: "pointer" }}
                  onclick={() => (this.isShowPicker = true)}
                  value={baseInfoForm.region}
                />
              ) : (
                <v-distpicker type="mobile" onselected={this.onSelectPicker} />
              )}
            </div>
          </div>
          <div class="form-item">
            <div class="label">意向阶段</div>
            {gradeList.length ? (
              <div class="tagBox">
                {gradeList.map((item) => (
                  <span
                    onclick={() => {
                      this.$store.commit("MyModel/updateState", {
                        baseInfoForm: {
                          ...baseInfoForm,
                          gradeId: item.value,
                        },
                      });
                    }}
                    class={item.value === baseInfoForm.gradeId ? "active" : ""}
                  >
                    {item.name}
                  </span>
                ))}
              </div>
            ) : null}
          </div>
          <div class="form-item">
            <div class="label">详细地址</div>
              <div class="inputBox address">
                {(this.morenaddress !== '')? (
                  <input
                    value={this.morenaddress}
                    style={{ width: "532px" }}
                  />
                  ) : (
                  <input
                    placeholder="请输入详细地址（xx街道xx小区x楼x单元xx室）"
                    style={{ width: "532px" }}
                  />
              )}
              </div>
            <span class="btn" onclick={this.onAddAddressClick}>
              常用地址管理
            </span>
          </div>
          <div class="btnGroup">
            <span class="confirmBtn" onclick={this.onSaveClick}>
              确认
            </span>
          </div>
        </div>
      ),
    };
    return <ModelBoxMy data={modelData} />;
  },
};
</script>

<style lang="less" scoped>
.content {
  .baseinfo-box {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    .left {
      .form-item {
        display: flex;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }

        .label {
          width: 117px;
          min-width: 117px;
          text-align: right;
          font-size: 18px;
          font-weight: 400;
          color: #000000;
          margin-right: 14px;
        }
        .inputBox {
          box-sizing: border-box;
          input {
            height: 30px;
          }
        }
      }
    }
    .right {
      margin-left: 47px;
      .avatarBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          object-fit: cover;
          margin-bottom: 12px;
        }
        .btn {
          width: 140px;
          height: 34px;
          border-radius: 10px;
          background: #006eeb;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
          line-height: 34px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
  .form-item {
    display: flex;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .label {
      width: 117px;
      min-width: 117px;
      text-align: right;
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
      margin-right: 14px;
    }
    .btn {
      padding: 0 20px;
      height: 30px;
      line-height: 30px;
      background: #006eeb;
      color: #ffffff;
      font-size: 16px;
      border-radius: 10px;
      margin-left: 16px;
      cursor: pointer;
    }
    .inputBox {
      border-radius: 10px;
      border: 1px solid #a4aab2;
      width: 272px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      input {
        height: 30px;
        flex: 1;
      }
    }
    .inputBox.picker {
      height: 300px;
      overflow-y: scroll;
    }
  }
  .tagBox {
    display: flex;
    flex-wrap: wrap;
    span {
      display: block;
      height: 30px;
      line-height: 30px;
      padding: 0 20px;
      border: 1px solid #a4aab3;
      border-radius: 10px;
      cursor: pointer;
      margin-bottom: 10px;
      margin-right: 14px;
    }
    span.active {
      background: #d9e9fc;
      border: 1px solid #006eeb;
      color: #006eeb;
    }
  }

  .btnGroup {
    display: flex;
    justify-content: center;
    .confirmBtn {
      display: block;
      padding: 0 70px;
      height: 45px;
      line-height: 45px;
      background: #006eeb;
      border-radius: 10px;
      color: #ffffff;
      font-size: 20px;
      cursor: pointer;
    }
  }
}

.error {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #f84164;
  line-height: 30px;
  flex: 1;
  justify-content: flex-end;
  .icon.info {
    width: 15px;
    height: 15px;
    background-image: url("../../../../assets/images/error-info.png");
    margin-right: 4px;
  }
}
</style>