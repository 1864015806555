<script>
// 名师在线
import ModelBox from "@components/ModelBox";
import Item from "./Item.vue";
export default {
  name: "teacherOnline",
  props: {
    list: Array,
  },
  data() {
    return {
      tabbarData: {
        list: [
          // 此ID根据枚举接口获取,不可更改
          { id: "8", value: "大咖入驻" },
          { id: "1", value: "国际教育规划师" },
          { id: "2", value: "海归顾问" },
        ],
        nowTab: "8",
      },
      jumpUrl: "/teacher/list?categoryId=8",
    };
  },
  methods: {
    async onTabClick(record) {
      this.jumpUrl = `/teacher/list?categoryId=${record.id}`;
      try {
        await this.$store.dispatch("LiveModel/getTeacherOnlineLiveList", {
          categoryId: record.id,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
  },
  render() {
    const modelBoxData = {
      blue: "名师",
      yellow: "在线",
      more: {
        title: "查看全部",
        onclick: () => {
          this.$router.push({ path: this.jumpUrl });
        },
      },
      tabbar: this.tabbarData,
      ontabclick: this.onTabClick,
      content: () => (
        <div class="main">
          {this.list.map((item) => (
            <Item live={true} data={{...item,nowTab:this.tabbarData.nowTab}} />
          ))}
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.main {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>