
<script>
export default {
  name: "detail",
  props: {
    data: Object,
  },
  methods: {
    async onCollectClick() {
      let msg;
      try {
        msg = await this.$store.dispatch("TeacherDetail/addOrDelUserCollect", {
          type: "teacher",
          productId: this.data.id,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.data.isCollect = !this.data.isCollect;
      this.$message({ showClose: true, message: msg, type: "success" });
    },
  },
  render() {
    return (
      <div class="detail-box">
        <img src={this.data.coverPhoto} />
        <div class="info-box">
          <p class="name">{this.data.name}</p>
          <p class="des">{this.data.position}</p>
          <p class="title">
            {this.data.tags && this.data.tags.split(",").map((item,index) => 
            `${item}${index+1!==this.data.tags.split(",").length?"/":""}`
            )}
          </p>
          <div class="detail" domPropsInnerHTML={this.data.introduction} />
          <div class="bottom">
            <div class="icon-box">
              <span>
                <i class="icon icon-eye" /> {this.data.viewTimes}
              </span>
              {/*<span>
                <i class="icon icon-collect" /> 306
              </span> */}
            </div>
            <div class="btn" onclick={this.onCollectClick}>
              <i
                class={`icon ${
                  this.data.isCollect ? "icon-collect-active" : "icon-collect"
                }`}
              />
              收藏
            </div>
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.detail-box {
  background: #ffffff;
  border-radius: 0px 16px 16px 0px;
  display: flex;
  margin-bottom: 24px;
  img {
    width: 560px;
    min-width: 560px;
    object-fit: cover;
  }
  .info-box {
    padding: 40px 36px 20px 36px;
    display: flex;
    flex-direction: column;
    width: 100%;
    .name {
      font-size: 30px;
      color: #1a1a1a;
      font-weight: bold;
    }
    .des {
      font-size: 18px;
      font-weight: 400;
      color: #8a9099;
    }
    .title {
      margin-top: 27px;
      font-size: 18px;
      font-weight: bold;
      color: #006eeb;
    }
    .detail {
      margin-top: 5px;
      padding: 12px 16px 20px;
      // height: 87px;
      background: #f8f8f8;
      border-radius: 8px;
      font-size: 14px;
      color: #8a9099;
      font-weight: 400;
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    .icon-box {
      padding-left: 16px;
      display: flex;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        line-height: 21px;
        font-size: 14px;
        font-weight: 400;
        color: #a4aab3;
        margin-right: 15px;
      }
    }
    .btn {
      display: block;
      width: 84px;
      height: 34px;
      background: #eff1f3;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 400;
      color: #525252;
      cursor: pointer;
    }
  }
}
.icon {
  background-size: 100%;
  background-repeat: no-repeat;
  display: block;
}
.icon-eye {
  width: 21px;
  height: 21px;
  background-image: url("../../assets/images/preview-open@2x.png");
  margin-right: 5px;
}
.icon-collect {
  width: 22px;
  height: 22px;
  background-image: url("../../assets/images/shoucang3.png");
  margin-right: 5px;
}
.icon-collect-active {
  width: 22px;
  height: 22px;
  background-image: url("../../assets/images/shoucang3-active.png");
  margin-right: 5px;
}
</style>