<script>
import Loading from "../../components/Loading";
import Swiper from "../../components/Swiper.vue";
import HotClass from "../../components/hotClass/index";
import HotSchool from "./hotSchool";
import StudyDest from "./studyDest/index";
import StudyProf from "./studyProf/index";
import UniversityRank from "./universityRank/index";
import HotStage from "./hotStage/index";
import CapacityUp from "./capacityUp/index";
import LookClass from "./lookClass/index";
import AbroadExam from "./abroadExam/index";
import Vip from "./vip/index";
import { randomNumber,GetQueryString,urldelCode,urlNocanshu } from "../../utils/index.js";
export default {
  name: "classPage",
  data() {
    return {
      StudyProfTab: "4", // 留学专业一级tab
      StudyProfSecondTab: "1", // 留学专业二级tab
    };
  },
  methods: {
    // 热门课程组件tab切换事件
    async onHotClassTabClick(record) {
      this.$store.commit("ClassModel/updateState", { isLoadingShow: true });
      await this.$store.dispatch("ClassModel/getCourseList", {
        page: 1,
        pageSize: 10,
        categoryId: record.id,
      });
      this.$store.commit("ClassModel/updateState", { isLoadingShow: false });
    },

    // 获取留学一级tab切换事件
    async onStudyProfTabClick(record) {
      this.StudyProfTab = record.id;
      this.$store.commit("ClassModel/updateState", { isLoadingShow: true });
      await this.$store.dispatch("ClassModel/getStudyAbroadList", {
        professionId: record.id,
        professionTypeId: this.StudyProfSecondTab,
      });
      this.$store.commit("ClassModel/updateState", { isLoadingShow: false });
    },

    // 获取留学二级tab切换事件
    async onStudyProfSecondTabClick(record) {
      this.StudyProfSecondTab = record.id;
      this.$store.commit("ClassModel/updateState", { isLoadingShow: true });
      await this.$store.dispatch("ClassModel/getStudyAbroadList", {
        professionId: this.StudyProfTab,
        professionTypeId: record.id,
      });
      this.$store.commit("ClassModel/updateState", { isLoadingShow: false });
    },

    // 热门阶段课程tab切换事件
    async onHotStageTabClick(record) {
      this.$store.commit("ClassModel/updateState", { isLoadingShow: true });
      await this.$store.dispatch("ClassModel/getHotStageCourseList", {
        page: 1,
        pageSize: 8,
        categoryId: "2",
        gradeId: record.id,
      });
      this.$store.commit("ClassModel/updateState", { isLoadingShow: false });
    },

    // 获取能力提升tab切换事件
    async onCapacityUpTabClick(record) {
      try {
        await this.$store.dispatch("ClassModel/getCapacityUpList", {
          page: 1,
          pageSize: 8,
          categoryId: record.id,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },

    // 看课指南tab切换事件
    async onLookCourseTabClick(record) {
      this.$store.commit("ClassModel/updateState", { isLoadingShow: true });
      await this.$store.dispatch("ClassModel/getLookCourseList", {
        id: record.id,
      });
      this.$store.commit("ClassModel/updateState", { isLoadingShow: false });
    },

    // 出国考试tab切换事件
    async onAbroadExamTabClick(record) {
      try {
        await this.$store.dispatch("ClassModel/getAbroadExamList", {
          page: 1,
          pageSize: 8,
          productId: record.id,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },

    // VIP专区tab切换事件
    async onVipTabClick(record) {
      try {
        await this.$store.dispatch("ClassModel/getVipList", {
          page: 1,
          pageSize: 8,
          categoryId: record.id,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    if (window.$cookies.get("token")) {
        urldelCode()
    }
    this.$store.commit("ClassModel/updateState", { isLoadingShow: true });
    await this.$store.dispatch("ClassModel/getPageInfoList");
    await this.$store.dispatch("ClassModel/getCourseList", {
      page: 1,
      pageSize: 10,
      categoryId: "1", // 默认获取专业
    });
    await this.$store.dispatch("ClassModel/getStudyAbroadList", {
      professionId: "4",
      professionTypeId: "1",
    });
    await this.$store.dispatch("ClassModel/getHotStageCourseList", {
      page: 1,
      pageSize: 8,
      categoryId: "2",
      gradeId: "5",
    });
    try {
        await this.$store.dispatch("ClassModel/recordAllPage", {
          app:3, 
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pvId:randomNumber(),
          medium: window.$cookies.get("datachannelmed") || '',
          extendurl: window.$cookies.get("dataurl") || '', 
          pageurl:urlNocanshu(),
          fullpageurl:window.location.href,
          accountId:window.$cookies.get("accountId") || ''
        });
    } catch (msg) {
      // this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    await this.$store.dispatch("ClassModel/getLookCourseList", { id: "139" });

    this.$store.commit("ClassModel/updateState", { isLoadingShow: false });

  },
  render() {
    const {
      isLoadingShow,
      swiperList,
      schoolList,
      hotCourseList,
      universityList,
      studyAbroadList,
      hotStageCourseList,
      capacityUpList,
      lookCourseList,
    } = this.$store.state.ClassModel;
    return (
      <div class="container">
        <Swiper list={swiperList} />
        <HotClass list={hotCourseList} tabclick={this.onHotClassTabClick} />
        <StudyDest />
        <StudyProf
          list={studyAbroadList}
          tabclick={this.onStudyProfTabClick}
          secondtabclick={this.onStudyProfSecondTabClick}
        />
        <HotSchool list={schoolList} />
        <UniversityRank list={universityList} />
        <HotStage
          list={hotStageCourseList}
          tabclick={this.onHotStageTabClick}
        />
        <CapacityUp
          list={capacityUpList}
          tabclick={this.onCapacityUpTabClick}
        />
        <LookClass list={lookCourseList} tabclick={this.onLookCourseTabClick} />
        <AbroadExam tabclick={this.onAbroadExamTabClick} />
        <Vip tabclick={this.onVipTabClick} />
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 1100px;
  margin: 0 auto;
  padding: 32px 0 64px;
}
</style>