<script>
// 直播回放
import ModelBox from "@components/ModelBox";
import Item from "./Item.vue";
export default {
  name: "record",
  render() {
    const {
      hotRecordList,
      newRecordList,
      userLiveBackList,
    } = this.$store.state.LiveModel;
    const modelBoxData = {
      blue: "直播",
      yellow: "回放",
      more: {
        title: "查看全部",
        onclick: () => {
          this.$router.push({ path: `/live/list?status=4` });
        },
      },
      content: () => (
        <div class="main">
          {userLiveBackList.length ? (
            <div class="listBox">
              <p>我的直播回放</p>
              <div class="list">
                {userLiveBackList.map((item) => (
                  <Item data={item} />
                ))}
              </div>
            </div>
          ) : null}
          <div class="listBox">
            <p>最新直播回放</p>
            <div class="list">
              {newRecordList.map((item) => (
                <Item data={item} />
              ))}
            </div>
          </div>
          <div class="listBox">
            <p>最热直播回放</p>
            <div class="list">
              {hotRecordList.map((item) => (
                <Item data={item} />
              ))}
            </div>
          </div>
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  .listBox {
    display: flex;
    flex-direction: column;
    margin-top: 13px;
    p {
      height: 48px;
      background: #e8f3ff;
      border-radius: 16px;
      font-size: 22px;
      color: #006eeb;
      font-weight: 400;
      line-height: 48px;
      text-align: center;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
    }
  }
}
</style>