<script>
export default {
  name: "commentItem",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="comment-item">
        <img src={this.data.head} />
        <div class="main">
          <p class="title">{this.data.nickname}</p>
          <p class="des">{this.data.content}</p>
          {/*  <div class="replyList" >
            <div class="replyItem">
              <span class="label">史蒂芬 回复 留学小助手：</span>
              <p>
                这是一条楼中楼回复这是一条楼中楼回复这是一条楼中楼回复这是一条楼中楼回复这是一条楼中楼回复这是一条楼中楼回复这是一条楼中楼回复这是一条楼中楼回复这是一条楼中楼回复这是一条楼中楼回复这是一条楼中楼回复这是一条楼中楼回复这是一条楼中楼回复这是一条楼中楼回复
              </p>
            </div>
          </div>
          */}
          <div class="timeBox">
            <span class="time">{this.data.createTime}</span>
            {/*<span class="btn">回复（1）</span>*/}
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.comment-item {
  display: flex;
  margin-bottom: 32px;
  img {
    width: 44px;
    height: 44px;
    object-fit: cover;
    border-radius: 50%;
  }
  .main {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    flex: 1;
    .title {
      font-size: 12px;
      font-weight: bold;
      color: #1a1a1a;
    }
    .des {
      font-size: 14px;
      font-weight: 400;
      color: #5c6066;
      margin-top: 8px;
    }
    .replyList {
      margin-top: 16px;
      padding: 14px 17px;
      background: #f3f6fa;
      border-radius: 8px;
      .replyItem {
        display: flex;
        font-size: 14px;
        margin-bottom: 10px;
        font-weight: 400;
        .label {
          color: #006eeb;
        }
        p {
          color: #5c6066;
          width: 70%;
        }
      }
    }
    .timeBox {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .time {
        font-size: 12px;
        font-weight: 400;
        color: #a4aab3;
      }
      .btn {
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
        color: #006eeb;
      }
    }
  }
}
</style>