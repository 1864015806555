<script>
export default {
  name: "schoolItem",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="box">
        <div class="imgBox">
          <img
            src={this.data.badge}
            onclick={() =>
              this.$router.push({
                path: `/university/detail?id=${this.data.id}`,
              })
            }
          />
        </div>
        <div class="content">
          <div>
            <p class="title">{this.data.name}</p>
            <p class="des">{this.data.abstract}</p>
          </div>
          <div>
            <div class="topBox">
              <span class="red">QS排名:{this.data.QsRanking}</span>
              <span class="yellow">USNEWS排名:{this.data.UsnewsRanking}</span>
            </div>
            {this.data.tags ? (
              <div class="taggroup">
                {this.data.tags.split(",").map((item) => (
                  <span>{item}</span>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.box {
  width: 514px;
  // height: 186px;
  box-sizing: border-box;
  padding: 16px;
  background: #f0f7ff;
  border-radius: 16px;
  display: flex;
  margin-right: 24px;
  margin-bottom: 24px;
  &:nth-child(2n) {
    margin-right: 0;
  }
  .imgBox {
    width: 124px;
    height: 154px;
    background: #fff;
    border-radius: 8px;
    align-items: center;
    display: flex;
  }
  img {
    width: 124px;
    height: 124px;
    object-fit: cover;
    cursor: pointer;
  }
  .content {
    margin-left: 18px;
    height: 154px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      font-size: 20px;
      color: #262626;
      font-weight: 400;
      margin-top: 5px;
    }
    .des {
      margin-top: 8px;
      font-size: 14px;
      color: #8a9099;
      font-weight: 400;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .topBox {
      width: 261px;
      height: 31px;
      background: #ffffff;
      border-radius: 8px;
      padding: 0 14px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 16px;
        font-weight: 400;
        position: relative;
      }

      span.red {
        color: #f94164;
        padding-right: 12px;
        &::before {
          display: block;
          content: "";
          width: 1px;
          height: 17px;
          background: #c4cbd2;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      span.yellow {
        color: #ff833e;
        margin-left: 13px;
      }
    }
    .taggroup {
      margin-top: 12px;
      display: flex;
      span {
        display: block;
        height: 23px;
        border: 1px solid #006eeb;
        border-radius: 8px;
        font-size: 16px;
        color: #006eeb;
        padding: 0 9px;
        margin-right: 5px;
      }
    }
  }
}
</style>