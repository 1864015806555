<script>
import ModelBoxMy from "@components/ModelBoxMy.vue";
import Pagination from "@components/Pagination.vue";
import Loading from "@components/Loading";
import Report from "./Report";
import Sign from "./Sign";
import Show from "./Show";
import activityShow from "./activityShow";
export default {
  name: "myPrize",
  data() {
    return {
      tabbarData: {
        list: [
          // 此ID根据枚举接口获取,不可更改
          { id: 1, value: "报告获取" },
          { id: 2, value: "活动抽奖" },
          { id: 3, value: "逛展抽奖" },
          { id: 4, value: "签约抽奖" },
        ],
        nowTab: 1,
      },
    };
  },
  methods: {
    onTabClick(record) {
      // this.tabbarData.nowTab = record.id;
    },
  },
  render() {
    const modelData = {
      blue: "我的",
      yellow: "奖品",
      tabbar: this.tabbarData,
      ontabclick: this.onTabClick,
      content: () => (
        <div>
          {this.tabbarData.nowTab === 1 ? <Report /> : null}
          {this.tabbarData.nowTab === 2 ? <activityShow /> : null}
          {this.tabbarData.nowTab === 3 ? <Show /> : null}
          {this.tabbarData.nowTab === 4 ? <Sign /> : null}
        </div>
      ),
    };
    return <ModelBoxMy data={modelData} />;
  },
};
</script>