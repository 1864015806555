
<script>
export default {
  name: "Info",
  props: {
    data: Object,
  },
  methods: {
    copy(id) {
      const spanText = document.getElementById(id).innerText;
      const oInput = document.createElement("input");
      oInput.value = spanText;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$message({
        showClose: true,
        message: "复制成功！",
        type: "success",
      });
    },
  },
  render() {
    return (
      <div class="infoBox">
        <div class="box">
          <p class="label title">订单信息</p>
        </div>
        <div class="box">
          <p class="label">订单号</p>
          <div class="orderNum">
            <span id="copy">{this.data.number}</span>
            <span class="btn blue" onclick={() => this.copy("copy")}>
              复制
            </span>
          </div>
        </div>
        <div class="box">
          <p class="label">下单时间</p>
          <div class="time">{this.data.createTime}</div>
        </div>
        {this.data.state === 8 ? (
          <div class="box">
            <p class="label">付款时间</p>
            <div class="time">{this.data.payTime}</div>
          </div>
        ) : null}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.infoBox {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-bottom: 1px solid #dce0e5;
  .box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    .label {
      font-size: 18px;
      font-weight: bold;
      color: #1a1a1a;
    }
    .label.title {
      font-size: 20px;
    }
    .orderNum {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      color: #cecece;
      .btn {
        display: block;
        height: 28px;
        line-height: 28px;
        border-radius: 16px;
        background: #dae9fc;
        font-size: 16px;
        color: #066eeb;
        padding: 0 17px;
        margin-left: 24px;
        cursor: pointer;
      }
    }
    .time {
      font-size: 18px;
      font-weight: 500;
      color: #cecece;
    }
  }
}
</style>