<script>
import { randomNumber,GetQueryString,urlNocanshu} from "../../../utils/index.js";
export default {
  name: "Video",
  props: {
    data: Object,
  },
  data() {
    return {
      video: null,
      isPlay: false,
      pvId:randomNumber(),
    };
  },
  async mounted() {
    // if (this.data.mode === 1) {
    //   try {
    //     await this.$store.dispatch("LiveDetailModel/getLiveUrl", { id });
    //   } catch (msg) {
    //     this.$message({ showClose: true, message: msg, type: "error" });
    //     return;
    //   }
    // }
  },
  methods: {
    async videoPlay() {
      const { detailData } = this.$store.state.LiveDetailModel;
      const { detail } = detailData;
      const { id,name } = detail;
      if (!window.$cookies.get("token")) {
        this.$store.commit("LoginModel/updateState", {
          isShowLogin: true,
          loginState: "login",
        });
        return;
      }
      // 正在直播
      this.$router.push({ path: `/living?id=${this.data.id}` });
    },
    async buyCourse(id) {
      if (!window.$cookies.get("token")) {
        this.$store.commit("LoginModel/updateState", {
          isShowLogin: true,
          loginState: "login",
        });
        return;
      }
      this.$router.push({ path: `/order/info?id=${id}&type=${"live"}` });
    },

    // 添加or取消预约
    async userSubscribe(record) {

	  if (!window.$cookies.get("token")) {
	    this.$store.commit("LoginModel/updateState", {
	      isShowLogin: true,
	      loginState: "login",
	    });
	    return;
	  }
      let msg;
      try {
        msg = await this.$store.dispatch(
          "LiveDetailModel/addOrDelLiveSubscribe",
          {
            id: record.id,
          }
        );
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }

      const { detailData } = this.$store.state.LiveDetailModel;
      const { detail } = detailData;
      const { id,name } = detail;
      try {
          await this.$store.dispatch("LiveDetailModel/recordDetailPage", {
            app:3, 
            module:1,
            datatype:2,
            contentTitle:name,
            uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
            pvId:this.pvId,
            project:'',
            country:'',
            contentId: id,
            medium: window.$cookies.get("datachannelmed") || '',
            extendurl: window.$cookies.get("dataUrl") || '',
            pageurl:urlNocanshu(),
            accountId:window.$cookies.get("accountId") || ''
          });
        } catch (msg) {
          // this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }

      this.$message({ showClose: true, message: msg, type: "success" });
    },

    renderBtn() {
      const { discountType, buyed, status, isSubscribe, mode } = this.data;
      
      if (window.$cookies.get("token")) {
const { userInfo } = this.$store.state.MyModel;
const { grade } = userInfo;
        if (!Number(this.data[`price${grade + 1}`])) {
          // 免费直播
          if (status === 1)
            return (
              <span
                class="btn blue"
                onclick={() => this.userSubscribe(this.data)}
              >
                {isSubscribe ? "取消预约" : "预约直播"}
              </span>
            );
          if (status === 2)
            return <i class="icon-play" onclick={this.videoPlay} />;
          if (status === 3) return <span class="btn blue">直播已结束</span>;
          if (status === 4)
            return (
              <span
                class="btn blue"
                onclick={() =>
                  this.$router.push({
                    path: `/course/detail?id=${this.data.backVideo}`,
                  })
                }
              >
                观看回放
              </span>
            );
        } else {
          // 付费直播
          if (buyed) {
            return status === 1 ? (
              <span
                class="btn blue"
                onclick={() => this.userSubscribe(this.data)}
              >
                {isSubscribe ? "取消预约" : "预约直播"}
              </span>
            ) : (
              <i class="icon-play" onclick={this.videoPlay} />
            );
          } else {
            return (
              <span class="btn red" onclick={() => this.buyCourse(this.data.id)}>
                立即购买
              </span>
            );
          }
        }
      }

      if (!window.$cookies.get("token")) {
        if (!Number(this.data[`price`])) {
          // 免费直播
          if (status === 1)
            return (
              <span
                class="btn blue"
                onclick={() => this.userSubscribe(this.data)}
              >
                {isSubscribe ? "取消预约" : "预约直播"}
              </span>
            );
          if (status === 2)
            return <i class="icon-play" onclick={this.videoPlay} />;
          if (status === 3) return <span class="btn blue">直播已结束</span>;
          if (status === 4)
            return (
              <span
                class="btn blue"
                onclick={() =>
                  this.$router.push({
                    path: `/course/detail?id=${this.data.backVideo}`,
                  })
                }
              >
                观看回放
              </span>
            );
        }
      }
    },
  },
  render() {
    return (
      <div class="videoBox">
        {this.data.mode === 1 ? (
          <div class="shadow">{this.renderBtn()}</div>
        ) : null}
        <video
          ref="videoRef"
          poster={this.data.coverPhoto}
          controlslist="nodownload"
        />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.videoBox {
  width: 750px;
  height: 420px;
  position: relative;
  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.icon-play {
  display: block;
  width: 83px;
  height: 83px;
  background-image: url("../../../assets/images/video-play.png");
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}
.btn {
  display: block;
  width: 204px;
  height: 50px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}
.btn.red {
  background: #f94164;
  color: #ffffff;
}
.btn.blue {
  background: #006eeb;
  color: #ffffff;
}
</style>