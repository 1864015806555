<script>
export default {
  name: "teacherItem",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="teacherItem">
        <div class="photo">
          <img
            src={this.data.head}
            onclick={() =>
              this.$router.push({
                path: `/teacher/detail?id=${this.data.teacherId}`,
              })
            }
          />
        </div>
        <div class="content">
          <div class="infoBox">
            <p class="teachName">{this.data.teacherName}</p>
            <p class="teachTitle">{this.data.abstract}</p>
          </div>
          <div>
            <div class="classBox">
              <div
                class="item"
                onclick={() =>
                  this.$router.push({ path: `/live/detail?id=${this.data.id}` })
                }
              >
                <div class="className">
                  <i class="icon-play" />
                  <span>{this.data.name}</span>
                </div>
                {/* <div class="price free">免费</div>*/}
              </div>
            </div>
            <div
              class="allClass"
              onclick={() =>
                this.$router.push({
                  path: `/teacher/detail?id=${this.data.teacherId}`,
                })
              }
            >
              {"查看老师全部课程 >"}
            </div>
          </div>
        </div>
      </div>
    );
  },
};
</script>

<style lang="less" scoped>
.teacherItem {
  width: 514px;
  background: #f0f7ff;
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-right: 24px;
  margin-bottom: 24px;
  &:nth-child(2n) {
    margin-right: 0;
  }
  .photo {
    width: 124px;
    height: 154px;
    overflow: hidden;
    border-radius: 8px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
      background: #fff;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .infoBox {
      display: flex;
      flex-direction: column;
      width: 340px;
      .teachName {
        font-size: 20px;
        font-weight: 400;
        color: #262626;
      }
      .teachTitle {
        margin-top: 5px;
        font-size: 14px;
        font-weight: 400;
        color: #8a9099;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        width: 300px;
      }
    }
    .classBox {
      display: flex;
      flex-direction: column;
      width: 340px;
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0px;
        }
        .className {
          display: flex;
          align-items: center;
          .icon-play {
            display: block;
            width: 15px;
            height: 15px;
            background-image: url("../../../../assets/images/icon-player.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-right: 7px;
          }
          span {
            width: 250px;
            font-size: 16px;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #262626;
          }
        }
        .price {
          font-size: 16px;
          font-weight: bold;
          color: #ff833e;
        }
        .price.free {
          color: #51b3ac;
        }
      }
    }
    .allClass {
      cursor: pointer;
      background: #006eeb;
      border-radius: 12px;
      font-weight: 400;
      color: #ffffff;
      font-size: 14px;
      height: 24px;
      padding: 0 13px;
      line-height: 24px;
      width: fit-content;
      margin-top: 14px;
    }
  }
}
</style>