<script>
import MyInfo from "./myInfo/index";
import NavBar from "./navBar/index";
import { randomNumber,GetQueryString,urldelCode,urlNocanshu } from "../../utils/index.js";
export default {
  name: "My",
  async mounted() {
    if (window.$cookies.get("token")) {
        urldelCode()
    }
    try {
        await this.$store.dispatch("MyModel/recordAllPage", {
          app:3, 
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pvId:randomNumber(),
          medium: window.$cookies.get("datachannelmed") || '',
          extendurl: window.$cookies.get("dataurl") || '',
          pageurl:urlNocanshu(),
          fullpageurl:window.location.href,
          accountId:window.$cookies.get("accountId") || ''
        });
      } catch (msg) {
        // this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
  },
  render() {
    return (
      <div class="container">
        <MyInfo />
        <div class="main">
          <NavBar />
          <router-view />
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 1100px;
  margin: 0 auto;
  padding: 32px 0 64px;
  .main {
    margin-top: 24px;
    display: flex;
    align-items: flex-start;
  }
}
</style>