<script>
import Video from "../video";
import VipRule from "@components/VipRule.vue";
import PopQr from "@components/PopQr.vue";
export default {
  name: "videoInfo",
  props: {
    data: Object,
    seriesId:String,
    list: Array,
  },
  data() {
    return {
      isVipRuleShow: false,
      isPopQrShow: false,
      id: "" /*弹出层二维码扫描跳转地址id*/,
      type: "" /*type为1，我的奖品留学报告; type为2，直播; type为3，课堂课程*/,
    };
  },
  // async mounted() {
  //   console.log(this.seriesId,"AAA")
  // },
  methods: {
    // 点击收藏
    async onCollectClick() {
      const { detailData } = this.$store.state.ClassDetailModel;
      const { id } = detailData.detail;
      if (!window.$cookies.get("token")) {
        this.$store.commit("LoginModel/updateState", {
          isShowLogin: true,
          loginState: "login",
        });
        return;
      }
      let msg = null;
      try {
        msg = await this.$store.dispatch(
          "ClassDetailModel/addOrDelUserCollect",
          {
            type: "course",
            productId: id,
          }
        );
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.$message({ showClose: true, message: msg, type: "success" });
      this.$store.commit("ClassDetailModel/updateState", {
        detailData: {
          ...detailData,
          detail: {
            ...detailData.detail,
            isCollect: !detailData.detail.isCollect,
          },
        },
      });
    },
    toIntegralShop() {
      if (!window.$cookies.get("token")) {
        this.$store.commit("LoginModel/updateState", {
          isShowLogin: true,
          loginState: "login",
        });
        return;
      }
      this.$router.push({ path: `/my/shop` });
    },
    viewClass(id, type) {
      this.isPopQrShow = true;
      this.id = id;
      this.type = type;
    },
    renerDom() {
      const { userInfo } = this.$store.state.MyModel;
      const grade = userInfo ? userInfo.grade : 0;
	  // const grade = 3
      //console.log(this.data);
      if (window.$cookies.get("token")) {
        if (Number(this.data[`price${grade + 1}`])) {
          return (
            <div class={`purchase-box ${this.data.buyed ? "green" : ""}`}>
              <div class="left">
                <div class="nowPrice">
                  价格：
                  <span>{this.data[`price${grade + 1}`]}</span>
                </div>
                {this.data[`price`] ===
                this.data[`price${grade + 1}`] ? null : (
                  <div class="price">原价：{this.data.price}</div>
                )}
                <span class="tag">{this.data.buyed ? "已购买" : "未购买"}</span>
              </div>
              {this.data[`price${grade + 1}`] ===
              this.data[`price${grade + 2}`] ? null : (
			  grade === 3? null : (
				  <div class="right">
                  <div class="info-box">
                    {grade + 1 === 1 ? "留学探路者" : ""}
                    {grade + 1 === 2 ? "留学领队" : ""}
                    {grade + 1 === 3 ? "留学践行者" : ""}
                    {this.data[`price${grade + 2}`] === "0.00"
                      ? "免费"
                      : `${this.data[`price${grade + 2}`]}元`}
                    观看
                    <span onclick={() => (this.isVipRuleShow = true)}>
                      点击查看如何成为{grade + 1 === 1 ? "留学探路者" : ""}
                      {grade + 1 === 2 ? "留学领队" : ""}
                      {grade + 1 === 3 ? "留学践行者" : ""}>
                    </span>
                  </div>
                  {/*  <div class="info-box">
                积分商城兑换免费观看
                <span onclick={this.toIntegralShop}>立即前往积分商城兑换></span>
              </div>*/}
                </div>
				)
				
              )}
            </div>
          );
        }
      } else {
        if (Number(this.data[`price`])) {
          return (
            <div class={`purchase-box`}>
              <div class="left">
                <div class="nowPrice">
                  价格：
                  <span>{this.data.price}</span>
                </div>
                {/* <div class="price">原价：{this.data.price}</div>*/}
                <span class="tag">未购买</span>
              </div>
              {this.data[`price`] === this.data[`price1`] ? null : (
                <div class="right">
                  <div class="info-box">
                    {"留学探路者"}
                    {this.data[`price1`] === "0.00"
                      ? "免费"
                      : `${this.data[`price2`]}元`}
                    观看
                    <span>点击查看如何成为{"留学探路者"}></span>
                  </div>
                  {/* <div class="info-box">
                积分商城兑换免费观看
                <span onclick={this.toIntegralShop}>立即前往积分商城兑换></span>
              </div>*/}
                </div>
              )}
            </div>
          );
        }
      }
    },
  },

  render() {
    return (
      <div class="video-container">
        <Video seriesId = {this.seriesId} data={this.data} list={this.list} />
        <div class="content">
          <p class="title">{this.data.name}</p>
          <div class="info">
            <div class="info-left">
              <p>
                <i class="icon time" />
                {this.data.createTime}
              </p>
              <p>
                <i class="icon eye" />
                {this.data.viewTimes}人观看
              </p>
              {this.data.tags &&
                this.data.tags
                  .split(",")
                  .map((item) => <span class="tag">{item}</span>)}
            </div>
            <div class="info-right">
              <span class="btn" onclick={this.onCollectClick}>
                <i
                  class={`icon collect ${this.data.isCollect ? "active" : ""}`}
                />
                收藏
              </span>
              <span
                class="btn"
                onclick={() => this.viewClass(this.data.id, "3")}
              >
                <i class="icon share" />
                分享
              </span>
            </div>
          </div>
        </div>
        {this.renerDom()}
        {this.isVipRuleShow ? (
          <VipRule
            isShow={this.isVipRuleShow}
            closeFunc={() => (this.isVipRuleShow = false)}
          />
        ) : null}
        {this.isPopQrShow ? (
          <PopQr
            isShow={this.isPopQrShow}
            closeFunc={() => (this.isPopQrShow = false)}
            id={this.id}
            typeId={this.type}
            title={'微信扫一扫，立即分享'}
            desc={'进入启德课堂小程序完成分享'}
          />
        ) : null}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.video-container {
  padding: 24px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  .content {
    margin-top: 26px;
    border-bottom: 1px solid #dce0e6;
    padding-bottom: 24px;
    .title {
      font-size: 22px;
      color: #1a1a1a;
      font-weight: 400;
    }
    .info {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .info-left {
        display: flex;
        align-items: center;
        p {
          font-size: 14px;
          display: flex;
          align-items: center;
          color: #a4aab3;
          margin-right: 15px;
        }
        .tag {
          display: block;
          background: #dbebfc;
          height: 24px;
          border-radius: 8px;
          padding: 0 13px;
          font-size: 14px;
          color: #006eeb;
          margin-right: 8px;
          line-height: 24px;
        }
      }

      .info-right {
        display: flex;
        align-items: center;
      }
    }
  }
  .purchase-box {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dce0e6;
    padding: 20px 0;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      .nowPrice {
        font-size: 20px;
        font-weight: bold;
        color: #1a1a1a;
        margin-right: 10px;
        display: flex;
        align-items: center;
        span {
          font-size: 33px;
          font-weight: bold;
          color: #f94164;
        }
      }
      .price {
        font-size: 16px;
        font-weight: 500;
        color: #a4aab3;
        margin-right: 23px;
      }
      .tag {
        display: block;
        width: 80px;
        height: 24px;
        background: #fee4e9;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #f94164;
        line-height: 24px;
        text-align: center;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      .info-box {
        display: flex;
        align-items: center;
        border: 1px solid #f94164;
        padding-left: 9px;
        border-radius: 12px;
        height: 24px;
        font-size: 14px;
        font-weight: 400;
        color: #f94164;
        box-sizing: border-box;
        justify-content: space-between;
        span {
          display: block;
          margin-left: 21px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          background: #f94164;
          border-radius: 12px;
          height: 24px;
          line-height: 24px;
          padding: 0 13px;
          cursor: pointer;
        }
      }
    }
  }
  .purchase-box.green {
    .left {
      .nowPrice {
        span {
          color: #51b3ac;
        }
      }
      .tag {
        background: #e6f4f3;
        color: #51b3ac;
      }
    }
    .right {
      .info-box {
        border: 1px solid #51b3ac;
        color: #51b3ac;
        span {
          background: #51b3ac;
        }
      }
    }
  }
}
.icon.time {
  width: 14px;
  height: 14px;
  background-image: url("../../../assets/images/time.png");
  margin-right: 7px;
}
.icon.eye {
  width: 19px;
  height: 13px;
  background-image: url("../../../assets/images/detail-eye.png");
  margin-right: 7px;
}
.icon.collect {
  width: 17px;
  height: 16px;
  background-image: url("../../../assets/images/shoucang2@2x.png");
  margin-right: 7px;
}
.icon.collect.active {
  width: 17px;
  height: 16px;
  background-image: url("../../../assets/images/shoucang3-active.png");
  margin-right: 7px;
}
.icon.share {
  width: 16px;
  height: 15px;
  background-image: url("../../../assets/images/fenxiang@2x.png");
  margin-right: 7px;
}

.btn {
  display: flex;
  align-items: center;
  height: 28px;
  padding: 0 9px;
  background: #eff1f3;
  border-radius: 8px;
  color: #000000;
  font-size: 14px;
  margin-right: 14px;
  cursor: pointer;
}
</style>
