<script>
// 留学专业
import ModelBox from "@components/ModelBox";
import BaseItem from "../baseItem";
export default {
  name: "studyProf",
  props: {
    list: Array,
    tabclick: {
      type: Function,
    },
    secondtabclick: {
      type: Function,
    },
  },
  data() {
    return {
      tabbarData: {
        list: [
          // 此ID根据枚举接口获取,不可更改
          { id: "4", value: "商科" },
          { id: "5", value: "理工科" },
          { id: "6", value: "文科" },
          { id: "13", value: "艺术" },
        ],
        nowTab: "4",
      },
      secondTabData: {
        list: [
          { id: "1", value: "热门专业" },
          { id: "2", value: "特色专业" },
          { id: "3", value: "其他" },
        ],
        nowTab: "1",
      },
    };
  },
  methods: {
    onTabClick(record) {
      this.tabclick(record);
    },
    onSecendTabClick(record) {
      this.secondtabclick(record);
      this.secondTabData.nowTab = record.id;
    },
  },
  render() {
    const modelBoxData = {
      blue: "留学",
      yellow: "专业",
      tabbar: this.tabbarData,
      ontabclick: this.onTabClick,
      content: () => (
        <div class="main">
          <div class="second-tab">
            {this.secondTabData.list.map((item) => (
              <span
                class={item.id === this.secondTabData.nowTab && "active"}
                onclick={() => this.onSecendTabClick(item)}
              >
                {item.value}
              </span>
            ))}
          </div>
          <div class="list">
            {this.list.map((item) => (
              <BaseItem
                data={{ ...item, jumpUrl: `/course/detail?id=${item.id}` }}
              />
            ))}
          </div>
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
  }
  .second-tab {
    display: flex;
    margin-top: 15px;
    span {
      display: block;
      height: 24px;
      border-radius: 12px;
      font-size: 14px;
      background: #f3f6fa;
      color: #8a9099;
      padding: 0 13px;
      line-height: 24px;
      font-weight: 400;
      cursor: pointer;
      margin-right: 16px;
    }
    span.active {
      background: #e5f2ff;
      border: 1px solid #006eeb;
      color: #006eeb;
      box-sizing: border-box;
    }
  }
}
</style>