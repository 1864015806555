<script>
export default {
  name: "confirmDialog",
  props: {
    num: Number,
    close: {
      type: Function,
    },
    confirm: {
      type: Function,
    },
  },
  render() {
    return (
      <div class="shadow">
        <div class="main">
          <i class="icon close" onclick={() => this.close()} />
          <i class="icon money" />
          <p>您确定要兑换吗？</p>
          <p>本次兑换将消费{this.num}积分</p>
          <div class="btn-group">
            <span class="btn yes" onclick={()=>this.confirm()}>立即兑换</span>
            <span class="btn no" onclick={() => this.close()}>
              我再想想
            </span>
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .main {
    background: #ffffff;
    border-radius: 8px;
    padding: 50px;
    width: 570px;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon.money {
      display: block;
      width: 96px;
      height: 75px;
      background-image: url("../../../../assets/images/money2.png");
      background-size: 100%;
      background-repeat: no-repeat;
      margin-bottom: 18px;
    }
    .icon.close {
      display: block;
      width: 31px;
      height: 31px;
      background-image: url("../../../../assets/images/pay-close.png");
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    p {
      font-size: 30px;
      font-weight: 600;
      color: #1a1a1a;
    }
    .btn-group {
      display: flex;
      align-items: center;
      margin-top: 24px;
      justify-content: space-between;
      .btn {
        display: block;
        width: 220px;
        height: 64px;
        line-height: 64px;
        text-align: center;
        font-weight: 600;
        border-radius: 8px;
        font-size: 20px;
        cursor: pointer;
      }
      .btn.yes {
        background: #006eeb;
        color: #ffffff;
      }
      .btn.no {
        background: #d9e9fc;
        color: #006eeb;
        margin-left: 19px;
      }
    }
  }
}
</style>