<script>
import ModelBoxMy from "@components/ModelBoxMy.vue";
import ClassItem from "./ClassItem.vue";
import Loading from "@components/Loading";
import Pagination from "@components/Pagination.vue";
import TeacherItem from "./TeacherItem.vue";
import SchoolItem from "./SchoolItem.vue";
import eicdata2empty from "@components/eicdata2empty.vue";
export default {
  name: "MyCollect",
  data() {
    return {
      pageSize: 3,
      tabbarData: {
        list: [
          { id: 1, value: "课程" },
          { id: 2, value: "名师" },
          { id: 3, value: "院校" },
        ],
        nowTab: 1,
      },
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("MyModel/getMyCollectList", {
        page: 1,
        pageSize: this.pageSize,
        type: this.tabbarData.nowTab,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  render() {
    const {
      myCollectList,
      myCollectListCount,
      isLoadingShow,
    } = this.$store.state.MyModel;
    const paginationObj = {
      totalPage: Math.ceil(myCollectListCount / this.pageSize),
      maxButton: 5,
      pageChange: async (record) => {
        try {
          await this.$store.dispatch("MyModel/getMyCollectList", {
            page: record,
            pageSize: this.pageSize,
            type: this.tabbarData.nowTab,
          });
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      },
    };
    const modelData = {
      blue: "我的",
      yellow: "收藏",
      tabbar: this.tabbarData,
      ontabclick: async (record) => {
        try {
          await this.$store.dispatch("MyModel/getMyCollectList", {
            page: 1,
            pageSize: this.pageSize,
            type: record.id,
          });
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      },
      content: () => (
        <div>
          <div class="list">
            {myCollectList.map((item) => {
              if (this.tabbarData.nowTab === 1)
                return <ClassItem data={item} />;
              if (this.tabbarData.nowTab === 2)
                return <TeacherItem data={item} />;
              if (this.tabbarData.nowTab === 3)
                return <SchoolItem data={item} />;
            })}
          </div>
          {myCollectListCount > this.pageSize ? (
            <div class="paginationBox">
              <Pagination paginationObj={paginationObj} />
            </div>
          ) : null}
          {myCollectListCount == 0 ? (
            <div>
              <eicdata2empty/>
            </div>
          ) : null}
          <Loading isShow={isLoadingShow} />
        </div>
      ),
    };
    return <ModelBoxMy data={modelData} />;
  },
};
</script>
<style lang="less" scoped>
.paginationBox {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>