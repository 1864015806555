<script>
import hotItem from "./Item";
import ModelBox from "@components/ModelBox";
export default {
  name: "hotClass",
  props: {
    list:Array,
    tabclick:{
      type:Function
    }
  },
  data() {
    return {
      nowPage: 1,
      pageSize: 4,
      tabbarData: {
        list: [
          // 此ID根据枚举接口获取,不可更改
          { id: "1", value: "专业" },
          { id: "2", value: "院校" },
          { id: "3", value: "栏目" },
          { id: "4", value: "问答" },
        ],
        nowTab: "1",
      },
    };
  },
  methods: {
    onTabClick(item) {
      this.nowPage = 1;
      this.tabclick(item)
    },
    onPrevClick() {
      if (this.nowPage <= 1) return;
      this.nowPage = this.nowPage - 1;
    },
    onNextClick() {
      // const { courseList } = this.$store.state[this.modelName];
      const totalPage = Math.ceil(this.list.length / this.pageSize);
      if (this.nowPage >= totalPage) return;
      this.nowPage = this.nowPage + 1;
    },
  },
  computed: {
    lists() {
      // const { courseList } = this.$store.state[this.modelName];
      const allList = [];
      this.list.map((item, index) =>
        allList.push({ ...item, index: index + 1 })
      );
      const arr = [];
      for (
        let i = (this.nowPage - 1) * this.pageSize;
        i < allList.length;
        i++
      ) {
        const item = allList[i];
        if (arr.length !== this.pageSize) arr.push({ ...item });
      }
      return arr;
    },
  },
  render() {
    // const { courseList } = this.$store.state[this.modelName];

    const modelBoxData = {
      blue: "热门",
      yellow: "课程",
      tabbar: this.tabbarData,
      ontabclick: this.onTabClick,
      more: {
        title: "查看全部",
        onclick: () => {
          this.$router.push({ path: "/course/list" });
        },
      },
      content: () => (
        <div class="main">
          <div class="hotList">
            {this.lists.map((item) => (
              <hotItem data={{ ...item }} />
            ))}
          </div>
          <div class="paginationBox">
            <span class="info">翻页查看更多</span>
            <div class="pagination">
              <i
                class={`icon-prve ${this.nowPage <= 1 && "disabled"}`}
                onclick={this.onPrevClick}
              />
              <span class="nowPage">
                {this.nowPage}/{Math.ceil(this.list.length / this.pageSize)}
              </span>
              <i
                class={`icon-next ${
                  this.nowPage >=
                    Math.ceil(this.list.length / this.pageSize) && "disabled"
                }`}
                onclick={this.onNextClick}
              />
            </div>
          </div>
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>

<style lang="less" scoped>
.main {
  .hotList {
    padding-top: 13px;
    display: flex;
    flex-wrap: wrap;
  }
  .paginationBox {
    display: flex;
    align-items: center;
    margin-top: 22px;
    justify-content: flex-end;
    .info {
      font-size: 14px;
      font-weight: 400;
      color: #a4a8af;
      margin-right: 17px;
    }
    .pagination {
      display: flex;
      align-items: center;
      .icon-prve {
        width: 25px;
        height: 25px;
        background-color: RGBA(0, 110, 235, 1);
        border-radius: 50%;
        background-image: url("../../assets/images/icon-arrows-white.png");
        background-repeat: no-repeat;
        background-size: 9px 13px;
        background-position: center;
        cursor: pointer;
        transform: rotateY(180deg);
      }
      .icon-prve.disabled {
        background-image: url("../../assets/images/icon-arrows-blue.png");
        background-color: RGBA(240, 247, 255, 1);
        transform: rotateY(0deg);
      }

      .icon-next {
        width: 25px;
        height: 25px;
        background-color: RGBA(0, 110, 235, 1);
        border-radius: 50%;
        background-image: url("../../assets/images/icon-arrows-white.png");
        background-repeat: no-repeat;
        background-size: 9px 13px;
        background-position: center;
        cursor: pointer;
      }
      .icon-next.disabled {
        background-color: RGBA(240, 247, 255, 1);
        background-image: url("../../assets/images/icon-arrows-blue.png");
        transform: rotateY(180deg);
      }
      .nowPage {
        font-size: 15px;
        font-weight: 400;
        color: #262626;
        margin: 0 15px;
      }
    }
  }
}
</style>>
