<script>
export default {
  name: "hotItem",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="hotItemBox">
        <div class="topBox">
          <div class="tag">TOP{this.data.index}</div>
          <p>{this.data.name}</p>
        </div>
        <div class="content">
          <div class="imgBox" onclick={() =>this.$router.push({ path: `/course/detail?id=${this.data.id}` })}>
            <img src={this.data.coverPhoto} />
            <div class="infoBox">
              <div class="peopleBox">
                <i class="icon-eye" />
                {this.data.viewTimes}人已观看
              </div>
              {/*<div class="number">全13集</div>*/}
            </div>
          </div>
          <div class="textBox">
            <p>{this.data.abstract}</p>
            <div class="tags">
              {this.data.tags &&
                this.data.tags.split(",").map((item) => <span>{item}</span>)}
            </div>
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.hotItemBox {
  width: 514px;
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  padding-bottom: 28px;
  &:nth-child(2n) {
    margin-right: 0;
  }
  &:nth-last-child(2) {
    padding-bottom: 0px;
  }
  &:last-child {
    padding-bottom: 0px;
  }
  .topBox {
    display: flex;
    background: #edf0f5;
    align-items: center;
    border-radius: 16px;
    .tag {
      min-width: 64px;
      width: 64px;
      height: 33px;
      background: #f94164;
      border-radius: 16px;
      text-align: center;
      line-height: 33px;
      font-size: 16px;
      color: #ffffff;
      font-weight: 500;
      margin-right: 11px;
    }
    p {
      color: #262626;
      font-size: 16px;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
  .content {
    margin-top: 13px;
    display: flex;
    justify-content: space-between;
    .imgBox {
      width: 211px;
      height: 134px;
      border-radius: 16px;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
      .infoBox {
        position: absolute;
        height: 36px;
        width: 100%;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        bottom: 0;
        display: flex;
        padding: 18px 17px 6px 13px;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        .peopleBox {
          display: flex;
          color: #ffffff;
          font-size: 13px;
          font-weight: 300;
          align-items: center;
          .icon-eye {
            display: block;
            width: 14px;
            height: 11px;
            background-image: url("../../assets/images/icon-eye.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-right: 7px;
          }
        }
        .number {
          font-size: 13px;
          color: #ffffff;
        }
      }
    }
    .textBox {
      width: 285px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        font-size: 14px;
        font-weight: 400;
        color: #8a9099;
        max-height: 112px;
      }
      .tags {
        display: flex;
        span {
          padding: 3px 9px;
          font-size: 16px;
          font-weight: 300;
          background: #f0f7ff;
          border-radius: 8px;
          color: #006eeb;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>