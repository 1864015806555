<script>
import ModelBox from "@components/ModelBox";
export default {
  name: "series",
  props: {
    list: Array,
  },
  data() {
    return {
      nowItem: null, // 当前选中的item id
    };
  },
  methods: {
    itemClick(record) {
      if (record.status === 3) return;

      this.nowItem = record.id;
      // 回放
      record.status === 4 &&
        this.$router.push({ path: `/course/detail?id=${record.backVideo}` });
      // 正在直播
      record.status === 2 &&
        this.$router.push({ path: `/living?id=${record.id}` });
    },
  },
  render() {
    return (
      <div class="series-box">
        <div class="header">
          <div class="title">
            <span class="blue">直播</span>
            <span class="yellow">目录</span>
          </div>
        </div>
        <div class="list">
          {this.list.map((item, index) => (
            <div
              onclick={() => this.itemClick(item)}
              class={`item 
              ${item.status === 1 && "noStart"} 
            ${item.status === 2 && "living"}
            ${item.status === 3 && "end"}
            ${item.id === this.nowItem && "active"}
            `}
              style={{
                cursor:
                  (item.status === 2 ||
                    item.status === 4) &&
                  "pointer",
              }}
            >
              <div class="left">
                <p>
                  {index + 1}.{item.name}
                </p>
              </div>
              <span>
                {item.status === 1 && "未开始"}
                {item.status === 2 && "正在直播"}
                {item.status === 3 && "已结束"}
                {item.status === 4 && "有回放"}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.series-box {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 24px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 22px;
      font-weight: bold;
      .blue {
        color: #006eeb;
      }
      .yellow {
        color: #ff833e;
      }
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    margin-top: 26px;
    // overflow-y: scroll;
    // height: 208px;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      margin-bottom: 16px;
      color: #1a1a1a;
      font-size: 16px;
      height: 30px;
      font-weight: 400;
    }
    .item.end {
      color: #a4aab3;
    }
    .item.noStart {
      color: #a4aab3;
    }
    .item.living {
      color: #006eeb;
    }
    .item.active {
      background: #dbebfc;
      border-radius: 20px;
      font-weight: bold;
      background: #d9e9fc;
    }
  }
}
</style>