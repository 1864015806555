<script>
export default {
  name: "Item",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="item-box">
        <img
          src={this.data.coverPhoto}
          onclick={() =>
            this.$router.push({
              path: `/${this.data.type}/detail?id=${this.data.id}`,
            })
          }
        />
        <div class="content">
          <div class="info">
            <p class="title">{this.data.name}</p>
            {/* <p class="des">刘老师手把手教你考雅思这里可以是 课程标题及简介</p>*/}
          </div>
          <div class="btnBox">
            <div class="group">
              {/*   <p class="price">￥2330.00</p>*/}
              <span
                class="btn"
                onclick={() =>
                  this.$router.push({
                    path: `/${this.data.type}/detail?id=${this.data.id}`,
                  })
                }
              >
                {this.data.type === "course" ? "查看课程" : ""}
                {this.data.type === "live" ? "查看直播" : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.item-box {
  display: flex;
  border-bottom: 1px solid #dce0e5;
  padding: 20px 0;
  &:last-child {
    padding-bottom: 20px;
    border-bottom: 0;
  }
  img {
    width: 263px;
    height: 167px;
    object-fit: cover;
    border-radius: 16px;
    cursor: pointer;
  }
  .content {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    justify-content: space-between;
    width: 100%;
    .info {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 20px;
        font-weight: 400;
        color: #4e4e4e;
      }
      .des {
        font-size: 16px;
        font-weight: 400;
        color: #9ca6b2;
        margin-top: 13px;
      }
    }
    .btnBox {
      display: flex;
      justify-content: flex-end;
      .group {
        display: flex;
        flex-direction: column;
        align-items: center;
        .price {
          font-size: 18px;
          font-weight: 400;
          color: #f94164;
        }
        .btn {
          margin-top: 5px;
          display: block;
          height: 30px;
          border-radius: 8px;
          background: #066eeb;
          font-size: 16px;
          color: #ffffff;
          line-height: 30px;
          text-align: center;
          min-width: 100px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>