<script>
import Pagination from "@components/Pagination.vue";
import eicdata2empty from "@components/eicdata2empty.vue";
export default {
  name: "Sign",
  data() {
    return {
      pageSize: 3,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("MyModel/getMyPrizeList", {
        type: 4,
        page: 1,
        pageSize: this.pageSize,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  render() {
    const { prizeList, prizeListCount } = this.$store.state.MyModel;
    const paginationObj = {
      totalPage: Math.ceil(prizeListCount / this.pageSize),
      maxButton: 5,
      pageChange: async (record) => {
        try {
          await this.$store.dispatch("MyModel/getMyPrizeList", {
            type: 4,
            page: record,
            pageSize: this.pageSize,
          });
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      },
    };
    return (
      <div class="box">
        <div class="signList">
          {prizeList.map((item) => (
            <div class="item">
              {/*  <img src="123" />*/}
              <div class="info">
                <p class="title">{item.name}</p>
                <p class="prize">{item.prize}</p>
              </div>
              <p class="time">抽奖时间：{item.createTime}</p>
            </div>
          ))}
        </div>

        {prizeListCount > this.pageSize ? (
          <div class="paginationBox">
            <Pagination paginationObj={paginationObj} />
          </div>
        ) : null}
        {prizeListCount == 0 ? (
            <div>
              <eicdata2empty/>
            </div>
          ) : null}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.item {
  display: flex;
  border-bottom: 1px solid #e6ebf3;
  padding: 21px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:first-child{
    padding-top: 0;
  }
  &:last-child {
    border-bottom: 0;
  }
  img {
    display: none;
    width: 225px;
    height: 126px;
    border-radius: 16px;
    object-fit: cover;
  }
  .info {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      font-weight: 400;
      padding-top: 10px;
    }
    .prize {
      font-size: 18px;
      font-weight: 500;
      color: #ff833e;
      margin-top: 15px;
    }
  }
  .time {
    color: #006eeb;
    font-size: 14px;
    font-weight: 300;
    width: fit-content;
    padding: 0 10px;
    height: 22px;
    line-height: 22px;
    background: #e5f0fd;
    border-radius: 5px;
    text-align: center;
  }
}
.paginationBox {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>