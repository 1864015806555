<script>
// 优惠管理
import Pagination from "@components/Pagination.vue";
import Item from "./Item.vue";
import Loading from "@components/Loading";
import eicdata2empty from "@components/eicdata2empty.vue";
export default {
  name: "discounts",
  data() {
    return {
      nowTab: 1,
      pageSize: 9,
      code: "", // 兑换码
    };
  },

  async mounted() {
    try {
      await this.$store.dispatch("MyModel/getCouponList", {
        isConsume: 1,
        page: 1,
        pageSize: this.pageSize,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },

  methods: {
    async onTabClick(id) {
      this.nowTab = id;
      try {
        await this.$store.dispatch("MyModel/getCouponList", {
          isConsume: id,
          page: 1,
          pageSize: this.pageSize,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
    async conversionClick() {
      let msg;
      try {
        msg = await this.$store.dispatch("MyModel/exchangeCoupon", {
          code: this.code,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.$message({ showClose: true, message: msg, type: "success" });
    },
  },
  render() {
    const {
      isLoadingShow,
      couponList,
      couponCount,
    } = this.$store.state.MyModel;
    const paginationObj = {
      totalPage: Math.ceil(couponCount / this.pageSize),
      maxButton: 5,
      pageChange: async (record) => {},
    };
    return (
      <div class="Box">
        <div class="header">
          <div class="left">
            <div class="titleBox">
              <span class="blue">优惠</span>
              <span class="yellow">管理</span>
            </div>
            <div class="tabbar">
              <span
                class={`${this.nowTab === 1 ? "active" : ""}`}
                onclick={() => this.onTabClick(1)}
              >
                未使用
              </span>
              <span
                class={`${this.nowTab === 2 ? "active" : ""}`}
                onclick={() => this.onTabClick(2)}
              >
                已使用
              </span>
              <span
                class={`${this.nowTab === 3 ? "active" : ""}`}
                onclick={() => this.onTabClick(3)}
              >
                已失效
              </span>
            </div>
          </div>

          <div class="inputBox">
            <input
              placeholder="请输入兑换码"
              oninput={(e) => (this.code = e.target.value)}
            />
            <span onclick={this.conversionClick}>兑换</span>
          </div>
        </div>
        <div class="content">
          <div class="list">
            {couponList.map((item) => (
              <Item data={item} />
            ))}
          </div>
        </div>
        {couponCount > this.pageSize ? (
          <div class="paginationBox">
            <Pagination paginationObj={paginationObj} />
          </div>
        ) : null}
        {couponCount == 0 ? (
            <div>
              <eicdata2empty/>
            </div>
          ) : null}
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.paginationBox {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.Box {
  padding: 24px;
  background: #ffffff;
  margin-left: 23px;
  border-radius: 16px;
  max-width: 882px;
  width: 882px;
  box-sizing: border-box;

  .header {
    border-bottom: 1px solid #e6ebf3;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .titleBox {
        font-size: 22px;
        font-weight: bold;
        padding-bottom: 7px;
        margin-right: 32px;
        span.blue {
          color: #006eeb;
        }
        span.yellow {
          color: #ff833e;
        }
      }
      .tabbar {
        span {
          font-weight: 400;
          color: #9ca6b2;
          font-size: 18px;
          margin-right: 26px;
          cursor: pointer;
        }
        span.active {
          font-weight: bold;
          color: #006eeb;
          position: relative;
          &::after {
            content: "";
            display: block;
            width: 30px;
            height: 3px;
            position: absolute;
            background: #006eeb;
            bottom: -7px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    .inputBox {
      display: flex;
      align-items: center;
      width: 304px;
      border: 1px solid #c2c6cc;
      border-radius: 8px;
      height: 34px;
      box-sizing: border-box;
      line-height: 34px;
      input {
        font-size: 16px;
        padding-left: 20px;
        line-height: 34px;
        width: 100%;
      }
      span {
        min-width: 86px;
        height: 34px;
        display: block;
        line-height: 34px;
        background: #006eeb;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }
  .content {
    .list {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
    }
    &:last-child {
      border: 0;
    }
  }
}
</style>