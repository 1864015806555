<script>
export default {
  name: "ClassItem",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="class-item">
        <div class="imgBox">
          <img src={this.data.coverPhoto} />
          {/* 
          <div class="infoBox">
            <div class="peopleBox">
              <i class="icon-eye" />
              800人已观看
            </div>
          </div>
          */}
        </div>
        <div class="content-box">
          <div class="top">
            <div class="title-box">
              <p class="title">{this.data.name}</p>
              {/* <p class="time">2020-12-22</p>*/}
            </div>
            {/*  <p class="teacher">主讲人：Lydia Wang</p>*/}
          </div>
          <div class="bottom">
            <div class="tags">
              {this.data.tags &&
                this.data.tags.split(",").map((item) => <span>{item}</span>)}
            </div>
            <span
              class="btn"
              onclick={() =>
                this.$router.push({
                  path: `/course/detail?id=${this.data.id}`,
                })
              }
            >
              <i class="icon-play" />
              继续观看
            </span>
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.class-item {
  background: #f4f9ff;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  margin-bottom: 24px;
  .imgBox {
    width: 242px;
    height: 154px;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .infoBox {
      position: absolute;
      height: 36px;
      width: 100%;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      bottom: 0;
      display: flex;
      padding: 18px 17px 6px 13px;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      .peopleBox {
        display: flex;
        color: #ffffff;
        font-size: 13px;
        font-weight: 300;
        align-items: center;
        .icon-eye {
          display: block;
          width: 14px;
          height: 11px;
          background-image: url("../../../assets/images/icon-eye.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 7px;
        }
      }
      .number {
        font-size: 13px;
        color: #ffffff;
      }
    }
  }
  .content-box {
    margin-left: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0;
    flex: 1;
    .top {
      display: flex;
      flex-direction: column;
      .title-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 18px;
          font-weight: 400;
          color: #262626;
        }
        .time {
          font-weight: 400;
          color: #8a9099;
          font-size: 14px;
        }
      }
      .teacher {
        margin-top: 18px;
        font-size: 16px;
        font-weight: bold;
        color: #8a9099;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .tags {
        display: flex;
        align-items: center;
        span {
          margin-right: 10px;
          padding: 0 9px;
          height: 23px;
          line-height: 23px;
          font-size: 16px;
          color: #006eeb;
          border: 1px solid #006eeb;
          border-radius: 8px;
        }
      }
      .btn {
        display: flex;
        align-items: center;
        background: #066eeb;
        border-radius: 8px;
        font-size: 16px;
        height: 30px;
        line-height: 30px;
        font-weight: 500;
        color: #ffffff;
        padding: 0 10px;
        cursor: pointer;
        .icon-play {
          display: block;
          width: 21px;
          height: 21px;
          background-image: url("../../../assets/images/icon-play-white.png");
          background-size: 100%;
          background-repeat: no-repeat;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>