<script>
export default {
  name: "page",
  methods: {
    onPrevClick() {
      const { nowPage, pageSize, searchData } = this.$store.state.LiveList;
      if (nowPage <= 1) return;
      this.$store.commit("LiveList/updateState", {
        nowPage: nowPage - 1,
      });
      this.loadList({ page: nowPage - 1, pageSize, ...searchData });
    },
    onNextClick() {
      const {
        nowPage,
        count,
        pageSize,
        searchData,
      } = this.$store.state.LiveList;
      const totalPage = Math.ceil(count / pageSize);
      if (nowPage >= totalPage) return;
      this.$store.commit("LiveList/updateState", {
        nowPage: nowPage + 1,
      });

      this.loadList({ page: nowPage + 1, pageSize, ...searchData });
    },
    async loadList(params) {
      try {
        await this.$store.dispatch("LiveList/getLiveList", params);
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
  },
  render() {
    const { lists, count, nowPage, pageSize } = this.$store.state.LiveList;
    const totalPage = Math.ceil(count / pageSize);
    return (
      <div class="pageBox">
        <p>
          共<span class="yellow">{count}</span>条相关内容
        </p>
        <div class="pagination">
          <i
            class={`icon-prev ${nowPage <= 1 ? "disabled" : ""}`}
            onclick={this.onPrevClick}
          />
          <span>
            {totalPage ? nowPage : 0}/{totalPage}
          </span>
          <i
            class={`icon-next ${nowPage >= totalPage ? "disabled" : ""}`}
            onclick={this.onNextClick}
          />
        </div>
      </div>
    );
  },
};
</script>

<style lang="less" scoped>
.pageBox {
  margin-top: 24px;
  padding: 22px 24px;
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  p {
    font-size: 18px;
    font-weight: 400;
    color: #1a1a1a;
    span.yellow {
      color: #ffa000;
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    .icon-prev {
      display: block;
      width: 25px;
      height: 25px;
      transform: rotateY(180deg);
      background-image: url("../../../assets/images/next.png");
      background-size: 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      margin-right: 12px;
    }
    .icon-prev.disabled {
      transform: rotateY(0deg);
      background-image: url("../../../assets/images/prev.png");
    }
    span {
      font-size: 18px;
      font-weight: 400;
      margin-right: 12px;
      color: #262626;
    }
    .icon-next {
      display: block;
      width: 25px;
      height: 25px;
      background-image: url("../../../assets/images/next.png");
      background-size: 100%;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    .icon-next.disabled {
      background-image: url("../../../assets/images/prev.png");
      transform: rotateY(180deg);
    }
  }
}
</style>