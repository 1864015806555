<script>
import BreadCrumbsMy from "@components/BreadCrumbsMy.vue";
import CouponItem from "./couponItem.vue";
export default {
  name: "comfirmOrder",
  data() {
    return {
      breadCrumbsList: [
        {
          name: "我的订单",
          onclick: () =>
            this.$store.commit("MyModel/updateState", { orderState: "list" }),
        },
        {
          name: "确认订单",
        },
      ],
    };
  },
  render() {
    return (
      <div class="box">
        <BreadCrumbsMy list={this.breadCrumbsList} />
        <div class="status">确认订单信息</div>
        <div class="infoBox">
          <img src={123} />
          <div class="content">
            <div class="info">
              <p class="title">美国文科文书头脑风暴</p>
              <p class="des">刘老师手把手教你考雅思这里可以是 课程标题及简介</p>
              <p class="price">￥2330.00</p>
            </div>
          </div>
        </div>
        <div class="payMode">
          <p class="title">请选择支付方式</p>
          <div class="payBox">
            <div class="vxBox">
              <i class="icon-vxpay" />
              微信支付
            </div>
            <i class="icon-check" />
          </div>
        </div>
        <div class="coupon">
          <div class="label">请选择优惠券</div>
          <div class="selectBox">
            <span>1张可用</span>
            <i class="icon-selectDown" />
          </div>
        </div>
        <div class="couponList">
          <CouponItem />
          <CouponItem />
        </div>
        <div class="btnBox">
          <div class="sumPrice">
            <i class="icon-money" />
            <p>
              订单总价：<span>￥2330.00</span>
            </p>
          </div>
          <span class="btn">确认支付</span>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.box {
  padding: 24px;
  background: #ffffff;
  margin-left: 23px;
  border-radius: 16px;
  max-width: 882px;
  width: 882px;
  box-sizing: border-box;
  .status {
    font-size: 18px;
    color: #006eeb;
    font-weight: bold;
    padding: 24px 0;
  }
  .infoBox {
    display: flex;
    border-bottom: 1px solid #dce0e5;
    padding-bottom: 20px;
    img {
      width: 263px;
      height: 167px;
      object-fit: cover;
      border-radius: 16px;
    }
    .content {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      width: 100%;
      .info {
        display: flex;
        flex-direction: column;
        .title {
          font-size: 20px;
          font-weight: 400;
          color: #4e4e4e;
        }
        .des {
          font-size: 16px;
          font-weight: 400;
          color: #9ca6b2;
          margin-top: 13px;
        }
        .price {
          font-size: 18px;
          color: #f94164;
          margin-top: 27px;
        }
      }
    }
  }
  .payMode {
    padding: 20px 0;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #1a1a1a;
    }
    .payBox {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      border-bottom: 1px solid #dce0e5;
      padding-bottom: 20px;
      .vxBox {
        display: flex;
        align-items: center;
        .icon-vxpay {
          display: block;
          width: 30px;
          height: 30px;
          background-image: url("../../../../assets/images/vxpay.png");
          background-size: 100%;
          background-repeat: no-repeat;
          margin-right: 13px;
        }
      }
      .icon-check {
        cursor: pointer;
        display: block;
        width: 25px;
        height: 25px;
        background-image: url("../../../../assets/images/pay-check.png");
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }
  }
  .coupon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label {
      color: #1a1a1a;
      font-weight: bold;
      font-size: 18px;
    }
    .selectBox {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        display: block;
        color: #ffffff;
        background: #066eeb;
        height: 20px;
        padding: 0 6px;
        border-radius: 16px;
        line-height: 20px;
      }
      .icon-selectDown {
        margin-left: 9px;
        display: block;
        width: 15px;
        height: 8px;
        background-image: url("../../../../assets/images/select-down.png");
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }
  }
  .couponList {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .btnBox {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    align-items: center;
    .sumPrice {
      display: flex;
      align-items: center;
      .icon-money {
        display: block;
        width: 28px;
        height: 28px;
        background-image: url("../../../../assets/images/money.png");
        background-size: 100%;
        background-repeat: no-repeat;
        margin-right: 7px;
      }
      p {
        font-size: 20px;
        color: #4e4e4e;
        span {
          color: #f94164;
        }
      }
    }
    .btn {
      width: 204px;
      display: block;
      height: 50px;
      line-height: 50px;
      border-radius: 8px;
      background: #f94164;
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      margin-top: 25px;
      cursor: pointer;
    }
  }
}
</style>