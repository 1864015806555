<script>
// 出国考试
import ModelBox from "@components/ModelBox";
import Item from "./item";
export default {
  name: "university",
  render() {
    const { relevanSchoolList } = this.$store.state.UniversityDetail;
    const modelBoxData = {
      blue: "相关",
      yellow: "院校",
      more: {
        title: "查看全部",
        onclick: () => this.$router.push({ path: "/university/list" }),
      },
      content: () => (
        <div class="main">
          <div class="list">
            {relevanSchoolList.length &&
              relevanSchoolList.map((item) => <Item data={item} />)}
          </div>
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}
</style>