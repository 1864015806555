<script>
export default {
  name: "item",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="item-box">
        <div class="imgBox">
          <img src={this.data.coverPhoto} />
          <div class="shadow">
            <i class="icon-live-text" />
            <div class="people">
              <i class="icon-people" />
              {this.data.viewTimes}人预约
            </div>
          </div>
        </div>
        <div class="content">
          <div class="infoBox">
            <p class="des">{this.data.name}</p>
            <p class="time">
              <i class="icon-time" />
              今天
              {this.$moment.unix(this.data.liveTime).format("HH:mm")} -
              {this.$moment.unix(this.data.endTime).format("HH:mm")}
            </p>
          </div>
          <div class="btngroup">
            <div class="tags">
              {this.data.tags ? (
                <span>{this.data.tags.split(",")[0]}</span>
              ) : null}
            </div>
            <span
              class="btn"
              onclick={() =>
                this.$router.push({ path: `/live/detail?id=${this.data.id}` })
              }
            >
              立即观看
              <i class="icon-next" />
            </span>
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.item-box {
  display: flex;
  border-radius: 16px;
  width: 514px;
  height: 186px;
  background: #f0f7ff;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
  .imgBox {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    width: 242px;
    min-width: 242px;
    height: 154px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .shadow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 42px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      padding: 17px 12px 0px 12px;
      box-sizing: border-box;
      .icon-live-text {
        display: block;
        width: 37px;
        height: 13px;
        background-image: url("../../../assets/images/live-text.png");
        background-size: 100%;
        background-repeat: no-repeat;
      }
      .people {
        display: flex;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        .icon-people {
          display: block;
          width: 17px;
          height: 17px;
          background-image: url("../../../assets/images/icon-people.png");
          background-size: 100%;
          background-repeat: no-repeat;
          margin-right: 8px;
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    justify-content: space-between;
    .infoBox {
      display: flex;
      flex-direction: column;
      .des {
        font-size: 16px;
        font-weight: 400;
        color: #262626;
        margin-top: 10px;
        min-height: 43px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .time {
        color: #ff833e;
        font-weight: bold;
        font-size: 16px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        .icon-time {
          display: block;
          width: 17px;
          height: 17px;
          background-image: url("../../../assets/images/time-active.png");
          background-size: 100%;
          background-repeat: no-repeat;
          margin-right: 4px;
        }
      }
    }
    .btngroup {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .tags {
        display: flex;
        span {
          display: block;
          padding: 0 9px;
          font-size: 14px;
          font-weight: 300;
          color: #006eeb;
          border: 1px solid #006eeb;
          border-radius: 8px;
          height: 23px;
          line-height: 23px;
        }
      }
      .btn {
        cursor: pointer;
        display: flex;
        width: 109px;
        height: 35px;
        background: #006eeb;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 300;
        color: #ffffff;
        line-height: 35px;
        align-items: center;
        padding-left: 14px;
        box-sizing: border-box;
        .icon-next {
          display: block;
          width: 10px;
          height: 16px;
          background-image: url("../../../assets/images/icon-arrows-white.png");
          background-size: 100%;
          background-repeat: no-repeat;
          margin-left: 8px;
          margin-top: 3px;
        }
      }
    }
  }
}
</style>