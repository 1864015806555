<script>
// 我的预约
import ModelBox from "@components/ModelBox";
import Item from "./Item.vue";
import Null from "./Null.vue";
export default {
  name: "myAppointment",
  props: {
    list: Array,
  },
  render() {
    const modelBoxData = {
      blue: "我的",
      yellow: "预约",
      more: {
        title: "查看全部",
        onclick: () => {
          this.$router.push({ path: "/my/appointment" });
        },
      },
      content: () => (
        <div class="main">
          {this.list.map((item) => (
            <Item data={item} />
          ))}
          {/*<Null />*/}
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.main {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;
}
</style>