
<script>
import ConfirmDialog from "../confirmDialog";
import BreadCrumbsMy from "@components/BreadCrumbsMy.vue";
import Dialog from "@components/Dialog";
export default {
  name: "confirmOrder",
  data() {
    return {
      breadCrumbsList: [
        {
          name: "积分商城",
          onclick: () => this.onInfoClick("shop"),
        },
        {
          name: "商品详情",
          onclick: () => this.onInfoClick("shopDetail"),
        },
        {
          name: "确认订单",
        },
      ],
      isSelectAddressId: null,
      isShowDialog: false,
      remark: "",
      isSuccessShow: false, // 成功提示
      isFailShow: false, // 失败提示
      orderId: null,
      timer: null,
      time: 3,
    };
  },
  async mounted() {
    const {
      integralId,
      integralDetail,
      userAddressList,
    } = this.$store.state.MyModel;
    try {
      await this.$store.dispatch("MyModel/getGoodsDetail", {
        id: integralId,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    if (integralDetail.type === 2) {
      try {
        await this.$store.dispatch("MyModel/getUserAddressList");
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.isSelectAddressId = userAddressList[0].id;
    }
  },
  methods: {
    onInfoClick(state) {
      this.$store.commit("MyModel/updateState", {
        integralState: state,
      });
    },

    async dialogConfirm() {
      const { userAddressList, integralDetail } = this.$store.state.MyModel;
      const param = {
        remark: this.remark,
        goodsId: integralDetail.id,
      };
      if (integralDetail.type === 2) {
        const data = userAddressList.filter(
          (item) => item.id === this.isSelectAddressId
        );
        param.realName = data[0].realName;
        param.mobile = data[0].mobile;
        param.address = data[0].address;
      }

      try {
        const data = await this.$store.dispatch(
          "MyModel/createShopOrder",
          param
        );
        this.orderId = data.id;
      } catch (msg) {
        this.isFailShow = true;
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.isSuccessShow = true;
      this.timer = setInterval(() => {
        if (this.time === 1) {
          this.time = 3;
          clearInterval(this.timer);
          this.$store.commit("MyModel/updateState", {
            integralShopDetailId: this.orderId,
            integralState: "detail",
            nowTab: 6,
          });
        } else {
          this.time = this.time - 1;
        }
      }, 1000);
      this.isShowDialog = false;
    },
    remarkInput(e) {
      const { value } = e.target;
      this.remark = value;
    },
  },
  render() {
    const { userAddressList, integralDetail } = this.$store.state.MyModel;
    const successData = {
      title: "兑换成功！",
      onclose: () => (this.isSuccessShow = false),
      content: () => (
        <div class="dialog-box">
          <i class="icon success" />
          <div class="btn">
            （{this.time}s）后跳转至
            <span
              onclick={() => {
                this.$store.commit("MyModel/updateState", {
                  integralShopDetailId: this.orderId,
                  integralState: "detail",
                  nowTab: 6,
                });
              }}
            >
              订单详情
            </span>
          </div>
        </div>
      ),
    };
    const failData = {
      title: "兑换失败！",
      content: () => (
        <div class="dialog-box">
          <i class="icon fail" />
          <div class="btn">
            积分不足，获取
            <span
              onclick={() => {
                this.$store.commit("MyModel/updateState", {
                  nowTab: 7,
                });
              }}
            >
              积分
            </span>
          </div>
        </div>
      ),
    };
    return (
      <div>
        <div class="box">
          <BreadCrumbsMy list={this.breadCrumbsList} />
          {integralDetail.type === 2 ? (
            <div class="address-box">
              <div class="title">
                <h3>确认收货地址</h3>
                <span>管理收货地址>></span>
              </div>
              <div class="main">
                {userAddressList.length ? (
                  <div class="address-list">
                    {userAddressList.map((item) => (
                      <div
                        class={`item ${
                          this.isSelectAddressId === item.id ? "active" : ""
                        }`}
                      >
                        <div class="left">
                          <i
                            class="icon check"
                            onclick={() => (this.isSelectAddressId = item.id)}
                          />
                          <span class="address-text">
                            {item.region} {item.address}
                          </span>
                          <span class="telphone">
                            {item.realName} {item.mobile}
                          </span>
                        </div>
                        {item.default === 1 ? (
                          <span class="tag">默认地址</span>
                        ) : null}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div class="noAddress" v-show={false}>
                    您还没有收货地址，请填写 >
                  </div>
                )}
              </div>
            </div>
          ) : null}
          <div class="order-box">
            <h3 class="title">确认订单信息</h3>
            <div class="content">
              <img src={integralDetail && integralDetail.coverPhoto} />
              <div class="info">
                <h3>{integralDetail && integralDetail.title}</h3>
                {integralDetail && JSON.parse(integralDetail.params).length ? (
                  <div class="other-info">
                    {JSON.parse(integralDetail && integralDetail.params).map(
                      (item) => (
                        <span>
                          {item.specifications}：{item.desc}
                        </span>
                      )
                    )}
                  </div>
                ) : null}
                <div class="num-box">
                  <i class="icon money" />
                  订单总价：
                  <span class="red">
                    {integralDetail && integralDetail.scorePrice}积分
                  </span>
                  <span class="gray">x1</span>
                </div>
              </div>
            </div>
          </div>
          <div class="info-box">
            <div class="line">
              <div class="item">
                <span class="label">服务</span>
                <span class="value">不支持7天无理由</span>
              </div>
              <div class="item">
                <span class="label">配送方式</span>
                <span class="value">免邮</span>
              </div>
            </div>
            <div class="line">
              <div class="item" style={{ width: "100%" }}>
                <span class="label">订单备注</span>
                <input
                  placeholder="选填，请先和客服协商一致"
                  oninput={this.remarkInput}
                />
              </div>
            </div>
          </div>
          <span class="btn" onclick={() => (this.isShowDialog = true)}>
            确认兑换
          </span>
          {this.isShowDialog ? (
            <ConfirmDialog
              num={integralDetail && integralDetail.scorePrice}
              confirm={this.dialogConfirm}
              close={() => (this.isShowDialog = false)}
            />
          ) : null}
        </div>
        {this.isSuccessShow ? <Dialog data={successData} /> : null}
        {this.isFailShow ? <Dialog data={failData} /> : null}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.box {
  padding: 24px;
  background: #ffffff;
  margin-left: 23px;
  border-radius: 16px;
  max-width: 882px;
  width: 882px;
  box-sizing: border-box;
  .btn {
    display: block;
    background: #f94164;
    border-radius: 8px;
    width: 204px;
    height: 50px;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    margin: 19px auto 0;
  }
  .address-box {
    display: flex;
    flex-direction: column;
    .title {
      display: flex;
      justify-content: space-between;
      h3 {
        font-size: 20px;
        font-weight: 400;
        color: #000000;
        line-height: 50px;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        color: #066eeb;
        line-height: 50px;
        cursor: pointer;
      }
    }

    .main {
      display: flex;
      .noAddress {
        margin: 0 auto;
        background: #dae9fc;
        border-radius: 8px;
        width: 450px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        font-size: 18px;
        font-weight: 500;
        color: #066eeb;
        cursor: pointer;
      }
      .address-list {
        display: flex;
        width: 100%;
        flex-direction: column;
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 50px;
            color: #a4aab3;

            .telphone {
              margin-left: 69px;
            }
            .icon.check {
              cursor: pointer;
              display: block;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              border: 1px solid #066eeb;
              margin-right: 7px;
              box-sizing: border-box;
            }
          }

          .tag {
            display: block;
            padding: 0 20px;
            background: #e5f0fd;
            border-radius: 8px;
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            font-weight: 500;
            color: #006eeb;
          }
        }
        .item.active {
          .left {
            color: #000000;
            .icon.check {
              background: rgb(6, 110, 235);
            }
          }
        }
      }
    }
  }
  .order-box {
    display: flex;
    flex-direction: column;
    h3.title {
      font-size: 20px;
      font-weight: 400;
      color: #000000;
      line-height: 50px;
    }
    .content {
      display: flex;
      padding-bottom: 20px;
      border-bottom: 1px solid #dce0e5;
      img {
        width: 263px;
        height: 167px;
        object-fit: cover;
        border-radius: 8px;
        margin-right: 24px;
      }
      .info {
        padding-top: 20px;
        width: 100%;
        h3 {
          font-size: 18px;
          font-weight: 500;
          color: #000000;
          margin-bottom: 5px;
        }
        .other-info {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          span {
            font-size: 14px;
            font-weight: 400;
            color: #9ca6b2;
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
        .num-box {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          margin-top: 24px;

          span.red {
            color: #f94164;
          }
          span.gray {
            color: #9ca6b2;
          }
          .icon.money {
            display: block;
            width: 22px;
            height: 22px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-image: url("../../../../assets/images/money.png");
            margin-right: 5px;
          }
        }
      }
    }
  }
  .info-box {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      .item {
        background: #eeeeee;
        border-radius: 8px;
        padding: 0 13px;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        width: 409px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        height: 30px;
        span.label {
          width: 84px;
          display: block;
        }
        span.value {
          border-left: 1px solid #000;
          padding-left: 5px;
        }
        input {
          width: 100%;
          background: #ffffff;
          height: 20px;
          margin-left: 8px;
          padding-left: 8px;
          box-sizing: border-box;
          border-radius: 6px;
          font-size: 14px;
          color: #9ca6b2;
        }
      }
    }
  }
}

.dialog-box {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  .icon.success {
    width: 60px;
    height: 60px;
    background-image: url("../../../../assets/images/pay-success.png");
    margin: 0 auto 30px;
  }
  .icon.fail {
    width: 60px;
    height: 60px;
    background-image: url("../../../../assets/images/pay-fail.png");
    margin: 0 auto 30px;
  }
  .btn {
    display: block;
    border-radius: 8px;
    background: #006eeb;
    padding: 0 50px;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    font-weight: 600;
    width: fit-content;
    color: #ffffff;
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>