
<script>
// 支付方式
export default {
  name: "payMode",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="payModeBox">
        <div class="box">
          <p class="label">支付方式</p>
          <div class="wxpay">
            <i class="icon-vxpay" />
            微信支付
          </div>
        </div>
        <div class="box">
          <p class="label">优惠券</p>
          <div class="coupon">
            {this.data.paymentMethod === "wxpay" ? "无" : null}
            {this.data.paymentMethod === "exchange" ? this.data.couponTitle : null}
            {/*<span class="blue">启德课堂全部课程折扣...</span>*/}
            {/*<span class="grey">暂无可用</span> */}
          </div>
        </div>
        <div class="box">
          <p class="label">订单总价</p>
          <div class="sumPrice">￥{this.data.needAmount}</div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.payModeBox {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-bottom: 1px solid #dce0e5;
  .box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    .label {
      font-size: 18px;
      font-weight: bold;
      color: #1a1a1a;
    }
    .wxpay {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      color: #1a1a1a;
      .icon-vxpay {
        display: block;
        width: 30px;
        height: 30px;
        background-image: url("../../../../assets/images/vxpay.png");
        background-size: 100%;
        background-repeat: no-repeat;
        margin-right: 13px;
      }
    }
    .coupon {
      font-size: 18px;
      font-weight: 500;
      color: #cecece;
      span {
        display: block;
        height: 20px;
        padding: 0 20px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 16px;
        cursor: pointer;
      }
      span.blue {
        color: #006eeb;
        background: #dae9fc;
      }
      span.grey {
        width: 69px;
        border: 1px solid #9ca6b2;
        color: #9ca6b2;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
      }
    }
    .sumPrice {
      font-size: 18px;
      font-weight: 400;
      color: #f94164;
    }
  }
}
</style>