<script>
export default {
  name: "shareItem",
  props: {
    data: Object,
  },
  render() {
    return (
      // <div class="shareItemBox">
      //   <div class="nameBox">
      //     <div class="avatar">
      //       <img src={this.data.head} />
      //     </div>
      //     {this.data.teacherName}
      //   </div>
      //   <div class="content">
      //     <div class="title of2">{this.data.name}</div>
      //     <div class="describe">{this.data.abstract}</div>
      //     <div class="banner">
      //       <img
      //         src={this.data.posters}
      //         onclick={() =>
      //           this.$router.push({
      //             path: `/live/detail?id=${this.data.id}`,
      //           })
      //         }
      //       />
      //     </div>
      //   </div>
      // </div>

      <div class="likeItemBox">
        <div class="pictureBox">
          <img
            src={this.data.coverPhoto}
            onclick={() =>
              this.$router.push({
                path: `/live/detail?id=${this.data.id}`,
              })
            }
          />
          <div class="shadowBox">
            <div class="people">
              <i class="icon-eye" />
              {this.data.viewTimes}人观看
            </div>
          </div>
          {/* <div class="state">教育体制</div>*/}
        </div>
        <div class="contentBox">
          <p>{this.data.name}</p>
          <div class="tags">
            {this.data.tags &&
              this.data.tags.split(",").map((item) => <span>{item}</span>)}
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
/* .shareItemBox {
  display: flex;
  flex-direction: column;
  margin-right: 25px;
  &:nth-child(3n) {
    margin-right: 0;
  }
  .nameBox {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #000000;
    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 14px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    .title {
      font-size: 18px;
      width: 334px;
	  height: 46px;
      color: #000000;
      font-weight: 400;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
    }
    .describe {
      width: 334px;
      font-size: 14px;
      color: #8a9099;
      font-weight: 400;
      margin-top: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .banner {
      width: 334px;
      height: 111px;
      border-radius: 16px;
      overflow: hidden;
      margin-top: 13px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }
} */

.likeItemBox {
  display: flex;
  flex-direction: column;
  width: 245px;
  margin-right: 24px;
  margin-bottom: 24px;
  &:nth-child(4n) {
    margin-right: 0;
  }
  &:nth-last-child(1) {
    margin-bottom: 0px;
  }
  &:nth-last-child(2) {
    margin-bottom: 0px;
  }
  &:nth-last-child(3) {
    margin-bottom: 0px;
  }
  &:nth-last-child(4) {
    margin-bottom: 0px;
  }
  .pictureBox {
    width: 245px;
    height: 155px;
    overflow: hidden;
    border-radius: 16px;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      cursor: pointer;
    }
    .shadowBox {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 42px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      padding: 17px 0 0px 12px;
      box-sizing: border-box;
      .people {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #ffffff;
        .icon-eye {
          width: 16px;
          height: 13px;
          display: block;
          background-image: url("../../assets/images/icon-eye.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 8px;
        }
      }
    }
    .state {
      background: linear-gradient(90deg, #30b9ec, #107dcc);
      border-radius: 16px 0px 8px 0px;
      height: 26px;
      position: absolute;
      left: 0;
      top: 0;
      padding: 0 14px;
      line-height: 26px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .contentBox {
    display: flex;
    margin-top: 9px;
    flex-direction: column;
    p {
      font-weight: 400;
      color: #000000;
      font-size: 16px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      height: 42px;
      min-height: 42px;
    }
    .tags {
      display: flex;
      margin-top: 11px;
      span {
        padding: 0 8px;
        background-color: RGBA(219, 235, 252, 1);
        color: #006eeb;
        font-size: 14px;
        font-weight: 300;
        border-radius: 8px;
        height: 23px;
        line-height: 23px;
        margin-right: 8px;
      }
    }
  }
}
</style>