<script>
export default {
  name: "Item",
  props: {
    data: Object,
  },
  methods: {
    onItemClick(record) {
      this.$store.commit("MyModel/updateState", {
        orderState: "detail",
        orderDetailNumber: record.number,
      });
    },
    async cancleOrder(number) {
      let msg;
      try {
        msg = await this.$store.dispatch("MyModel/cancleOrder", { number });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.$message({ showClose: true, message: msg, type: "success" });
      try {
        await this.$store.dispatch("MyModel/getUserOrderList", {
          state: 0,
          page: 1,
          pageSize: 5,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
  },
  render() {
    return (
      <div class="order-item">
        <img
          src={this.data.coverPhoto}
          onclick={() => this.onItemClick(this.data)}
        />
        <div class="content">
          <div class="top">
            <div class="number-box">
              <span class="orderNum">订单编号：{this.data.number}</span>
              <span class="status">
                {this.data.state === 1 && "待支付"}
                {this.data.state === 2 && "已支付"}
                {this.data.state === 9 && "已失效"}
              </span>
            </div>
            <div class="des">{this.data.title}</div>
          </div>
          <div class="bottom">
            <div class="priceBox">
              <span>￥{this.data.totalPrice}</span>
            </div>
            <div class="dateBox">
              <span class="time">订单日期：{this.data.createTime} </span>
              <div class="btnGroup">
                {this.data.state === 1 ? (
                  <span
                    class="btn cancel"
                    onclick={() => this.cancleOrder(this.data.number)}
                  >
                    取消订单
                  </span>
                ) : null}
                {this.data.state === 1 ? (
                  <span
                    class="btn pay"
                    onclick={() =>
                      this.$router.push({
                        path: `/order/info?id=${this.data.productId}&type=${this.data.type}`,
                      })
                    }
                  >
                    立即支付
                  </span>
                ) : null}
                {this.data.state === 8 ? (
                  <span
                    class="btn watch"
                    onclick={() =>
                      this.$router.push({
                        path: `/${this.data.type}/detail?id=${this.data.productId}`,
                      })
                    }
                  >
                    {this.data.type === "live" ? "观看直播" : ""}
                    {this.data.type === "course" ? "观看课程" : ""}
                  </span>
                ) : null}
                {this.data.state === 9 ? (
                  <span
                    class="btn again"
                    onclick={() =>
                      this.$router.push({
                        path: `/order/info?id=${this.data.productId}&type=${this.data.type}`,
                      })
                    }
                  >
                    重新下单
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.order-item {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid #dce0e5;
  &:last-child {
    border-bottom: 0;
  }
  img {
    width: 263px;
    height: 167px;
    object-fit: cover;
    border-radius: 16px;
    cursor: pointer;
  }
  .content {
    margin-left: 20px;
    width: 100%;
    padding: 3px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top {
      display: flex;
      flex-direction: column;
      .number-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .orderNum {
          font-size: 18px;
          font-weight: 400;
          color: #9ca6b2;
        }
        .status {
          font-size: 18px;
          font-weight: 400;
          color: #9ca6b2;
        }
      }
      .des {
        margin-top: 10px;
        font-weight: 400;
        font-size: 20px;
        color: #4e4e4e;
      }
    }
    .bottom {
      display: flex;
      flex-direction: column;
      .priceBox {
        display: flex;
        justify-content: flex-end;
        span {
          font-size: 18px;
          font-weight: 400;
          color: #f94164;
        }
      }
      .dateBox {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;
        align-items: center;
        .time {
          font-size: 14px;
          color: #9ca6b2;
        }
        .btnGroup {
          display: flex;
          align-items: center;
          .btn {
            display: block;
            line-height: 28px;
            padding: 0 13px;
            height: 28px;
            border-radius: 10px;
            font-size: 16px;
            margin-right: 20px;
            cursor: pointer;
            &:last-child {
              margin-right: 0;
            }
          }

          .btn.cancel {
            background: #d9e9fc;
            color: #066eeb;
          }
          .btn.pay {
            color: #ffffff;
            background: #f94164;
          }
          .btn.watch {
            background: #066eeb;
            color: #ffffff;
          }
          .btn.again {
            background: #ff833e;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>