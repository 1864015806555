<script>
import DialogComp from "@components/Dialog";
export default {
  name: "vipRule",
  props: {
    isShow: Boolean,
    closeFunc: {
      type: Function,
    },
  },
  data() {
    return {
      levelData: [
        { name: "留学萌新", className: "lxmx", isActive: true },
        { name: "留学探路者", className: "lxtl", isActive: false },
        { name: "留学领队", className: "lxld", isActive: false },
        { name: "留学践行者", className: "lxjx", isActive: false },
      ],
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("MyModel/getUserInfo");
    } catch (error) {
          if (error.code == 1016) {
            this.$message({ showClose: true, message: error.msg, type: "error" });
            window.$cookies.set("token",'')
          }
          return;
        }
    const { userInfo } = this.$store.state.MyModel;
    const { grade } = userInfo;
    const filterArr = this.levelData.filter((value, index) => index <= grade);
    filterArr.map((item, index) => (this.levelData[index].isActive = true));
  },
  render() {
    const { userInfo } = this.$store.state.MyModel;
    const dialogData = {
      onclose: this.closeFunc,
      style: { width: "570px" },
      content: () => (
        <div class="vipRule-box">
          <div class="scroll-box">
            <div class="header-box">
              <div
                class={`bg-box 
                ${userInfo && userInfo.grade === 0 && "lxmx"} 
                ${userInfo && userInfo.grade === 1 && "lxtl"}
                ${userInfo && userInfo.grade === 2 && "lxld"}
                ${userInfo && userInfo.grade === 3 && "lxjx"}`}
              >
                <p class="bg-title">
                  {userInfo && userInfo.grade === 0 && "留学萌新"}
                  {userInfo && userInfo.grade === 1 && "留学探路者"}
                  {userInfo && userInfo.grade === 2 && "留学领队"}
                  {userInfo && userInfo.grade === 3 && "留学践行者"}
                </p>
                <div class="info">
                  <div class="name">
                    <img src={userInfo && userInfo.head} />
                    {userInfo && userInfo.nickname}
                  </div>
                  <p>
                    签到天数<span>{userInfo && userInfo.signCount}</span>
                  </p>
                </div>
              </div>
              <div class="level-box">
                {this.levelData.map((item) => (
                  <div class={`item ${item.isActive ? "active" : ""}`}>
                    <i class={`icon ${item.className}`} />
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>
            </div>
            <div class="content-box">
              <h2>会员类别及说明</h2>
              <p>会员类别：启德课堂会员分为以下4个类别，名称及达成条件如下：</p>
              <table>
                <thead>
                  <tr>
                    <th width="120px">会员类型</th>
                    <th>使用权限</th>
                  </tr>
                </thead>
                <tr>
                  <td>留学萌新</td>
                  <td>成功完成注册</td>
                </tr>
                <tr>
                  <td>留学探路者</td>
                  <td>
                    成功完成注册，在启德课堂站内完成一次预约名师/顾问，且累计签到次数大于等于3次
                  </td>
                </tr>
                <tr>
                  <td>留学领队</td>
                  <td>
                    成功完成注册，在启德课堂站内完成一次预约名师/顾问，且累积签到次数大于等于30次
                  </td>
                </tr>
                <tr>
                  <td>留学践行者</td>
                  <td>成功完成注册，且已签约启德教育留学产品</td>
                </tr>
              </table>
              <h2>会员权益</h2>
              <ul>
                <li>①专属会员标识</li>
                <li>②付费课程及直播如有优惠，享受对应类别的优惠价格</li>
                <li>③积分商城兑换权限，部分商品仅限对应类别会员进行兑换</li>
              </ul>
              <h2>注意</h2>
              <p>
                若留学领队用户连续30天未登录，会员类别将变更为留学探路者，登录后重新
                开始累计签到天数。
              </p>
              <h1>会员等级福利说明</h1>
              <ul>
                <li>
                  ①如果启德签约用户登录后，会员类别未显示为留学践行者，请查看是否使用签约时登记的手机号注册，若确认无误，请联系我们。
                </li>
                <li>
                  ②如果用户发布了不正当评论，包括但不限于人身攻击、政治相关、无理由挂人、引战等，评论将被删除，且收回获得的积分。
                </li>
              </ul>
            </div>
          </div>
        </div>
      ),
    };
    return <DialogComp v-show={this.isShow} data={dialogData} />;
  },
};
</script>
<style lang="less" scoped>
.vipRule-box {
  padding: 0 32px;
  .scroll-box {
    height: 555px;
    overflow-y: scroll;
    padding-right: 14px;

    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #99c5f7;
    }
    .header-box {
      .bg-box {
        width: 100%;
        height: 179px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: relative;
        margin-bottom: 13px;
        .bg-title {
          font-size: 24px;
          font-weight: 300;
          color: #ffffff;
          position: absolute;
          top: 15px;
          left: 34px;
        }
        .info {
          padding-left: 36px;
          padding-top: 64px;
          .name {
            width: 240px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
            padding-bottom: 9px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 600;
            color: #ffffff;
            margin-bottom: 12px;
            img {
              width: 36px;
              height: 36px;
              object-fit: cover;
              border-radius: 50%;
              margin-right: 8px;
            }
          }
          p {
            font-size: 16px;
            font-weight: 300;
            color: #ffffff;
            display: flex;
            align-items: baseline;
            span {
              font-size: 26px;
              font-weight: 500;
              color: #ffffff;
              margin-left: 10px;
            }
          }
        }
      }
      .bg-box.lxmx {
        background-image: url("../assets/images/lxmx-bg.png");
      }
      .bg-box.lxtl {
        background-image: url("../assets/images/lxtl-bg.png");
      }
      .bg-box.lxld {
        background-image: url("../assets/images/lxld-bg.png");
      }
      .bg-box.lxjx {
        background-image: url("../assets/images/lxjx-bg.png");
      }
      .level-box {
        display: flex;
        justify-content: space-between;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          &:last-child {
            margin-right: 0px;
            .icon {
              &::after {
                display: none;
              }
            }
          }

          .icon {
            width: 37px;
            height: 37px;
            margin-bottom: 12px;
            &::after {
              display: block;
              content: "";
              width: 62px;
              height: 2px;
              background: #766557;
              position: absolute;
              top: 20px;
              right: -71px;
            }
          }
          p {
            font-size: 16px;
            font-weight: 300;
            color: #323543;
          }
          .lxmx {
            background-image: url("../assets/images/lxmx2.png");
          }
          .lxtl {
            background-image: url("../assets/images/lxtl2.png");
          }
          .lxld {
            background-image: url("../assets/images/lxld2.png");
          }
          .lxjx {
            background-image: url("../assets/images/lxjx2.png");
          }
        }
        .item.active {
          .lxmx {
            background-image: url("../assets/images/lxmx2-active.png");
          }
          .lxtl {
            background-image: url("../assets/images/lxtl2-active.png");
          }
          .lxld {
            background-image: url("../assets/images/lxld2-active.png");
          }
          .lxjx {
            background-image: url("../assets/images/lxjx2-active.png");
          }
        }
      }
    }
    .content-box {
      margin-top: 28px;
      h1 {
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 15px;
        color: #262626;
      }
      h2 {
        font-size: 20px;
        font-weight: 600;
        color: #262626;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 10px;
      }
      ul {
        margin-bottom: 10px;
        list-style: none;
        li {
          font-size: 14px;
        }
      }
      table {
        margin-bottom: 30px;
        border-collapse: collapse;
        text-align: center;
      }
      table thead th {
        color: #515a6e;
        font-weight: 600;
        font-size: 14px;
        background: #f8f8f9;
        height: 35px;
        line-height: 35px;
      }
      table td,
      table th {
        border: 1px solid #dee2e6;
        height: 30px;
        font-size: 14px;
        font-weight: 400;
        color: #515a6e;
      }
    }
  }
}
</style>