<script>
export default {
  name: "Item",
  props: {
    data: Object,
  },
  data() {
    return {
      isShrink: true,
    };
  },
  methods: {
    toggleShrink() {
      this.isShrink = !this.isShrink;
    },
  },
  render() {
    return (
      <div class="item-box">
        <div class="top">
          <p class="title">{this.data.title}</p>
          <p class="time">{this.data.sendTime}</p>
        </div>
        <div class="bottom">
          <div
            class={`content ${this.isShrink ? "shrink" : ""}`}
            domPropsInnerHTML={this.data.content}
          />
          {this.data.content.length >= 500 ? (
            this.isShrink ? (
              <span class="btn unfold" onclick={this.toggleShrink}>
                点击展开
              </span>
            ) : (
              <span class="btn shrink" onclick={this.toggleShrink}>
                点击收回
              </span>
            )
          ) : null}
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.item-box {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 20px;
      font-weight: 400;
      color: #000000;
    }
    .time {
      font-size: 14px;
      font-weight: 400;
      color: #9ca6b2;
    }
  }
  .bottom {
    margin-top: 20px;
    .content {
      font-size: 14px;
      font-weight: 400;
      color: #a4aab2;
    }
    .content.shrink {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
    .btn {
      display: block;
      margin-top: 9px;
      cursor: pointer;
      margin-top: 9px;
      line-height: 24px;
      font-size: 14px;
      font-weight: 400;
      color: #066eeb;
      display: flex;
      align-items: center;
    }
    .btn.unfold {
      &::after {
        content: "";
        display: block;
        margin-left: 4px;
        background-image: url("../../../assets/images/down.png");
        background-size: 100%;
        background-repeat: no-repeat;
        width: 12px;
        height: 7px;
      }
    }
    .btn.shrink {
      &::after {
        content: "";
        display: block;
        margin-left: 4px;
        transform: rotate(180deg);
        background-image: url("../../../assets/images/down.png");
        background-size: 100%;
        background-repeat: no-repeat;
        width: 12px;
        height: 7px;
      }
    }
  }
}
</style>