<script>
import ModelBox from "@components/ModelBox";
export default {
  name: "abstract",
  render() {
    const { detailData } = this.$store.state.CountryDetailModel;
    const modelBoxData = {
      content: () => (
        <div class="main">
          <div
            class="content-box"
            domPropsInnerHTML={detailData.introduction}
          />
          <div class="btn-group">
            <span
              class="btn"
              onclick={() =>
                this.$router.push({
                  name: "teacherList",
                  query: { countryId: detailData.id },
                })
              }
            >
              <div class="left">
                <i class="icon teacher" />
                相关名师
              </div>
              {detailData.relevantTeacher}
            </span>
            <span class="btn" style={{cursor: "default"}}>
              <div class="left">
                <i class="icon student" />
                相关学子
              </div>
              {detailData.relevantStudent}
            </span>
            <span
              class="btn"
              onclick={() =>
                this.$router.push({
                  path: `/university/list?countryId=${detailData.id}`,
                })
              }
            >
              <div class="left">
                <i class="icon school" />
                相关院校
              </div>
              {detailData.relevantSchool}
            </span>
            <span class="btn"   onclick={() =>
                this.$router.push({
                  path: `/course/list?countryId=${detailData.id}`,
                })
              }>
              <div class="left">
                <i class="icon video" />
                相关视频
              </div>
              {detailData.relevantCourse}
            </span>
          </div>
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  .content-box {
    font-size: 16px;
    font-weight: 400;
    color: #5c6066;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .btn-group {
    display: flex;
    align-items: center;
    .btn {
      cursor: pointer;
      width: 174px;
      display: flex;
      justify-content: space-between;
      background: #edeff5;
      border-radius: 8px;
      height: 40px;
      padding: 0 14px;
      line-height: 40px;
      font-size: 20px;
      color: #1a1a1a;
      font-weight: 400;
      margin-right: 16px;
      &:hover {
        background: #006eeb;
        color: #ffffff;
        .left {
          color: #ffffff;
          .icon.teacher {
            background-image: url("../../../assets/images/woman@2x (1).png");
          }
          .icon.student {
            background-image: url("../../../assets/images/hands@2x (1).png");
          }
          .icon.school {
            background-image: url("../../../assets/images/school@2x (1).png");
          }
          .icon.video {
            background-image: url("../../../assets/images/video@2x (1).png");
          }
        }
      }
      .left {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #016eeb;
        .icon {
          margin-right: 8px;
        }
      }
    }
  }
}

.icon.teacher {
  width: 24px;
  height: 24px;
  background-image: url("../../../assets/images/woman@2x.png");
}
.icon.student {
  width: 24px;
  height: 24px;
  background-image: url("../../../assets/images/hands@2x.png");
}
.icon.school {
  width: 24px;
  height: 24px;
  background-image: url("../../../assets/images/school@2x.png");
}
.icon.video {
  width: 24px;
  height: 24px;
  background-image: url("../../../assets/images/video@2x.png");
}
</style>