<script>
import ModelBox from "@components/ModelBox";
import BaseItem from "../baseItem.vue";
export default {
  name: "hotStage",
  props: {
    list: Array,
    tabclick: {
      type: Function,
    },
  },
  data() {
    return {
      tabbarData: {
        list: [
          // 此ID根据枚举接口获取,不可更改
          { id: "5", value: "研究生" },
          { id: "4", value: "本科" },
          { id: "3", value: "高中" },
          { id: "7", value: "其他" },
        ],
        nowTab: "5",
      },
    };
  },
  methods: {
    onTabClick(record) {
      this.tabclick(record);
    },
  },
  render() {
    const modelBoxData = {
      blue: "热门阶段",
      yellow: "课程",
      tabbar: this.tabbarData,
      ontabclick: this.onTabClick,
      more: {
        title: "查看全部",
        onclick: () =>
          this.$router.push({
            path: "/course/list",
            query: { gradeId: this.tabbarData.nowTab },
          }),
      },
      content: () => (
        <div class="list">
          {this.list.map((item) => (
            <BaseItem
              data={{ ...item, jumpUrl: `/course/detail?id=${item.id}` }}
            />
          ))}
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}
</style>