<script>
import ModelBox from "@components/ModelBox";
import { urlNocanshu } from "../../../utils/index.js";
export default {
  name: "speaker",
  props: {
    list: Array,
  },
  methods: {
    // 用户打开Live800记录
    async immediatelyConsult() {
      try {
        await this.$store.dispatch("ClassDetailModel/recordBehavior", {
          app:3, 
          action:1,  
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pageurl:urlNocanshu(),
          accountId:window.$cookies.get("accountId") || ''
        });
      } catch (msg) {
        // this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
  },
  render() {
    const modelBoxData = {
      style: { marginTop: "0", marginBottom: "20px" },
      blue: "主讲",
      yellow: "老师",
      content: () => (
        <div class="main">
          {this.list.map((item) => (
            <div class="speaker-item">
              <img
                src={item.head}
                onclick={() =>
                  this.$router.push({
                    path: `/teacher/detail?id=${item.id}`,
                  })
                }
              />
              <p
                class="name"
                onclick={() =>
                  this.$router.push({
                    path: `/teacher/detail?id=${item.id}`,
                  })
                }
              >
                {item.name}
              </p>
              <p class="des">{item.abstract}</p>
              {item.speakerType == 1 ? 
                <a class="btn"
                  target="_bank" 
                  onclick={this.immediatelyConsult}
                  href="https://live800.eiceducation.com.cn/live800/chatClient/chatbox.jsp?companyID=8940&configID=79"
                  >立即咨询</a>
                : null}
            </div>
          ))}
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  .speaker-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    img {
      cursor: pointer;
      width: 160px;
      height: 160px;
      object-fit: cover;
      border-radius: 50%;
      margin-top: 27px;
    }
    .name {
      cursor: pointer;
      font-size: 20px;
      font-weight: 400;
      color: #262626;
      margin-top: 17px;
    }
    .des {
      margin-top: 18px;
      font-size: 14px;
      font-weight: 400;
      color: #8a9099;
    }
    .btn {
      text-decoration: none;
      display: block;
      margin-top: 27px;
      width: 120px;
      height: 30px;
      background: #006eeb;
      border-radius: 15px;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      color: #ffffff;
    }
  }
}
</style>