<script>
  import logo from "@assets/images/eicdata2empty.png";
  export default {
    name: "eicdata2empty",
    props:{
    },
    render() {
      return (
        <div class="background">
          <div> 
            <img class="logo" src={logo} />
            <p>这居然啥都没有！！</p>
          </div>
        </div>
      );
    },
  };
  </script>
  <style lang="less" scoped>
  .background {
    height: 300px;
    text-align: center;
    margin-top: 140px;
  }
  </style>