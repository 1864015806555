<script>
import Loading from "@components/Loading";
import gz from "@assets/images/gz.png";
import ModelBoxMy from "@components/ModelBoxMy.vue";
export default {
  name: "LearnTime",
  async mounted() {
    if (window.$cookies.get("token")) {
      try {
        await this.$store.dispatch("MyModel/getUserInfo");
      } catch (error) {
          if (error.code == 1016) {
            this.$message({ showClose: true, message: error.msg, type: "error" });
            window.$cookies.set("token",'')
          }
          return;
        }
    }
    try {
      await this.$store.dispatch("MyModel/getUserLearnTime");
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  render() {
    const {
      learnTimeData,
      isLoadingShow,
      userInfo,
    } = this.$store.state.MyModel;
    const { head } = userInfo;
    const modelData = {
      blue: "学习",
      yellow: "时长",
      content: () => (
        <div class="main">
          <div class="box1">
            <div class="imgBox">
              <img src={head} />
             {/* <!-- <span>学神</span>-->*/}
            </div>
            <div class="info-box">
              <div class="top">
                <p>
                  今日学习了<span class="red">{learnTimeData && learnTimeData.todayStudyCourse}</span>个课程
                </p>
                <p>
                  累计学习<span class="red">{learnTimeData && learnTimeData.todayStudyTime}</span>分钟
                </p>
              </div>
             {/* <!--<div class="bottom">
                <p>
                  不愧是你，今日已打败全国<span class="blue">96%</span>
                  的用户！加油吧
                </p>
              </div>-->*/}
            </div>
            <i class="icon-lt1" />
          </div>
          <div class="box2">
            <div class="title">你已在启德课堂坚持自主学习：</div>
            <div class="learnDay">
              <p class="text">学习天数</p>
              <p class="number">
                {learnTimeData && learnTimeData.studyDay}
                <span>天</span>
              </p>
            </div>
            <div class="learnTime">
              <p class="text">学习时长</p>
              <p class="number">
                {learnTimeData && learnTimeData.studyTime}
                <span>小时</span>
              </p>
            </div>
            <i class="icon-lt2" />
          </div>
          <div class="box3">
            <img src={gz} />
            <div class="info-box">
              <i class="icon-logo" />
              <div class="text-box">
                <p>
                  微信扫码关注<span>启德课堂</span>公众号
                </p>
                <p>随时随地 想学就学</p>
              </div>
            </div>
          </div>
          <Loading isShow={isLoadingShow} />
        </div>
      ),
    };
    return <ModelBoxMy data={modelData} />;
  },
};
</script>
<style lang="less" scoped>
.main {
  margin-top: 83px;
  //   padding: 0 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .box1 {
    width: 690px;
    margin: 0 auto;
    height: 190px;
    background: #fcfdff;
    border: 2px solid #82bdff;
    border-radius: 10px;
    padding: 25px 0 30px 49px;
    box-sizing: border-box;
    display: flex;
    position: relative;
    .imgBox {
      width: 127px;
      height: 127px;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
      span {
        position: absolute;
        display: block;
        min-width: 96px;
        line-height: 28px;
        background: #187bec;
        color: #ffffff;
        height: 28px;
        font-size: 21px;
        font-weight: 400;
        color: #f6f9fc;
        text-align: center;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 16px;
      }
    }
    .info-box {
      margin-top: 25px;
      margin-left: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top {
        p {
          font-size: 24px;
          font-weight: bold;
          color: #0b75ee;
          line-height: 42px;
          span.red {
            padding: 0 5px;
            font-size: 26px;
            color: #f94164;
          }
        }
      }
      .bottom {
        p {
          font-size: 16px;
          color: #0b75ee;
          font-weight: 400;
          span.blue {
            font-size: 30px;
            font-weight: bold;
            padding: 0 5px;
          }
        }
      }
    }
    .icon-lt1 {
      display: block;
      width: 99px;
      height: 136px;
      background-image: url("../../../assets/images/leantime2.png");
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      right: 54px;
      top: -57px;
    }
  }
  .box2 {
    width: 690px;
    margin: 86px auto 0;
    height: 190px;
    background: #fcfdff;
    border: 2px solid #82bdff;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      position: absolute;
      top: -47px;
      background: #238afd;
      left: 50%;
      transform: translateX(-50%);
      height: 47px;
      color: #ffffff;
      width: 439px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      font-weight: bold;
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
    }
    .learnDay {
      display: flex;
      flex-direction: column;
      align-items: center;
      .text {
        font-size: 26px;
        font-weight: 400;
        color: #006eeb;
      }
      .number {
        font-size: 60px;
        font-weight: bold;
        color: #006eeb;
        span {
          font-size: 24px;
        }
      }
    }
    .learnTime {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 120px;
      .text {
        font-size: 26px;
        font-weight: 400;
        color: #006eeb;
      }
      .number {
        font-size: 60px;
        font-weight: bold;
        color: #006eeb;
        span {
          font-size: 24px;
        }
      }
    }
    .icon-lt2 {
      display: block;
      width: 96px;
      height: 141px;
      background-image: url("../../../assets/images/leantime1.png");
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      left: 29px;
      top: -68px;
    }
  }
  .box3 {
    margin: 44px auto 0;
    width: 434px;
    padding-bottom: 28px;
    display: flex;
    img {
      width: 143px;
      height: 143px;
      object-fit: cover;
    }
    .info-box {
      margin-left: 18px;
      .icon-logo {
        display: block;
        width: 59px;
        height: 44px;
        background-image: url("../../../assets/images/logo.png");
        background-size: 100%;
        background-repeat: no-repeat;
        position: relative;
        &::after {
          content: "";
          display: block;
          height: 2px;
          width: 46px;
          background: #cccccc;
          position: absolute;
          bottom: -13px;
          left: 0;
        }
      }
      .text-box {
        margin-top: 34px;
        p {
          font-size: 20px;
          font-weight: 400;
          color: #000000;
          span {
            color: #f94164;
            font-size: 20px;
            padding: 0 5px;
          }
        }
      }
    }
  }
}
</style>