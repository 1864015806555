<script>
export default {
  name: "schoolItem",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="schoolItemBox">
        <div class="photo">
          <img
            src={this.data.badge}
            onclick={() => {
              this.$router.push({name:"universityDetail",query:{id:this.data.id}});
            }}
          />
        </div>
        <div class="name">{this.data.name}</div>
        {this.data.nature === 0 && <span class="tag public">公立</span>}
        {this.data.nature === 1 && <span class="tag private">私立</span>}
        {this.data.nature === 2 && <span class="tag public">国立</span>}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.schoolItemBox {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  position: relative;
  &:last-child {
    margin-right: 0px;
  }
  .photo {
    width: 155px;
    height: 155px;
    border: 1px solid #dce0e6;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 108px;
      height: 108px;
      object-fit: contain;
      cursor: pointer;
    }
  }
  .name {
    display: flex;
    color: #262626;
    font-size: 16px;
    font-weight: 400;
    margin-top: 12px;
    align-items: center;
    line-height: 23px;
    justify-content: center;
  }
  .tag {
    position: absolute;
    top: 8px;
    left: 8px;
    // width: 36px;
    height: 18px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 18px;
    padding: 0px 6px;
  }
  .tag.public {
    background: RGBA(219, 235, 252, 1);
    color: #006eeb;
  }
  .tag.private {
    background: RGBA(255, 237, 228, 1);
    color: #ff833e;
  }
}
</style>