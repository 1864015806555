<script>
import blockComp from "./blockComp/index";
import liveItem from "./liveItem/index";
import recentItem from "./recentItem/index";
import ModelBox from "@components/ModelBox";

export default {
  name: "liveClass",
  data() {
    return {
      nowData: {}, // 当前选中数据
      activeId: null, // 选中id
    };
  },
  mounted() {
    const { liveClassList } = this.$store.state.HomeModel;
    this.nowData = liveClassList[0];
	
    this.activeId = liveClassList[0].id;
  },
  methods: {
    onMouseOver(record) {
      this.nowData = null;
      this.activeId = record.id;
      this.nowData = record;
    },
  },
  render() {
    const { liveClassList } = this.$store.state.HomeModel;
	console.log(this.nowData)
    const modelBoxData = {
      blue: "精选",
      yellow: "直播课",
      more: {
        title: "更多直播",
        onclick: () => 
         this.$router.push({ path: "/live/list" })
      },
      content: () => (
        <div class="content">
          <blockComp data={this.nowData} />
          <div class="list">
            {liveClassList.map((item) => {
              if (item.status === 2)
                return (
                  <liveItem
                    data={item}
                    mouseover={this.onMouseOver}
                    activeid={this.activeId}
                  />
                );
              if (item.status === 1)
                return (
                  <recentItem
                    data={item}
                    mouseover={this.onMouseOver}
                    activeid={this.activeId}
                  />
                );
            })}
          </div>
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.content {
  margin-top: 21px;
  display: flex;
  justify-content: space-between;

  .list {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    height: 295px;
    overflow-y: scroll;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
}
</style>