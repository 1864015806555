<script>
import * as api from "@src/service/index";
import { debounce } from "../../../utils/index.js";
export default {
  name: "searchArea",
  props: {
    countryList: Array,
  },
  methods: {
    // 搜索框输入事件
    async onInput(e) {
      const { searchData, pageSize } = this.$store.state.LiveList;
      this.$store.commit("LiveList/updateState", {
        searchData: { ...searchData, keyword: e.target.value },
      });
      this.loadList();
    },
    async loadList() {
      const { searchData, pageSize } = this.$store.state.LiveList;
      try {
        await this.$store.dispatch("LiveList/getLiveList", {
          page: 1,
          pageSize,
          ...searchData,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
  },
  render() {
    const {
      searchData,
      countryList,
      liveTypeList,
      liveStateTypeList,
      spearkerTypeList,
      professionalTypeList,
      gradeTypeList,
    } = this.$store.state.LiveList;
    return (
      <div class="box">
        <div class="searchItem" style={{ alignItems: "center" }}>
          <span class="label black">您已输入</span>
          <div class="inputBox">
            <input onInput={debounce((e) => this.onInput(e), 500)} />
          </div>
        </div>
        <div class="searchItem">
          <span class="label blue">国家/地区</span>
          <div class="tagGroup">
            {countryList.map((item) => (
              <span
                class={`tag ${
                  item.id === searchData.countryId ? "active" : ""
                }`}
                onclick={() => {
                  this.$store.commit("LiveList/updateState", {
                    searchData: {
                      ...searchData,
                      countryId: item.id,
                    },
                  });
                  this.loadList();
                }}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div class="searchItem">
          <span class="label blue">直播内容</span>
          <div class="tagGroup">
            {liveTypeList.map((item) => (
              <span
                class={`tag ${
                  item.value === searchData.categoryId ? "active" : ""
                }`}
                onclick={() => {
                  this.$store.commit("LiveList/updateState", {
                    searchData: {
                      ...searchData,
                      categoryId: item.value,
                    },
                  });
                  this.loadList();
                }}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div class="searchItem">
          <span class="label blue">直播状态</span>
          <div class="tagGroup">
            {liveStateTypeList.map((item) => (
              <span
                class={`tag ${
                  item.value === searchData.status ? "active" : ""
                }`}
                onclick={() => {
                  this.$store.commit("LiveList/updateState", {
                    searchData: {
                      ...searchData,
                      status: item.value,
                    },
                  });
                  this.loadList();
                }}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div class="searchItem">
          <span class="label blue">主讲人</span>
          <div class="tagGroup">
            {spearkerTypeList.map((item) => (
              <span
                class={`tag ${
                  item.value === searchData.speakerType ? "active" : ""
                }`}
                onclick={() => {
                  this.$store.commit("LiveList/updateState", {
                    searchData: {
                      ...searchData,
                      speakerType: item.value,
                    },
                  });
                  this.loadList();
                }}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div class="searchItem">
          <span class="label blue">专业分类</span>
          <div class="tagGroup">
            {professionalTypeList.map((item) => (
              <span
                class={`tag ${
                  item.value === searchData.professionId ? "active" : ""
                }`}
                onclick={() => {
                  this.$store.commit("LiveList/updateState", {
                    searchData: {
                      ...searchData,
                      professionId: item.value,
                    },
                  });
                  this.loadList();
                }}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div class="searchItem">
          <span class="label blue">留学阶段</span>
          <div class="tagGroup">
            {gradeTypeList.map((item) => (
              <span
                class={`tag ${
                  item.value === searchData.gradeId ? "active" : ""
                }`}
                onclick={() => {
                  this.$store.commit("LiveList/updateState", {
                    searchData: {
                      ...searchData,
                      gradeId: item.value,
                    },
                  });
                  this.loadList();
                }}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.box {
  padding: 24px;
  background: #fff;
  border-radius: 16px;
  .searchItem {
    display: flex;
    align-items: baseline;
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
    .inputBox {
      width: 260px;
      height: 32px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      background: #f3f6fa;
      input {
        margin-left: 15px;
        color: #333333;
      }
    }
    .tagGroup {
      display: flex;
      flex-wrap: wrap;
      .tag {
        color: #333333;
        cursor: pointer;
        font-size: 14px;
        padding: 0 20px;
        height: 32px;
        line-height: 32px;
        margin-bottom: 5px;
      }
      .tag.active {
        background: #f0f7ff;
        border-radius: 8px;
        color: #006eeb;
        font-weight: bold;
      }
    }
    .label {
      display: block;
      min-width: 80px;
      text-align: left;
      font-size: 18px;
      margin-right: 25px;
      white-space: nowrap;
    }
    .label.black {
      color: #1a1a1a;
    }
    .label.blue {
      color: #006eeb;
    }
  }
}
</style>