<script>
import Swiper from "@components/Swiper.vue";
import LiveClass from "./liveClass/index.vue";
import HotClass from "@components/hotClass/index";
import TeacherRCD from "./teacherRCD/index";
import HotSchool from "./hotSchool/index";
import StudentShare from "@components/studentShare/index";
import GuessLike from "./guessLike/index";
import Loading from "@components/Loading";
import { randomNumber,GetQueryString,urldelCode,urlNocanshu } from "../../utils/index.js";
export default {
  name: "Home",
  methods: {
    // 热门课程组件tab切换事件
    async onHotClassTabClick(record) {
      try {
        await this.$store.dispatch("HomeModel/getCourseList", {
          page: 1,
          pageSize: 10,
          categoryId: record.id, // 默认获取专业
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },

    // 学子分享组件tab切换事件
    async onShareTabClick(record) {
      try {
        await this.$store.dispatch("HomeModel/getStudentShareLiveList", {
          categoryId: record.id,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
    
    // 学子分享组件tab切换事件(全部)
    async onShareTabClick(record) {
      try {
        await this.$store.dispatch("HomeModel/getLiveList", {
          page: 1,
          pageSize:4,
          categoryId: record.id,
          status: 4
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    if (window.$cookies.get("token")) {
        urldelCode()
    }
    try {
      await this.$store.dispatch("HomeModel/getPageInfoList", { id: 125 });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
      await this.$store.dispatch("HomeModel/getRecentLive", {
        page: 1,
        pageSize: 10,
        type: 2,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
      await this.$store.dispatch("HomeModel/getCourseList", {
        page: 1,
        pageSize: 10,
        categoryId: "1", // 默认获取专业
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
      await this.$store.dispatch("HomeModel/getTeacherOnlineLiveList", {
        categoryId: 2,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    if (window.$cookies.get("token")) {
      try {
        await this.$store.dispatch("MyModel/getUserInfo");
      } catch (error) {
          if (error.code == 1016) {
            this.$message({ showClose: true, message: error.msg, type: "error" });
            window.$cookies.set("token",'')
          }
          return;
        }
      const { userInfo } = this.$store.state.MyModel;
      const { gradeId, countryId } = userInfo;

      try {
        await this.$store.dispatch("HomeModel/getGuessLike", {
          countryId,
          gradeId,
          page: 1,
          pageSize: 8,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    } else {
      try {
        await this.$store.dispatch("HomeModel/getGuessLike", {
          page: 1,
          pageSize: 8,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    }

    try {
      await this.$store.dispatch("HomeModel/getLiveList", {
        page: 1,
        pageSize:4,
        categoryId:"18",
        status: 4
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
      await this.$store.dispatch("HomeModel/getStudentShareLiveList", {
        categoryId: "18",
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    
    try {
        await this.$store.dispatch("ClassModel/recordAllPage", {
          app:3, 
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pvId:randomNumber(),
          medium: window.$cookies.get("datachannelmed") || '',
          extendurl: window.$cookies.get("dataurl") || '', 
          pageurl:urlNocanshu(),
          fullpageurl:window.location.href,
          accountId:window.$cookies.get("accountId") || ''
        });
    } catch (msg) {
      // this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },

  render() {
    const {
      isLoadingShow,
      swiperList,
      liveClassList,
      teacherList,
      courseList,
      guessLikeList,
      studentShareList,
      studentShareLists,
    } = this.$store.state.HomeModel;
    return (
      <div class="container">
        {swiperList.length ? <Swiper list={swiperList} /> : null}
        {liveClassList.length ? <LiveClass /> : null}
        <HotClass list={courseList} tabclick={this.onHotClassTabClick} />
        {teacherList.length ? <TeacherRCD list={teacherList} /> : null}
        <HotSchool />
        <StudentShare list={studentShareLists} tabclick={this.onShareTabClick} />
        <GuessLike list={guessLikeList} />
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>

<style  scoped>
.container {
  width: 1100px;
  margin: 0 auto;
  padding: 32px 0 64px;
}
</style>