
<script>
export default {
  name: "Item",
  render() {
    return (
      <div class="item">
        <div class="status green">
          <div class="exchange">
            <span>课 程</span>
            <p>兑换券</p>
          </div>
        </div>
        <div class="content">
          <div class="top">
            <p class="title">雅思在线词汇通关营通关金融</p>
            <p class="des">就业篇（金融方向）仅用于相关课程</p>
          </div>
          <div class="bottom">
            <p class="time">有效期：2020.03.60 - 2020.10.28</p>
            <span class="btn green">立即使用</span>
          </div>
          <i class="icon-check" />
        </div>
        {/*  <div class="status green">
          <div class="exchange">
            <p class="text1">课 程</p>
            <p>兑换券</p>
          </div>
        </div>
        <div class="status purple">
          <div class="discount">
            <p>7.8</p>
            <span>折</span>
          </div>
        </div>
        */}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.item {
  position: relative;
  display: flex;
  width: 404px;
  margin-right: 26px;
  margin-bottom: 24px;
  &:nth-child(2n) {
    margin-right: 0;
  }
  .status {
    width: 110px;
    height: 98px;
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    .voucher {
      color: #ffffff;
      margin-top: -10px;
      span {
        padding-left: 10px;
        font-size: 12px;
        font-weight: bold;
      }
      p {
        padding-left: 20px;
        font-size: 30px;
        font-weight: bold;
        margin-top: -10px;
      }
    }
    .exchange {
      color: #ffffff;
      font-size: 26px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      justify-content: center;
      p {
        text-align: center;
      }
    }
    .discount {
      color: #ffffff;
      display: flex;
      align-items: baseline;
      width: 100%;
      justify-content: center;
      p {
        font-size: 40px;
        font-weight: bold;
      }
      span {
        font-size: 16px;
        margin-left: 7px;
      }
    }
  }
  .content {
    background: #f3f6fa;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 18px;
        font-weight: 500;
        color: #000000;
      }
      .des {
        font-size: 12px;
        font-weight: 500;
        color: #5c6066;
        margin-top: 5px;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .time {
        font-size: 12px;
        font-weight: 500;
        color: #5c6066;
      }
      .btn {
        height: 27px;
        border-radius: 10px;
        min-width: 66px;
        font-size: 14px;
        color: #ffffff;
        display: block;
        line-height: 27px;
        text-align: center;
        margin-left: 15px;
        cursor: pointer;
      }
      .btn.red {
        background: #f15544;
      }
      .btn.green {
        background: #00c48c;
      }
      .btn.purple {
        background: #7b57ff;
      }
    }
    .icon-check {
      display: block;
      width: 21px;
      height: 21px;
      background-image: url("../../../../assets/images/pay-check.png");
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
  .icon-use {
    display: block;
    position: absolute;
    width: 62px;
    height: 62px;
    background-image: url("../../../../assets/images/icon-use.png");
    background-size: 100%;
    background-repeat: no-repeat;
    top: 0;
    right: 0;
  }
  .status.red {
    background-image: url("../../../../assets/images/discounts-red.png");
  }
  .status.purple {
    background-image: url("../../../../assets/images/discounts-purple.png");
  }
  .status.gray {
    background-image: url("../../../../assets/images/discounts-gray.png");
  }
  .status.green {
    background-image: url("../../../../assets/images/discounts-green.png");
  }
}
</style>