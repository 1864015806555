<script>
export default {
  name: "fail",
  props: {
    isShow: Boolean,
    closeFuc: {
      type: Function,
    },
  },
  render() {
    return (
      <div class="shadow">
        <div class="box">
          <i
            class="icon-close"
            onclick={() => {
              this.closeFuc();
            }}
          />
          <p class="title">支付失败</p>
          <div class="payBox">
            <i class="icon-pay-fail" />
            <span>
              支付失败，请<i onclick={() => this.closeFuc()}>重新支付</i>
            </span>
          </div>
        </div>
      </div>
    );
  },
};
</script>

<style lang="less" scoped>
.shadow {
  position: fixed;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  top: 0;
  left: 0;
  .box {
    display: block;
    width: 566px;
    background: #ffffff;
    border-radius: 16px;
    position: relative;
    padding: 25px;
    box-sizing: border-box;
    .icon-close {
      display: block;
      width: 31px;
      height: 31px;
      background-image: url("../../assets/images/pay-close.png");
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
    }
    .title {
      font-size: 30px;
      color: #1a1a1a;
      font-weight: bold;
      text-align: center;
    }
    .code {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 17px;
      img {
        width: 194px;
        height: 194px;
        object-fit: cover;
      }
      p {
        background: #e5f0fd;
        font-size: 20px;
        font-weight: 500;
        color: #006eeb;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        margin-top: 12px;
        .icon-scan {
          display: block;
          width: 24px;
          height: 24px;
          background-image: url("../../assets/images/scan.png");
          background-repeat: no-repeat;
          background-size: 100%;
          margin-right: 4px;
        }
      }
    }
    .payBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon-pay-success {
        display: block;
        width: 60px;
        height: 60px;
        background-image: url("../../assets/images/pay-success.png");
        background-size: 100%;
        background-repeat: no-repeat;
        margin-top: 31px;
      }
      .icon-pay-fail {
        display: block;
        width: 60px;
        height: 60px;
        background-image: url("../../assets/images/pay-fail.png");
        background-size: 100%;
        background-repeat: no-repeat;
        margin-top: 31px;
      }
      span {
        display: block;
        height: 60px;
        border-radius: 8px;
        background: #006eeb;
        color: #ffffff;
        padding: 0 12px;
        line-height: 60px;
        margin-top: 38px;
        i {
          text-decoration: underline;
          font-style: normal;
          cursor: pointer;
        }
      }
    }
  }
}
</style>