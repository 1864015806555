<script>
export default {
  name: "liveItem",
  props: {
    data: Object,
    mouseover: {
      type: Function,
    },
    activeid: Number,
  },
  render() {
    return (
      <div
        class={`living-item  ${this.data.id === this.activeid ? "active" : ""}`}
        onmouseover={()=>this.mouseover(this.data)}

      >
        <i class="icon-living" />
        <span class="state">直播中</span>
        <span class="text">{this.data.name}</span>
      </div>
    );
  },
};
</script>

<style lang="less" scoped>
.living-item {
  display: flex;
  align-items: center;
  height: 46px;
  min-height: 46px;
  padding: 0 15px;
  cursor: pointer;
  margin-bottom: 16px;
  border-radius: 16px;
  position: relative;
  background: #ffede2;

  .icon-living {
    display: block;
    margin-right: 10px;
    width: 48px;
    height: 17px;
    background-size: 25px 17px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 10px;
    background-image: url("../../../assets/images/icon-live-yellow.png");
  }

  .state {
    font-size: 16px;
    font-weight: bold;

    color: #ff833e;
    margin-right: 10px;
  }
  .text {
    width: 418px;
    font-weight: 400;
    color: #262626;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.living-item.active {
  background: #ff833e;
  &::before {
    content: "";
    display: block;
    width: 10px;
    height: 18px;
    background-image: url("../../../assets/images/icon-triangle-yellow.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    left: -16px;
  }
  .icon-living {
    background-image: url("../../../assets/images/icon-live-white.png");
  }
  .state {
    color: #ffffff;
  }
  .text {
    color: #ffffff;
  }
}
</style>