<script>
import Modal from "../modal"
export default {
  name: "item",
  props: {
    data: Object,
  },
  data(){
    return{
      showModal:false
    }
  },
  methods: {
    onShowModal() {
      // this.$store.commit("MyModel/updateState", { shopModalShow: true });
      this.showModal = true;
    },
    toDetail() {
      this.$store.commit("MyModel/updateState", {
        integralState: "detail",
        integralShopDetailId:this.data.id
      });
    },
  },
  render() {
    return (
      <div class="item-box">
        <img src={this.data.coverPhoto} onclick={this.toDetail} />
        <div class="info">
          <h3>{this.data.title}</h3>
          <span class="tag">{this.data.scorePrice}积分</span>
          <p>订单日期：{this.data.createTime} </p>
          <div class="btn-group">
            {this.data.type===2?<span class="btn" onclick={this.onShowModal}>
              物流状态
            </span>:null}
          </div>
        </div>
       {this.showModal?<Modal close={()=>this.showModal = false} data={this.data}/>:null} 
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.item-box {
  display: flex;
  align-items: center;
  width: 100%;
  padding:20px 0;
  border-bottom: 1px solid #dce0e5;
  img {
    width: 263px;
    height: 168px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
  }
  .info {
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    width: 100%;
    h3 {
      font-size: 18px;
      font-weight: 500;
      color: #000000;
      margin-bottom: 15px;
    }
    span.tag {
      display: block;
      background: #feecef;
      height: 24px;
      line-height: 24px;
      padding: 0 8px;
      font-weight: 500;
      font-size: 16px;
      color: #f94164;
      margin-bottom: 12px;
      border-radius: 8px;
      width: fit-content;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #9ca6b2;
    }
    .btn-group {
      display: flex;
      justify-content: flex-end;
      .btn {
        display: block;
        background: #066eeb;
        width: 86px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }
}
</style>