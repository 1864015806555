
<script>
import ModelBox from "@components/ModelBox";
import BaseItem from "../BaseItem";
export default {
  name: "policy",
  props:{
    list:Array
  },
  render() {
    const modelBoxData = {
      blue: "最新",
      yellow: "政策",
      content: () => (
        <div class="main">
          <div class="list">
            {this.list.map((item) => (
              <BaseItem data={item} />
            ))}
          </div>
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.main {
  margin-top: 20px;
  .list {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>