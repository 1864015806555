<script>
export default {
  name: "item",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="item-box">
        <div class="imgBox">
          {this.data.status === 2 ? (
            <div class="living">
              <i class="icon-live" />
              <span>正在直播</span>
            </div>
          ) : null}
          <img
            src={this.data.coverPhoto}
            onclick={() =>
              this.$router.push({ path: `/live/detail?id=${this.data.id}` })
            }
          />
          {this.data.status === 1 || this.data.status === 2 ? (
            <div class="shadow" style={{ justifyContent: "space-between" }}>
              <i class="icon-live-text" />
              <div class="people">
                <i class="icon-people" />
                {this.data.subscribeCount}人预约
              </div>
            </div>
          ) : null}
          {this.data.status === 3 ? (
            <div class="shadow">
              <i class="icon-eye" />
              <p>{this.data.viewTimes}人已观看</p>
            </div>
          ) : null}
        </div>
        <div class="content">
          <div class="infoBox">
            <p class="des">{this.data.name}</p>
            {this.data.status === 1 ? (
              <p class="time">
                <i class="icon-time" />
                {this.$moment
                  .unix(this.data.liveTime)
                  .format("YYYY-MM-DD HH:mm")}
                -
                {this.$moment
                  .unix(this.data.endTime)
                  .format("YYYY-MM-DD HH:mm")}
              </p>
            ) : null}
            {this.data.status === 3 ? (
              <p class="record">
                <i class="icon-record" />
                直播回放
              </p>
            ) : null}
            {this.data.status === 2 ? (
              <p class="time active">
                <i class="icon-time" />
                {this.$moment
                  .unix(this.data.liveTime)
                  .format("YYYY-MM-DD HH:mm")}
                -
                {this.$moment
                  .unix(this.data.endTime)
                  .format("YYYY-MM-DD HH:mm")}
              </p>
            ) : null}
          </div>
          <div class="btngroup">
            <div class="tags">
              {this.data.tags &&
                this.data.tags.split(",").map((item) => <span>{item}</span>)}
            </div>
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.item-box {
  display: flex;
  border-radius: 16px;
  width: 514px;
  height: 186px;
  background: #f0f7ff;
  padding: 16px;
  box-sizing: border-box;
  margin-right: 24px;
  margin-bottom: 24px;
  &:nth-child(2n) {
    margin-right: 0;
  }
  .imgBox {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    width: 242px;
    min-width: 242px;
    height: 154px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
    .living {
      position: absolute;
      top: 10px;
      left: 10px;
      background: #ff833e;
      width: 125px;
      height: 25px;
      border-radius: 13px;
      padding: 4px 17px 4px 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .icon-live {
        display: block;
        width: 18px;
        height: 15px;
        margin-right: 10px;
        background-image: url("../../assets/images/icon-live-white.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .shadow {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 42px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      padding: 17px 12px 0px 12px;
      box-sizing: border-box;
      .icon-live-text {
        display: block;
        width: 37px;
        height: 13px;
        background-image: url("../../assets/images/live-text.png");
        background-size: 100%;
        background-repeat: no-repeat;
      }
      .people {
        display: flex;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        .icon-people {
          display: block;
          width: 17px;
          height: 17px;
          background-image: url("../../assets/images/icon-people.png");
          background-size: 100%;
          background-repeat: no-repeat;
          margin-right: 8px;
        }
      }
      p {
        font-size: 14px;
        font-weight: 300;
        color: #ffffff;
      }
      .icon-eye {
        width: 16px;
        height: 13px;
        display: block;
        background-image: url("../../assets/images/icon-eye.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-right: 8px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    justify-content: space-between;
    .infoBox {
      display: flex;
      flex-direction: column;
      .des {
        font-size: 16px;
        font-weight: 400;
        color: #262626;
        margin-top: 10px;
      }
      .time {
        color: #a4aab3;
        font-size: 16px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        .icon-time {
          display: block;
          width: 17px;
          height: 17px;
          background-image: url("../../assets/images/time.png");
          background-size: 100%;
          background-repeat: no-repeat;
          margin-right: 4px;
        }
      }
      .time.active {
        color: #ff833e;
        .icon-time {
          background-image: url("../../assets/images/time-active.png");
        }
      }
      .record {
        color: #a4aab3;
        font-size: 16px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        .icon-record {
          display: block;
          width: 17px;
          height: 17px;
          background-image: url("../../assets/images/play.png");
          background-size: 100%;
          background-repeat: no-repeat;
          margin-right: 4px;
        }
      }
    }
    .btngroup {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tags {
        display: flex;
        span {
          display: block;
          padding: 0 9px;
          font-size: 14px;
          font-weight: 300;
          color: #006eeb;
          border: 1px solid #006eeb;
          border-radius: 8px;
          height: 23px;
          line-height: 23px;
          margin-right: 8px;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>