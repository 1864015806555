<script>
import DialogComp from "@components/Dialog";
import qrReport from "@assets/images/qr_report.png";
export default {
  name: "PopQr",
  props: {
    id: Number,
    typeId: String,
    isShow: Boolean,
    closeFunc: {
      type: Function,
    },
    title: String, //二维码上面标题文字
    desc: String, //二维码下面面描述文字
    isView: Boolean, //是否有启德课堂小程序浏览留学报告授权
  },
  data() {
    return {
      url: "",
    };
  },
  async created() {
    let typeId = this.typeId;
    //console.log (this.typeId)
    //console.log (this.isView)
    if (typeId == "1") {
      this.url = "pages/user/user"; //有授权,启德课堂小程序->个人中心
    }
    if (typeId == "2") {
      this.url = "pages/index/live-detail"; //直播
    }
    if (typeId == "3") {
      this.url = "pages/class/class-detail"; //课程
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch("MyModel/getUrlQRCode", {
        url: this.url,
        params: "id=" + this.id,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  render() {
    // console.log(this.id + ',' + this.typeId);
    const { qrCode } = this.$store.state.MyModel;
    //console.log (this.isView)
    //console.log(qrCode);
    const dialogData = {
      onclose: this.closeFunc,
      style: { width: "380px" },
      content: () => (
        <div class="popqr-box">
          <h5>{this.title}</h5>
          <div class="popqr-img">
            {this.typeId == "1" ? (
              this.isView ? (
                //留学报告并且有授权，弹出启德课堂小程序个人中心二维码
                <img src={"data:image/png;base64," + qrCode} />
              ) : (
                //直播及课程详情分享,弹出启德小程序看一看留学报告二维码
                <img src={qrReport} />
              )
            ) : (
              //直播及课程详情分享,弹出启德小程序看一看留学报告二维码
              <img src={"data:image/png;base64," + qrCode} />
            )}
          </div>
          <p>
            *请使用微信扫一扫
            <br />
            {this.desc}
          </p>
        </div>
      ),
    };
    return <DialogComp v-show={this.isShow} data={dialogData} />;
  },
};
</script>
<style lang="less" scoped>
.popqr-box {
  padding: 0 32px;
  h5 {
    font-size: 20px;
    color: #1a1a1a;
    text-align: center;
  }
  .popqr-img {
    width: 150px;
    height: 150px;
    padding: 10px;
    background: #efefef;
    margin: 20px auto;
    img {
      width: 150px;
      height: 150px;
      display: block;
      background: #fff;
    }
  }
  p {
    font-size: 14px;
    color: #1a1a1a;
    text-align: center;
  }
}
</style>
