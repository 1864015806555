<script>
import Item from "./Item";
import Pagination from "@components/Pagination.vue";
export default {
  name: "comment",
  data() {
    return {
      id: "",
      reviewsContent: "", // 评论内容
      pageSize: 5,
    };
  },
  mounted() {
    const { id } = this.$route.query;
    this.id = id;
  },
  methods: {
    async submitReviews() {
      if (!window.$cookies.get("token")) {
        this.$store.commit("LoginModel/updateState", {
          isShowLogin: true,
          loginState: "login",
        });
        return;
      }

      try {
        await this.$store.dispatch("ClassDetailModel/addUserReviews", {
          id: this.id,
          content: this.reviewsContent,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }

      try {
        await this.$store.dispatch("ClassDetailModel/getCourseReview", {
          id: this.id,
          page: 1,
          pageSize: this.pageSize,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }

      this.reviewsContent = "";
    },
  },
  render() {
    const { reviewList, reviewCount } = this.$store.state.ClassDetailModel;
    const paginationObj = {
      totalPage: Math.ceil(reviewCount / this.pageSize),
      maxButton: 5,
      pageChange: async (record) => {
        try {
          await this.$store.dispatch("ClassDetailModel/getCourseReview", {
            id: this.id,
            page: record,
            pageSize: this.pageSize,
          });
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      },
    };
    return (
      <div class="commentBox">
        <div class="head">
          <div class="titleBox">
            <div class="title">
              <span class="blue">课程</span>
              <span class="yellow">评论</span>
              <span class="num">({reviewCount})</span>
            </div>
          </div>
          <p>用户评论仅供其表达个人看法，并不表明启德课堂立场</p>
        </div>
        <div class="inputBox">
          <div class="main">
            <textarea
              placeholder="发了这条评论说不定我就火了～"
              oninput={(e) => (this.reviewsContent = e.target.value)}
              value={this.reviewsContent}
            ></textarea>
            <div class="noLogin" style={{ display: "none" }}>
              <span class="btn">去登录</span>
              <p>先登录才能发表评论哦~</p>
            </div>
          </div>
          <span onclick={this.submitReviews}>发表</span>
        </div>
        {reviewList.length ? (
          <div class="list">
            {reviewList.map((item) => (
              <Item data={item} />
            ))}
          </div>
        ) : null}
        {reviewCount > 3 ? (
          <div class="paginationBox">
            <Pagination paginationObj={paginationObj} />
          </div>
        ) : null}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.commentBox {
  margin-top: 32px;
  padding: 24px;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .titleBox {
      display: flex;
      align-items: center;
      .title {
        margin-right: 30px;
        span {
          font-size: 22px;
          font-weight: bold;
        }
        span.blue {
          color: #006eeb;
        }
        span.yellow {
          color: rgba(255, 131, 62, 1);
        }
        span.num {
          font-size: 20px;
          font-weight: 300;
          color: #afb9c6;
          margin-left: 10px;
        }
      }
    }
    p {
      font-size: 12px;
      font-weight: 300;
      color: #d5d9de;
    }
  }
  .inputBox {
    display: flex;
    margin-top: 15px;
    .main {
      background: #f3f6fa;
      width: 680px;
      height: 57px;
      border-radius: 8px;
      textarea {
        padding: 12px 14px;
        resize: none;
        width: 100%;
        box-sizing: border-box;
        height: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #a4aab3;
      }
      .noLogin {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
        .btn {
          display: block;
          width: 70px;
          height: 24px;
          background: #006eeb;
          border-radius: 8px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
          text-align: center;
        }
        p {
          margin-left: 16px;
          color: #a4aab3;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    span {
      width: 70px;
      height: 57px;
      background: #006eeb;
      border-radius: 0px 8px 8px 0px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 57px;
      cursor: pointer;
    }
  }
  .list {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .paginationBox {
    display: flex;
    justify-content: center;
  }
}
</style>