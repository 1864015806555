<script>
import { randomNumber,GetQueryString,urlNocanshu } from "../../../utils/index.js";
export default {
  name: "Video",
  props: {
    seriesId:String,
    list: Array,
  },
  data() {
    return {
      video: null,
      isPlay: false,
      timer: null,
    };
  },
  async mounted() {
    console.log(this.seriesId,"BBB")
    console.log(this.list,"CCC")
    this.$refs.videoRef.addEventListener("play", async (e) => {
      this.isPlay = true;
    });

    this.$refs.videoRef.addEventListener("pause", (e) => {
      this.isPlay = false;
    });

    this.$refs.videoRef.addEventListener("seeking", (e) => {
      this.isPlay = true;
      this.pushVideoTime(id);
    });

    this.$refs.videoRef.addEventListener("ended", (e) => {
      clearInterval(this.timer);
    });

    this.$refs.videoRef.addEventListener("loadedmetadata", async (e) => {
      this.pushVideoTime(id);
      this.timer = setInterval(async () => {
        this.pushVideoTime(id);
      }, 1000 * 60 * 5);
    });
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    async videoPlay() {
      const { detailData, videoUrl } = this.$store.state.ClassDetailModel;
      const { detail } = detailData;
      console.log(detail)
      const { name,mode } = detail;
      const id = this.list.length == 0 ? detail.id : this.list[0].id;
      console.log(id)
      if (!window.$cookies.get("token")) {
        this.$store.commit("LoginModel/updateState", {
          isShowLogin: true,
          loginState: "login",
        });
        return;
      }

      if (!videoUrl) {
        try {
          await this.$store.dispatch("ClassDetailModel/getVideoUrl", { 
            id,
            mode,
            seriesId:this.seriesId,
            });
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      }
      this.$refs.videoRef.play();
      this.isPlay = true;
    },
    videoPause() {
      this.$refs.videoRef.pause();
      this.isPlay = false;
    },
    async pushVideoTime(id) {
      const { currentId } = this.$store.state.ClassDetailModel;
      if (currentId != '' && currentId != id) {
        id = currentId;
      }
      console.log(id);
      try {
        await this.$store.dispatch("ClassDetailModel/addUserLearnRecord", {
          courseId: id,
          seconds: this.$refs.videoRef.currentTime,
        });
      } catch (msg) {
        return;
      }
      //console.log(id);
    },
    async buyCourse(id) {
      if (!window.$cookies.get("token")) {
        this.$store.commit("LoginModel/updateState", {
          isShowLogin: true,
          loginState: "login",
        });
        return;
      }
      const { userInfo } = this.$store.state.MyModel;
      const { grade } = userInfo;
      let userPermission = String(grade)
      const { detailData } = this.$store.state.ClassDetailModel;
      const { permission } = detailData.detail;
			let permissionArr = permission.split(',');
				if(permissionArr.length != 0){
					let index = permissionArr.indexOf(userPermission);
					console.log(index)
					if(index == -1){
						setTimeout(() => {
              this.$message({ showClose: true, message: 'Oops，没有办法购买呢，快去提升等级吧'});
						}, 300)
						return;
					}
				}
      this.$router.push({ path: `/order/info?id=${id}&type=${"course"}` });
    },

    renderBtn() {
      const { detailData, videoUrl } = this.$store.state.ClassDetailModel;
      const { userInfo } = this.$store.state.MyModel;
      const { detail } = detailData;
      const { coverPhoto, discountType, buyed } = detail;
      const grade = userInfo ? userInfo.grade : 0;

      if (window.$cookies.get("token")) {
        if (!Number(detail[`price${grade + 1}`])) {
          // 免费
          return <i class="icon-play" onclick={this.videoPlay} />;
        } else {
          if (buyed) return <i class="icon-play" onclick={this.videoPlay} />;
          if (!buyed)
            return (
              <span class="btn red" onclick={() => this.buyCourse(detail.id)}>
                立即购买
              </span>
            );
        }
      }

      if (!window.$cookies.get("token")) {
        if (!Number(detail[`price`])) {
          // 免费
          return <i class="icon-play" onclick={this.videoPlay} />;
        } else {
          if (buyed) return <i class="icon-play" onclick={this.videoPlay} />;
          if (!buyed)
            return (
              <span class="btn red" onclick={() => this.buyCourse(detail.id)}>
                立即购买
              </span>
            );
        }
      }
    },
  },
  render() {
    const { detailData, videoUrl } = this.$store.state.ClassDetailModel;
    const { detail } = detailData;
    const { coverPhoto, discountType, buyed } = detail;
    return (
      <div class="videoBox">
        <div class="shadow" v-show={!this.isPlay}>
          {this.renderBtn()}
        </div>
        <video
          ref="videoRef"
          src={videoUrl}
          controlslist="nodownload"
          poster={coverPhoto}
          controls={true}
        />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.videoBox {
  width: 750px;
  height: 420px;
  position: relative;
  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.icon-play {
  display: block;
  width: 83px;
  height: 83px;
  background-image: url("../../../assets/images/video-play.png");
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}
.btn {
  display: block;
  width: 204px;
  height: 50px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}
.btn.red {
  background: #f94164;
  color: #ffffff;
}
</style>