<script>
import { debounce } from "../../../utils/index.js";
export default {
  name: "searchArea",
  methods: {
    onInput(e) {
      const { searchData } = this.$store.state.SearchResult;
      this.$store.commit("SearchResult/updateState", {
        searchData: { ...searchData, keyword: e.target.value },
      });
      this.loadList();
    },
    async loadList() {
      const { searchData, limit } = this.$store.state.SearchResult;
      try {
        await this.$store.dispatch("SearchResult/siteSearch", {
          ...searchData,
          page: 1,
          limit,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
  },
  render() {
    const {
      searchData,
      countryList,
      typeList,
      gradeTypeList,
    } = this.$store.state.SearchResult;
    return (
      <div class="search-box">
        <div class="searchItem" style={{ alignItems: "center" }}>
          <span class="label black">您已输入</span>
          <div class="inputBox">
            <input
              onInput={debounce((e) => this.onInput(e), 500)}
              value={searchData.keyword}
            />
          </div>
        </div>
        <div class="searchItem">
          <span class="label blue">国家/地区</span>
          <div class="tagGroup">
            {countryList.map((item) => (
              <span
                class={`tag ${
                  item.name === searchData.CountryName ? "active" : ""
                }`}
                onclick={() => {
                  this.$store.commit("SearchResult/updateState", {
                    searchData: {
                      ...searchData,
                      CountryName: item.name,
                    },
                  });
                  this.loadList();
                }}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div class="searchItem">
          <span class="label blue">类型</span>
          <div class="tagGroup">
            {typeList.map((item) => (
              <span
                class={`tag ${item.value === searchData.AssemblyConditions[0].ModuleType ? "active" : ""}`}
                onclick={() => {
                  this.$store.commit("SearchResult/updateState", {
                    searchData: {
                      ...searchData,
                      AssemblyConditions:[{
												BusinessUnit: "SPZ",
												ModuleType: item.value,
												ProjectType: "视频站PC",
											}],
                    },
                  });
                  this.loadList();
                }}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div class="searchItem">
          <span class="label blue">留学阶段</span>
          <div class="tagGroup">
            {gradeTypeList.map((item) => (
              <span
                class={`tag ${
                  item.name === searchData.Stage ? "active" : ""
                }`}
                onclick={() => {
                  this.$store.commit("SearchResult/updateState", {
                    searchData: {
                      ...searchData,
                      Stage: item.name,
                    },
                  });
                  this.loadList();
                }}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.search-box {
  padding: 24px;
  background: #fff;
  border-radius: 16px;
  .searchItem {
    display: flex;
    align-items: baseline;
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
    .inputBox {
      width: 260px;
      height: 32px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      background: #f3f6fa;
      input {
        margin-left: 15px;
        color: #333333;
      }
    }
    .tagGroup {
      display: flex;
      flex-wrap: wrap;
      .tag {
        color: #333333;
        cursor: pointer;
        font-size: 14px;
        padding: 0 20px;
        height: 32px;
        line-height: 32px;
        margin-bottom: 5px;
      }
      .tag.active {
        background: #f0f7ff;
        border-radius: 8px;
        color: #006eeb;
        font-weight: bold;
      }
    }
    .label {
      display: block;
      min-width: 80px;
      text-align: left;
      font-size: 18px;
      margin-right: 25px;
      white-space: nowrap;
    }
    .label.black {
      color: #1a1a1a;
    }
    .label.blue {
      color: #006eeb;
    }
  }
}
</style>