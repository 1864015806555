<script>
import Pagination from "@components/Pagination.vue";
import ModelBoxMy from "@components/ModelBoxMy.vue";
import IntegralRule from "@components/IntegralRule.vue";
import ShopItem from "./shopItem";
export default {
  name: "shop",
  data() {
    return {
      currentPage: 1,
      pageSize: 6,
      tabbarData: [
        { min: null, max: null, name: "全部商品", id: 0 },
        { min: 0, max: 1000, name: "0-1000", id: 1 },
        { min: 1000, max: 2000, name: "1000-2000", id: 2 },
        { min: 2000, max: null, name: ">2000", id: 3 },
      ],
      tabActive: 0,
      isShowRule: false,
      min: null,
      max: null,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("MyModel/getGoodsList", {
        min: this.min,
        max: this.max,
        page: this.currentPage,
        pageSize: this.pageSize,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
      await this.$store.dispatch("MyModel/getPageInfoList");
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    this.getUserInfo();
  },
  destroyed() {
      const { shopListFilter } = this.$store.state.MyModel;
      shopListFilter.shopDetailBack = false;
  },
  methods: {
    async onTabClick(record) {
      this.currentPage = 1;
      this.tabActive = record.id;
      this.min = record.min;
      this.max = record.max;
      
      // record tabActive, min, max for detail back to list
      this.$store.state.MyModel.shopListFilter = {
        ...this.$store.state.MyModel.shopListFilter,
        currentPage: this.currentPage,
        tabActive: record.id,
        min: record.min,
        max: record.max
      };

      try {
        await this.$store.dispatch("MyModel/getGoodsList", {
          min: record.min,
          max: record.max,
          page: this.currentPage,
          pageSize: this.pageSize,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
    jumpMyIntegral() {
      this.$router.push({ path: "/my/integral", query: { tab: 1 } });
    },
    // 获取用户信息
    async getUserInfo() {
      try {
        await this.$store.dispatch("MyModel/getUserInfo");
      } catch (error) {
          if (error.code == 1016) {
            this.$message({ showClose: true, message: error.msg, type: "error" });
            window.$cookies.set("token",'')
          }
          return;
        }
    },
    resetListFilter() {
      const { shopListFilter } = this.$store.state.MyModel;

      if (shopListFilter.shopDetailBack) {
        const { currentPage, tabActive, min, max } = shopListFilter;
        this.currentPage = currentPage;
        this.tabActive = tabActive;
        this.min = min;
        this.max = max;
      }
    }
  },
  render() {
    this.resetListFilter();
    const {
      integralShopList,
      integralShopCount,
      userInfo,
      shopBannerList,
    } = this.$store.state.MyModel;
    const paginationObj = {
      totalPage: Math.ceil(integralShopCount / this.pageSize),
      maxButton: 5,
      currentPage: this.currentPage,
      pageChange: async (record) => {
        
        // record current page number for detail back to list
        this.currentPage = record;
        this.$store.state.MyModel.shopListFilter = {
          ...this.$store.state.MyModel.shopListFilter,
          currentPage: record
        };

        try {
          await this.$store.dispatch("MyModel/getGoodsList", {
            min: this.min,
            max: this.max,
            page: record,
            pageSize: this.pageSize,
          });
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      },
    };
    const modelData = {
      blue: "积分",
      yellow: "商城",
      content: () => (
        <div class="main">
          <div class="top-box">
            <el-carousel trigger="click" height="150px">
              {shopBannerList.map((item) => (
                <el-carousel-item>
                  <img
                    src={item.imageUrl}
                    onclick={() => this.$router.push({ path: item.jumpUrl })}
                  />
                </el-carousel-item>
              ))}
            </el-carousel>
            <div class="my">
              <div class="info">
                <div class="title">
                  我的积分
                  <i
                    class="icon info"
                    onclick={() => (this.isShowRule = true)}
                  />
                </div>
                <div class="num">
                  {userInfo && userInfo.score}
                  <span
                    onclick={() =>
                      this.$router.push({
                        path: "/my/integral",
                        query: { tab: 1 },
                      })
                    }
                  >
                    获取积分>>
                  </span>
                </div>
                <div class="btn-group">
                  <span
                    onclick={() =>
                      this.$store.commit("MyModel/updateState", {
                        integralState: "order",
                      })
                    }
                  >
                    积分订单
                  </span>
                  <span
                    onclick={() =>
                      this.$router.push({
                        path: "/my/integral",
                        query: { tab: 2 },
                      })
                    }
                  >
                    积分详情
                  </span>
                </div>
              </div>
              <i class="icon money" />
            </div>
          </div>
          <div class="bottom-box">
            <div class="tabbar">
              {this.tabbarData.map((item) => (
                <span
                  onclick={() => this.onTabClick(item)}
                  class={`tab-item  ${
                    item.id === this.tabActive ? "active" : ""
                  }`}
                >
                  {item.name}
                </span>
              ))}
            </div>
            <div class="list">
              {integralShopList.map((item) => (
                <ShopItem data={item} />
              ))}
            </div>
          </div>
          {integralShopCount > this.pageSize ? (
            <div class="paginationBox">
              <Pagination paginationObj={paginationObj} />
            </div>
          ) : null}
        </div>
      ),
    };
    return (
      <div>
        <ModelBoxMy data={modelData} />
        <IntegralRule
          isShow={this.isShowRule}
          closeFunc={() => (this.isShowRule = false)}
        />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  .top-box {
    display: flex;
    // align-items: center;
    .el-carousel {
      width: 263px;
      height: 168px;
      margin-right: 19px;
      border-radius: 8px;
      .el-carousel__item {
        height: 168px;
      }
    }
    img {
      width: 263px;
      height: 168px;
      object-fit: cover;
      cursor: pointer;
    }
    .my {
      display: flex;
      width: 553px;
      height: 167px;
      background-image: url("../../../../assets/images/integralBG.png");
      background-size: 100%;
      background-repeat: no-repeat;
      padding: 24px 49px;
      box-sizing: border-box;
      justify-content: space-between;
      .info {
        display: flex;
        flex-direction: column;
        .title {
          font-size: 24px;
          font-weight: 600;
          color: #fefefe;
          display: flex;
          align-items: center;
          .icon.info {
            background-image: url("../../../../assets/images/info.png");
            width: 22px;
            height: 22px;
            margin-left: 11px;
            cursor: pointer;
          }
        }
        .num {
          font-size: 40px;
          font-weight: 500;
          color: #fefefe;
          display: flex;
          align-items: flex-end;
          span {
            cursor: pointer;
            font-size: 16px;
            margin-left: 11px;
            padding-bottom: 2px;
            display: block;
            padding-bottom: 10px;
          }
        }
        .btn-group {
          margin-top: 20px;
          display: flex;
          align-items: center;
          span {
            display: block;
            padding: 0 7px;
            border: 1px solid #ffffff;
            border-radius: 8px;
            color: #ffffff;
            font-size: 14px;
            margin-right: 9px;
            cursor: pointer;
          }
        }
      }
      .icon.money {
        width: 128px;
        height: 99px;
        background-image: url("../../../../assets/images/money2.png");
      }
    }
  }
  .bottom-box {
    margin-top: 50px;
    .tabbar {
      margin-bottom: 20px;
      .tab-item {
        font-size: 18px;
        font-weight: 400;
        color: #9ca6b2;
        margin-right: 24px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          font-size: 18px;
          font-weight: bold;
          color: #006eeb;
          position: relative;
          &::before {
            content: "";
            display: block;
            width: 30px;
            height: 3px;
            background: #006eeb;
            border-radius: 2px;
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .paginationBox {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
.icon {
  display: block;
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>