<script>
export default {
  name: "banner",
  data() {
    return {
      isCollect: false,
    };
  },
  methods: {
    async onCollectClick(record) {
      const { detailData } = this.$store.state.UniversityDetail;
      let msg;
      try {
        msg = await this.$store.dispatch(
          "UniversityDetail/addOrDelUserCollect",
          {
            productId: record.id,
            type: "school",
          }
        );
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.$message({ showClose: true, message: msg, type: "success" });
      this.$store.commit("UniversityDetail/updateState", {
        detailData: {
          ...detailData,
          isCollect: !detailData.isCollect,
        },
      });
    },
  },
  render() {
    const { detailData } = this.$store.state.UniversityDetail;
    return (
      <div class="banner">
        {/*<el-carousel height="367px">
          <el-carousel-item>*/}
        <img src={detailData.coverPhoto} />
        <div class="shadow">
          <div class="left">
            {detailData.countryName ? (
              <div class="address-box">
                <i class="icon address" />
                {detailData.countryName}
              </div>
            ) : null}
            <div class="name-box">
              <p class="zh-cn">{detailData.name}</p>
              <p class="en">{detailData.enname}</p>
            </div>
            <p class="des">{detailData.abstract}</p>
          </div>
          <span class="btn" onclick={() => this.onCollectClick(detailData)}>
            <i class={`icon collect ${detailData.isCollect ? "active" : ""}`} /> 收藏
          </span>
        </div>
        {/* </el-carousel-item>
        </el-carousel>*/}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  height: 367px;
  margin-bottom: 24px;
  // .el-carousel {
  //   height: 100%;
  //   .el-carousel__container {
  //     height: 100%;
  //     .el-carousel__item {
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .shadow {
    height: 124px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    position: absolute;
    align-items: flex-end;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 30px 21px 30px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    .left {
      display: flex;
      flex-direction: column;
      .address-box {
        display: flex;
        font-size: 18px;
        color: #ffffff;
        height: 30px;
        padding: 0 10px;
        line-height: 30px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 16px;
        align-items: center;
        width: fit-content;
        .icon.address {
          background-image: url("../../../assets/images/address@2x.png");
          width: 15px;
          height: 18px;
          margin-right: 6px;
        }
      }
      .name-box {
        display: flex;
        align-items: baseline;
        margin-top: 14px;
        .zh-cn {
          font-size: 26px;
          color: #ffffff;
          font-weight: bold;
          margin-right: 23px;
          position: relative;
          &::before {
            display: block;
            content: "";
            width: 8px;
            height: 8px;
            background: #ff833e;
            border-radius: 50%;
            position: absolute;
            left: -15px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .en {
          font-size: 18px;
          color: #ffffff;
        }
      }
      .des {
        font-size: 14px;
        color: #ffffff;
        margin-top: 5px;
      }
    }
    .btn {
      display: block;
      background: #ffffff;
      border-radius: 8px;
      height: 34px;
      min-width: 84px;
      width: 84px;
      font-size: 16px;
      color: #525252;
      display: flex;
      align-items: center;
      padding: 0 11px;
      box-sizing: border-box;
      cursor: pointer;
      .icon.collect {
        width: 22px;
        height: 22px;
        background-image: url("../../../assets/images/shoucang3.png");
        margin-right: 5px;
      }
      .icon.collect.active {
        background-image: url("../../../assets/images/shoucang3-active.png");
      }
    }
  }
  //     }
  //   }
  // }
}
.icon {
  display: block;
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>