
<script>
export default {
  name: "Item",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="item-box">
        <div class="imgBox">
          <img
            src={this.data.coverPhoto}
            onclick={() => {
              this.$router.push({ path: `/course/detail?id=${this.data.id}` });
            }}
          />
          <div class="shadow">
            <i class="icon-eye" />
            <p>{this.data.viewTimes}人已观看</p>
          </div>
          {/* <i class="icon-pay" />*/}
        </div>
        <div class="content">
          <p>{this.data.name}</p>
          <div class="tags">
            {this.data.tags &&
              this.data.tags.split(",").map((item) => <span>{item}</span>)}
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.item-box {
  width: 245px;
  margin-right: 24px;
  margin-bottom: 24px;
  .imgBox {
    width: 245px;
    height: 155px;
    border-radius: 16px;
    position: relative;
    .icon-pay {
      display: block;
      width: 53px;
      height: 53px;
      position: absolute;
      top: 0;
      right: 0;
      background-image: url("../../../assets/images/pay.png");
      background-size: 100%;
      background-repeat: no-repeat;
    }
    .shadow {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 42px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      padding: 17px 0 0px 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
        font-weight: 300;
        color: #ffffff;
      }
      .icon-eye {
        width: 16px;
        height: 13px;
        display: block;
        background-image: url("../../../assets/images/icon-eye.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-right: 8px;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }
  .content {
    p {
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
      margin-top: 9px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      height: 43px;
    }
    .tags {
      display: flex;
      margin-top: 10px;
      span {
        display: block;
        height: 23px;
        line-height: 23px;
        background: #dbebfc;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 300;
        color: #006eeb;
        padding: 0 13px;
        margin-right: 8px;
      }
    }
  }
  &:nth-child(4n) {
    margin-right: 0;
  }
}
</style>