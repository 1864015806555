<script>
import Loading from "@components/Loading";
import BreadCrumbs from "@components/BreadCrumbs.vue";
import PayMode from "./PayMode";
import Success from "./Success";
import Fail from "./Fail";
import { randomNumber,GetQueryString,urldelCode,urlNocanshu } from "../../utils/index.js";
export default {
  name: "orderInfo",
  data() {
    return {
      nowCouponId: null,
      buyType: 2,
      payShow: false,
      isSuccessShow: false,
      isFailShow: false,
      timer: null,
    };
  },
  async mounted() {
    const { id, type } = this.$route.query;
    if (window.$cookies.get("token")) {
        urldelCode()
    }
    if (!window.$cookies.get("token")) {
      this.$store.commit("LoginModel/updateState", {
        isShowLogin: true,
        loginState: "login",
      });
      return;
    } else {
      try {
        await this.$store.dispatch("MyModel/getUserInfo");
      } catch (error) {
          if (error.code == 1016) {
            this.$message({ showClose: true, message: error.msg, type: "error" });
            window.$cookies.set("token",'')
          }
          return;
        }
    }
    try {
      await this.$store.dispatch("OrderInfo/buyDetail", {
        productId: id,
        type,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    const params = { productId: id };
    if (type === "course") params.scope = 1;
    if (type === "live") params.scope = 2;
    try {
      await this.$store.dispatch("OrderInfo/getEffectiveCoupon", params);
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    try {
        await this.$store.dispatch("OrderInfo/recordAllPage", {
          app:3, 
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pvId:randomNumber(),
          medium: window.$cookies.get("datachannelmed") || '',
          extendurl: window.$cookies.get("dataurl") || '',
          pageurl:urlNocanshu(),
          fullpageurl:window.location.href,
          accountId:window.$cookies.get("accountId") || ''
        });
      } catch (msg) {
        // this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
  },
  methods: {
    couponClick(record) {
      if (this.nowCouponId === record.id) {
        this.nowCouponId = null;
      } else {
        this.nowCouponId = record.id;
      }
    },
    payClose() {
      this.payShow = false;
      this.isFailShow = true;
      clearInterval(this.timer);
    },

    async payClick() {
      const { id, type } = this.$route.query;
      if (!window.$cookies.get("token")) {
        this.$store.commit("LoginModel/updateState", {
          isShowLogin: true,
          loginState: "login",
        });
        // this.$message({ showClose: true, message: "请先登录", type: "error" });
        return;
      }
      try {
        await this.$store.dispatch("OrderInfo/createOrder", {
          buyType: this.buyType,
          couponId: this.nowCouponId,
          productId: id,
          type,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      if (!this.nowCouponId) {
        this.payShow = true;
        this.checkPay();
        return;
      }
      this.isSuccessShow = true;
    },
    checkPay() {
      const { number } = this.$store.state.OrderInfo;
      this.timer = setInterval(async () => {
        try {
          await this.$store.dispatch("OrderInfo/orderPayCheck", { number });
        } catch (msg) {
          // this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
        this.payShow = false;
        this.isSuccessShow = true;
        clearInterval(this.timer);
      }, 3000);
    },
  },
  render() {
    const {
      detailData,
      isLoadingShow,
      effectiveCouponList,
    } = this.$store.state.OrderInfo;
    const { userInfo } = this.$store.state.MyModel;
    const { grade } = userInfo;

    return (
      <div class="container">
        <BreadCrumbs />
        <div class="main">
          <h1>确认订单信息</h1>
          <table class="pure-table">
            <thead>
              <tr>
                <th style={{ width: "395px" }}>课程信息</th>
                <th>注意事项</th>
                <th>价格</th>
              </tr>
            </thead>
            <tbody>
              {detailData ? (
                <tr>
                  <td>
                    <div class="infobox">
                      <img src={detailData.coverPhoto} />
                      <div class="content">
                        <h3>{detailData.name}</h3>
                        <span>¥ {detailData[`price${grade + 1}`]}</span>
                      </div>
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    本产品为虚拟物品，售出后不支持退款
                  </td>
                  <td style={{ textAlign: "center" }}>
                    ¥ {detailData[`price${grade + 1}`]}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
          <div class="paybox">
            <h3>请选择支付方式</h3>
            <div class="list">
              <div class="item active">
                <i class="icon check" />
                <div class="box">
                  <i class="icon vx" />
                  微信支付
                </div>
              </div>
            </div>
          </div>
          <div class="discounts">
            <h3>
              请选择优惠券{" "}
              <span class="count">（{effectiveCouponList.length}张可用）</span>
            </h3>
            {effectiveCouponList.length ? (
              <div class="list">
                {effectiveCouponList.map((item) => (
                  <div
                    class={`item ${
                      this.nowCouponId === item.id ? "active" : ""
                    }`}
                    onclick={() => this.couponClick(item)}
                  >
                    <div class="status green">
                      <div class="exchange">
                        <p class="text1">课 程</p>
                        <p>兑换券</p>
                      </div>
                    </div>
                    <div class="content">
                      <div class="top">
                        <p class="title">{item.title}</p>
                        <p class="des">{item.subhead}</p>
                      </div>
                      <div class="bottom">
                        <p class="time">
                          有效期：{item.effectiveDate} - {item.failureDate}
                        </p>
                        {/* <span class="btn green">立即使用</span>*/}
                      </div>
                    </div>
                  </div>
                ))}

                {/* <div class="item">
                  <div class="status purple">
                    <div class="discount">
                      <p>7.8</p>
                      <span>折</span>
                    </div>
                  </div>
                  <div class="content">
                    <div class="top">
                      <p class="title">全部课程代金券</p>
                      <p class="des">用于启德课堂付费课程 满1999元使用 </p>
                    </div>
                    <div class="bottom">
                      <p class="time">有效期：2020.03.60 - 2020.10.28</p>
                      <span class="btn purple">立即使用</span>
                    </div>
                  </div>
                </div>*/}
              </div>
            ) : null}
            {!effectiveCouponList.length ? (
              <div class="null_container">
                <div class="null-box">
                  <i class="icon null" />
                  <p>无可用优惠券</p>
                </div>
              </div>
            ) : null}
          </div>
          {detailData ? (
            <div class="sum">
              <h3>订单总价</h3>
              <p>
                ¥ {this.nowCouponId ? "0" : detailData[`price${grade + 1}`]}
              </p>
            </div>
          ) : null}
          <span class="btn pay" onclick={this.payClick}>
            确认支付
          </span>
        </div>
        <Loading isShow={isLoadingShow} />
        {this.payShow ? (
          <PayMode isShow={this.payShow} closeFuc={this.payClose} />
        ) : null}
        {this.isSuccessShow ? (
          <Success
            isShow={this.isSuccessShow}
            closeFuc={() => (this.isSuccessShow = false)}
          />
        ) : null}
        {this.isFailShow ? (
          <Fail
            isShow={this.isFailShow}
            closeFuc={() => (this.isFailShow = false)}
          />
        ) : null}
      </div>
    );
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 1100px;
  margin: 0 auto 60px;
  .main {
    background: #ffffff;
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 22px;
      font-weight: 400;
      color: #1a1a1a;
      padding-bottom: 10px;
      border-bottom: 1px solid #dce0e6;
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
      empty-cells: show;
      margin: 30px 0;
      thead {
        background: #f3f6fa;
        font-size: 16px;
        font-weight: 400;
        color: #1a1a1a;
        text-align: center;
        vertical-align: bottom;
        border-radius: 8px;
        line-height: 46px;
        height: 46px;
      }

      td {
        // text-align: center;
        padding-top: 18px;
        .infobox {
          display: flex;
          //   width: 395px;
          img {
            width: 150px;
            height: 95px;
            border-radius: 8px;
            object-fit: cover;
          }
          .content {
            display: flex;
            flex-direction: column;
            margin-left: 17px;
            justify-content: space-between;
            h3 {
              font-size: 16px;
              font-weight: 400;
              color: #1a1a1a;
            }
            span {
              font-size: 16px;
              font-weight: 400;
              color: #f94164;
            }
          }
        }
      }
    }
    .paybox {
      border-top: 1px solid #dce0e6;
      h3 {
        font-size: 18px;
        font-weight: 400;
        color: #1a1a1a;
        margin-top: 25px;
        font-weight: 400;
      }
      .list {
        margin-top: 10px;
        display: flex;
        .item {
          cursor: pointer;
          width: 234px;
          height: 68px;
          border: 1px solid #dce0e6;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 46px 0 25px;
          box-sizing: border-box;
          .icon.check {
            width: 18px;
            height: 18px;
            background-image: url("../../assets/images/check2.png");
          }
          .box {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            color: #1a1a1a;
            .icon.vx {
              width: 30px;
              height: 30px;
              background-image: url("../../assets/images/vxpay.png");
              margin-right: 12px;
            }
          }
        }
        .item.active {
          .icon.check {
            background-image: url("../../assets/images/check.png");
          }
        }
      }
    }
    .discounts {
      h3 {
        font-size: 18px;
        font-weight: 400;
        color: #1a1a1a;
        margin-top: 25px;
        font-weight: 400;
        .count {
          font-size: 18px;
          font-weight: 400;
          color: #a4aab3;
        }
      }
      .null_container {
        border: 1px solid #dce0e6;
        border-radius: 8px;
        display: flex;
        height: 100px;
        margin-top: 15px;
        align-items: center;
        justify-content: center;
        .null-box {
          display: flex;
          align-items: center;
          p {
            font-size: 16px;
            font-weight: 300;
            color: #a4aab3;
            margin-top: 8px;
          }
          .icon.null {
            width: 71px;
            height: 63px;
            background-image: url("../../assets/images/null.png");
            margin-right: 20px;
          }
        }
      }
      .list {
        border: 1px solid #dce0e6;
        border-radius: 8px;
        padding: 30px 80px;
        display: flex;
        margin-top: 15px;
        .item {
          cursor: pointer;
          position: relative;
          display: flex;
          width: 409px;
          margin-right: 26px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          .status {
            width: 110px;
            min-width: 110px;
            height: 98px;
            background-size: 100%;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            .voucher {
              color: #ffffff;
              margin-top: -10px;
              span {
                padding-left: 10px;
                font-size: 12px;
                font-weight: bold;
              }
              p {
                padding-left: 20px;
                font-size: 30px;
                font-weight: bold;
                margin-top: -10px;
              }
            }
            .exchange {
              color: #ffffff;
              font-size: 26px;
              font-weight: bold;
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              justify-content: center;
              p {
                text-align: center;
              }
            }
            .discount {
              color: #ffffff;
              display: flex;
              align-items: baseline;
              width: 100%;
              justify-content: center;
              p {
                font-size: 40px;
                font-weight: bold;
              }
              span {
                font-size: 16px;
                margin-left: 7px;
              }
            }
          }
          .content {
            box-sizing: border-box;
            background: #f3f6fa;
            border: 1px solid #f3f6fa;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 10px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-left: 0;
            .top {
              display: flex;
              flex-direction: column;
              .title {
                font-size: 18px;
                font-weight: 500;
                color: #000000;
              }
              .des {
                font-size: 12px;
                font-weight: 500;
                color: #5c6066;
                margin-top: 5px;
              }
            }
            .bottom {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              .time {
                font-size: 12px;
                font-weight: 500;
                color: #5c6066;
              }
              .btn {
                height: 27px;
                border-radius: 10px;
                min-width: 66px;
                font-size: 14px;
                color: #ffffff;
                display: block;
                line-height: 27px;
                text-align: center;
                margin-left: 15px;
                cursor: pointer;
              }
              .btn.red {
                background: #f15544;
              }
              .btn.green {
                background: #00c48c;
              }
              .btn.purple {
                background: #7b57ff;
              }
            }
          }
          .icon-use {
            display: block;
            position: absolute;
            width: 62px;
            height: 62px;
            background-image: url("../../assets/images/icon-use.png");
            background-size: 100%;
            background-repeat: no-repeat;
            top: 0;
            right: 0;
          }
          .icon-invalid {
            display: block;
            position: absolute;
            width: 62px;
            height: 62px;
            background-image: url("../../assets/images/invalid.png");
            background-size: 100%;
            background-repeat: no-repeat;
            top: 0;
            right: 0;
          }

          .status.red {
            background-image: url("../../assets/images/discounts-red.png");
          }
          .status.purple {
            background-image: url("../../assets/images/discounts-purple.png");
          }
          .status.gray {
            background-image: url("../../assets/images/discounts-gray.png");
          }
          .status.green {
            background-image: url("../../assets/images/discounts-green.png");
          }
        }
        .item.active {
          .content {
            border: 1px solid #006eeb;
            border-left: 0;
          }
          &::after {
            content: "";
            display: block;
            width: 21px;
            height: 21px;
            position: absolute;
            right: 12px;
            top: 15px;
            background-image: url("../../assets/images/check.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
      }
    }
    .sum {
      h3 {
        font-size: 18px;
        font-weight: 400;
        color: #1a1a1a;
        margin-top: 25px;
        font-weight: 400;
      }
      p {
        margin-top: 23px;
        font-size: 30px;
        font-weight: bold;
        color: #f94164;
      }
    }
    .btn.pay {
      margin: 0 auto;
      width: 200px;
      height: 50px;
      background: #f94164;
      border-radius: 8px;
      font-size: 20px;
      color: #ffffff;
      font-weight: bold;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
      margin-top: 20px;
    }
  }
}
</style>