<script>
import Pagination from "@components/Pagination.vue";
import eicdata2empty from "@components/eicdata2empty.vue";
export default {
  name: "Show",
  data() {
    return {
      pageSize: 3,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("MyModel/getMyPrizeList", {
        type: 3,
        page: 1,
        pageSize: this.pageSize,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  methods: {
    copy(id) {
      const spanText = document.getElementById(id).innerText;
      const oInput = document.createElement("input");
      oInput.value = spanText;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$message({
        showClose: true,
        message: "复制成功！",
        type: "success",
      });
    },
  },
  render() {
    const { prizeList, prizeListCount } = this.$store.state.MyModel;
    const paginationObj = {
      totalPage: Math.ceil(prizeListCount / this.pageSize),
      maxButton: 5,
      pageChange: async (record) => {
        try {
          await this.$store.dispatch("MyModel/getMyPrizeList", {
            type: 3,
            page: record,
            pageSize: this.pageSize,
          });
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      },
    };
    return (
      <div class="box">
        <div class="list">
          {prizeList.map((item) => (
            <div class="item">
              {/* <img src={"123"} />*/}
              <div class="info">
                <div class="top">
                  <div class="serial">
                    抽奖序列号：<span id="copy">{item.number}</span>
                    <span class="tag" onclick={() => this.copy("copy")}>
                      复制
                    </span>
                  </div>
                  <div class="text">
                    <p class="des">{item.name}</p>
                    <span class="time">抽奖时间：{item.createTime}</span>
                  </div>
                  <p class="prize">
                    奖品：{item.prize}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        {prizeListCount > this.pageSize ? (
          <div class="paginationBox">
            <Pagination paginationObj={paginationObj} />
          </div>
        ) : null}
        {prizeListCount == 0 ? (
            <div>
              <eicdata2empty/>
            </div>
          ) : null}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.item {
  display: flex;
  border-bottom: 1px solid #e6ebf3;
  padding: 21px 0;
  &:first-child{
    padding-top: 0;
  }
  &:last-child {
    border-bottom: 0;
  }
  // img {
  //   display: none;
  //   width: 225px;
  //   height: 126px;
  //   border-radius: 16px;
  //   object-fit: cover;
  // }
  .info {
    width: 100%;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top {
      display: flex;
      flex-direction: column;
      padding-top: 8px;
      .serial {
        font-weight: 400;
        color: #5c6066;
        font-size: 14px;
        .tag {
          cursor: pointer;
          background: #fee3e8;
          //   width: 24px;
          height: 16px;
          padding: 0 9px;
          line-height: 16px;
          color: #f94164;
          font-weight: 500;
          font-size: 12px;
          border-radius: 5px;
          margin-left: 13px;
        }
      }
      .text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .des {
          font-size: 18px;
          color: #000000;
          line-height: 25px;
          font-weight: 400;
          margin-top: 5px;
        }
        .time {
          background: #e5f0fd;
          width: fit-content;
          line-height: 22px;
          height: 22px;
          padding: 0 10px;
          border-radius: 10px;
          color: #006eeb;
          font-size: 14px;
          text-align: center;
          font-weight: 300;
        }
      }
      .prize {
        font-size: 18px;
        font-weight: 500;
        color: #ff833e;
        margin-top: 20px;
      }
    }
  }
}
.paginationBox {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>