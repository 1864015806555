<script>
import SearchArea from "./SearchArea";
import BreadCrumbs from "@components/BreadCrumbs.vue";
import Pagination from "@components/Pagination.vue";
import Page from "./Page";
import Loading from "@components/Loading";
import Item from "./Item";
import { randomNumber,GetQueryString,urldelCode,urlNocanshu } from "../../utils/index.js";
export default {
  name: "courseList",
  async mounted() {
    window.scrollTo(0, 0);
    if (window.$cookies.get("token")) {
        urldelCode()
    }
    const { countryId, gradeId, categoryId } = this.$route.query;
    const { searchData, pageSize } = this.$store.state.CourseList;
    this.$store.commit("CourseList/updateState", {
      searchData: {
        keyword: "", // 搜索关键字
        professionalId: -1, // 专业ID
        speakerType: -1, //主讲人
        categoryId: categoryId || -1, // 分类
        countryId: Number(countryId) || -1,
        gradeId: gradeId || -1,
      },
    });

    try {
      await this.$store.dispatch("CourseList/getCountryList");
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
      await this.$store.dispatch("CourseList/getEnumList");
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
      await this.$store.dispatch("CourseList/getCourseList", {
        page: 1,
        pageSize,
        ...searchData,
        countryId: Number(countryId) || -1,
        categoryId: categoryId || -1, // 分类
        gradeId: gradeId || -1,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    try {
        await this.$store.dispatch("CourseList/recordAllPage", {
          app:3, 
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pvId:randomNumber(),
          medium: window.$cookies.get("datachannelmed") || '',
          extendurl: window.$cookies.get("dataurl") || '',
          pageurl:urlNocanshu(),
          fullpageurl:window.location.href,
          accountId:window.$cookies.get("accountId") || ''
        });
    } catch (msg) {
      // this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  render() {
    const {
      isLoadingShow,
      listData,
      pageSize,
      count,
      searchData,
      nowPage,
    } = this.$store.state.CourseList;
    const paginationObj = {
      totalPage: Math.ceil(count / pageSize),
      maxButton: 5,
      currentPage: nowPage,
      pageChange: async (record) => {
        try {
          await this.$store.dispatch("CourseList/getCourseList", {
            page: record,
            pageSize,
            ...searchData,
          });
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      },
    };
    return (
      <div class="container">
        <BreadCrumbs />
        <SearchArea />
        <Page />
        {listData.length ? (
          <div class="list">
            {listData.map((item) => (
              <Item data={item} />
            ))}
            <div class="paginationBox">
              <Pagination paginationObj={paginationObj} />
            </div>
          </div>
        ) : null}

        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 1100px;
  margin: 0 auto;
  .list {
    background: #ffffff;
    padding: 24px;
    border-radius: 16px;
    margin-bottom: 64px;
    display: flex;
    flex-wrap: wrap;
    .paginationBox {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
</style>