
<script>
export default {
  name: "BreadCrumbs",
  props: {
    nowName: String,
  },
  render() {
    const { breadCrumbsArr } = this.$store.state.Other;
    return (
      <div class="crumbs">
        <div class="tips">
          <i class="icon-location" />
          您的位置：
        </div>
        {breadCrumbsArr.map((item, index) =>
          breadCrumbsArr.length-1 !==index ? (
            <span
              class="prev"
              onclick={() => this.$router.push({ name: item.name })}
            >
              {item.meta.name} >
            </span>
          ) : (
            <span class="now">
              {this.nowName ? this.nowName : item.meta.name}
            </span>
          )
        )}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.crumbs {
  padding: 13px 0;
  align-items: center;
  display: flex;
  .tips {
    display: flex;
    align-items: center;
    color: #a4aab3;
    .icon-location {
      width: 16px;
      height: 16px;
      display: block;
      background-image: url("../assets/images/location.png");
      background-size: 100%;
      background-repeat: no-repeat;
      margin-right: 7px;
    }
  }
  .prev {
    color: #a4aab3;
    cursor: pointer;
    margin-right: 5px;
  }
  .now {
    color: #5c6066;
    margin-left: 8px;
  }
}
</style>