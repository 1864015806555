
<script>
export default {
  name: "updateEmail",
  props: {
    toggleUpdatEmail: {
      type: Function,
    },
    isShowUpdateEmail: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    onCloseClick() {
      this.$store.commit("MyModel/updateState", { updateEmailShow: false });
    },
    onSave(){
      this.onCloseClick();
    }
  },
  render() {
    return (
      <div class="updatePhoneBox">
        <div class="container">
          <i class="icon-close" onclick={this.onCloseClick} />
          <div class="title">绑定邮箱</div>
          <div class="formBox">
            <div class="emailBox">
              <input placeholder="请输入新的手机号" />
            </div>
            <div class="verificationBox">
              <div class="inputBox">
                <input placeholder="请输入验证码" />
              </div>
              <span class="btn">获取验证码</span>
            </div>
            <div class="btngroup">
              <span class="save" onclick={this.onSave}>立即绑定</span>
            </div>
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.updatePhoneBox {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;

  .container {
    position: relative;
    padding: 0 37px;
    background: #ffffff;
    width: 690px;
    border-radius: 10px;
    box-sizing: border-box;
    .icon-close {
      display: block;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      background-color: #edf0f5;
      background-image: url("../../../../assets/images/icon-close.png");
      background-size: 18px 18px;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      right: 25px;
      top: 25px;
      cursor: pointer;
    }
    .title {
      margin-top: 66px;
      font-weight: 600;
      color: #1a1a1a;
      font-size: 36px;
      text-align: center;
    }
    .formBox {
      margin-top: 47px;
      .emailBox {
        width: 616px;
        height: 62px;
        background: #edf0f5;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 20px;
        border-radius: 10px;
        box-sizing: border-box;
        input {
          border: 0;
          outline: none;
          background: none;
          font-size: 22px;
          color: #a4aab3;
        }
      }
      .verificationBox {
        margin-top: 23px;
        width: 616px;
        display: flex;
        .inputBox {
          width: 405px;
          background: #edf0f5;
          height: 62px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          border-radius: 10px;
          input {
            border: 0;
            outline: none;
            background: none;
            font-size: 22px;
            color: #a4aab3;
          }
        }
        .btn {
          width: 200px;
          height: 62px;
          background: #d9e9fc;
          border-radius: 10px;
          line-height: 62px;
          margin-left: 10px;
          color: #006eeb;
          font-weight: 500;
          font-size: 22px;
          text-align: center;
          cursor: pointer;
        }
      }
      .btngroup {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        padding-bottom: 62px;
        span.save {
          display: block;
          width: 204px;
          height: 64px;
          background: #006eeb;
          border-radius: 10px;
          font-size: 22px;
          color: #ffffff;
          font-weight: 600;
          line-height: 64px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>