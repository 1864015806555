<script>
import ModelBoxMy from "@components/ModelBoxMy.vue";
import Pagination from "@components/Pagination.vue";
import Loading from "@components/Loading";
import eicdata1empty from "@components/eicdata1empty.vue";
export default {
  name: "myAppointment",
  data() {
    return {
      pageSize: 9,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("MyModel/getUserSubLiveList", {
        page: 1,
        pageSize: this.pageSize,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  render() {
    const {
      isLoadingShow,
      subLiveList,
      subLiveListCount,
    } = this.$store.state.MyModel;
    const paginationObj = {
      totalPage: Math.ceil(subLiveListCount / this.pageSize),
      maxButton: 5,
      pageChange: async (record) => {
        try {
          await this.$store.dispatch("MyModel/getUserSubLiveList", {
            page: record,
            pageSize: this.pageSize,
          });
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      },
    };
    const modelData = {
      blue: "我的",
      yellow: "预约",
      content: () => (
        <div>
          <div class="models">
            <div class="modelBox">
              <div class="lists">
                {subLiveList.map((item) => (
                  <div class="item">
                    <div class="imgBox">
                      <img
                        src={item.coverPhoto}
                        onclick={() =>
                          this.$router.push({
                            path: `/live/detail?id=${item.id}`,
                          })
                        }
                      />
                      <span class="tag">直播</span>
                    </div>
                    <p class="content">{item.name}</p>
                    <div class="statusBox">
                      <span
                        class={`status-tag 
                        ${item.status === 1 && "notStarted"}
                        ${item.status === 2 && "living"}
                        ${item.status === 3 && "end"}
                      `}
                      >
                        {item.status === 1 && "未开始"}
                        {item.status === 2 && "进行中"}
                        {item.status === 3 && "已结束"}
                      </span>
                      <span class="time">
                        {this.$moment
                          .unix(item.liveTime)
                          .format("YYYY-MM-DD HH:mm:ss")}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {subLiveListCount > this.pageSize ? (
            <div class="paginationBox">
              <Pagination paginationObj={paginationObj} />
            </div>
          ) : null}
          {subLiveListCount == 0 ? (
            <div>
              <eicdata1empty/>
            </div>
          ) : null}
          <Loading isShow={isLoadingShow} />
        </div>
      ),
    };
    return <ModelBoxMy data={modelData} />;
  },
};
</script>
<style lang="less" scoped>
.modelBox {
  padding-bottom: 16px;
  .lists {
    margin-top: 18px;
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 262px;
      margin-right: 24px;
      margin-bottom: 24px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .imgBox {
        width: 262px;
        height: 166px;
        overflow: hidden;
        border-radius: 10px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
        }
        .tag {
          display: block;
          padding: 0 10px;
          height: 22px;
          line-height: 22px;
          background: #ff833e;
          color: #ffffff;
          font-size: 16px;
          font-weight: 400;
          position: absolute;
          left: 0;
          top: 0;
          border-bottom-right-radius: 10px;
          border-top-left-radius: 10px;
        }
      }
      .content {
        font-size: 16px;
        color: #000000;
        margin-top: 12px;
        font-weight: 400;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        min-height: 43px;
      }
      .statusBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 13px;
        .status-tag {
          height: 24px;
          line-height: 24px;
          border-radius: 8px;
          font-size: 14px;
          font-weight: 300;
          padding: 0 7px;
        }
        .status-tag.living {
          background: #fee3e8;
          color: #f94164;
        }
        .status-tag.notStarted {
          background: #d9e9fc;
          color: #006eeb;
        }
        .status-tag.end {
          background: #f0f0f0;
          color: #999999;
        }
        .time {
          font-weight: 400;
          font-size: 13px;
          color: #9ca6b2;
        }
      }
    }
  }
}
.paginationBox {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>