<script>
import QRCode from "qrcodejs2";
export default {
  name: "payMode",
  props: {
    isShow: Boolean,
    closeFuc: {
      type: Function,
    },
  },
  data() {
    return {
      isPaySuccess: false,
      timer: null,
      isSuccess: false,
      successTimer: null,
      successTime: 3,
      isFailShow: false,
    };
  },
  mounted() {
    const { QRCodeParams } = this.$store.state.OrderInfo;
    new QRCode("qrcode", {
      width: 200, // 二维码宽度
      height: 200, // 二维码高度
      text: QRCodeParams.qcode,
    });
  },
  render() {
    return (
      <div class="shadow">
        <div class="box" style={{ width:  "566px" }}>
          <i class="icon-close" onclick={() => this.closeFuc()} />
          <div>
            <p class="title">微信扫码支付</p>
            <div class="code" style={{ display: "flex" }}>
              <div id="qrcode" ref="qrcode"></div>
              <p>
                <i class="icon-scan" />
                请使用微信扫一扫，扫描二维码支付
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.shadow {
  position: fixed;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  top: 0;
  left: 0;
  .box {
    display: block;
    width: 566px;
    // height: 300px;
    background: #ffffff;
    border-radius: 16px;
    position: relative;
    padding: 25px;
    box-sizing: border-box;
    .icon-close {
      display: block;
      width: 31px;
      height: 31px;
      background-image: url("../../assets/images/pay-close.png");
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
    }
    .title {
      font-size: 30px;
      color: #1a1a1a;
      font-weight: bold;
      text-align: center;
    }
    .code {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 17px;
      img {
        width: 194px;
        height: 194px;
        object-fit: cover;
      }
      p {
        background: #e5f0fd;
        font-size: 20px;
        font-weight: 500;
        color: #006eeb;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        margin-top: 12px;
        .icon-scan {
          display: block;
          width: 24px;
          height: 24px;
          background-image: url("../../assets/images/scan.png");
          background-repeat: no-repeat;
          background-size: 100%;
          margin-right: 4px;
        }
      }
    }
    .payBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon-pay-success {
        display: block;
        width: 60px;
        height: 60px;
        background-image: url("../../assets/images/pay-success.png");
        background-size: 100%;
        background-repeat: no-repeat;
        margin-top: 31px;
      }
      .icon-pay-fail {
        display: block;
        width: 60px;
        height: 60px;
        background-image: url("../../assets/images/pay-fail.png");
        background-size: 100%;
        background-repeat: no-repeat;
        margin-top: 31px;
      }
      span {
        display: block;
        height: 60px;
        border-radius: 8px;
        background: #006eeb;
        color: #ffffff;
        padding: 0 12px;
        line-height: 60px;
        cursor: pointer;
        margin-top: 38px;
      }
    }
  }
}
</style>