<script>
import ModelBox from "@components/ModelBox";
export default {
  name: "series",
  props: {
    list: Array,
    seriesId:String,
  },
  data() {
    return {
      nowId: "", // 当前选中视频ID
    };
  },
  mounted() {
    this.nowId = this.list[0].id;
    this.$store.state.ClassDetailModel.currentId = this.nowId;
  },
  methods: {
    async onItemClick(id) {
      if (this.nowId === id) return;
      if (!window.$cookies.get("token")) {
        this.$store.commit("LoginModel/updateState", {
          isShowLogin: true,
          loginState: "login",
        });
        return;
      }
      try {
        this.$store.state.ClassDetailModel.currentId = id;
        await this.$store.dispatch("ClassDetailModel/getVideoUrl", { 
          id,
          seriesId:this.seriesId,
          mode:2 });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.nowId = id;
    },
  },
  render() {
    return (
      <div class="series-box">
        <div class="header">
          <div class="title">
            <span class="blue">课程</span>
            <span class="yellow">目录</span>
          </div>
        </div>
        <div class="list">
          {this.list.map((item, index) => (
            <div
              class={`item ${item.id === this.nowId ? "active" : ""}`}
              onclick={() => this.onItemClick(item.id)}
            >
              <div class="left">
                <i>{index + 1}</i>
                <p>{item.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.series-box {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 24px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 22px;
      font-weight: bold;
      .blue {
        color: #006eeb;
      }
      .yellow {
        color: #ff833e;
      }
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    margin-top: 26px;
    // overflow-y: scroll;
    // height: 208px;
    .item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 20px;
      background: #f3f6fa;
      padding: 0 24px;
      height: 40px;
      margin-bottom: 16px;
      .left {
        display: flex;
        align-items: center;
        i {
          font-style: normal;
          font-size: 20px;
          font-weight: bold;
          color: #1a1a1a;
          margin-right: 20px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: #1a1a1a;
        }
      }
    }
    .item.active {
      background: #dbebfc;
      .left {
        i {
          color: #006eeb;
        }
        p {
          color: #006eeb;
        }
      }
      &::after {
        display: block;
        content: "正在观看";
        width: 64px;
        height: 20px;
        padding: 0 6px;
        font-size: 13px;
        color: #ffffff;
        background: #006eeb;
        border-radius: 6px;
        text-align: center;
        line-height: 20px;
      }
    }
  }
}
</style>