<script>
// 能力提升
import ModelBox from "@components/ModelBox";
import BaseItem from "../baseItem.vue";
export default {
  name: "capacityUp",
  props: {
    list: Array,
    tabclick: {
      type: Function,
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch("ClassModel/getCapacityUpList", {
        page: 1,
        // pageSize: 8,
        categoryId: "5",
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  data() {
    return {
      nowPage: 1,
      pageSize: 4,
      tabbarData: {
        list: [
          // 此ID根据枚举接口获取,不可更改
          { id: "5", value: "国际竞争力" },
          { id: "6", value: "学术能力" },
          { id: "7", value: "科学考察" },
          { id: "8", value: "职场" },
          { id: "9", value: "公益" },
          { id: "38", value: "科研" },
        ],
        nowTab: "5",
      },
    };
  },
  methods: {
    onTabClick(record) {
      this.tabclick(record);
      this.nowPage = 1;
    },
    onPrevClick() {
      if (this.nowPage <= 1) return;
      this.nowPage = this.nowPage - 1;
    },
    onNextClick() {
      const totalPage = Math.ceil(this.list.length / this.pageSize);
      if (this.nowPage >= totalPage) return;
      this.nowPage = this.nowPage + 1;
    },
  },
  computed: {
    listData() {
      const allList = [];
      this.list.map((item, index) =>
        allList.push({ ...item, index: index + 1 })
      );
      const arr = [];
      for (
        let i = (this.nowPage - 1) * this.pageSize;
        i < allList.length;
        i++
      ) {
        const item = allList[i];
        if (arr.length !== this.pageSize) arr.push({ ...item });
      }
      return arr;
    },
  },
  render() {
    const { capacityUpList } = this.$store.state.ClassModel;
    const modelBoxData = {
      blue: "能力",
      yellow: "提升",
      tabbar: this.tabbarData,
      ontabclick: this.onTabClick,
      more: {
        title: "查看全部",
        onclick: () =>
          this.$router.push({
            path: "/course/list",
            query: { categoryId: this.tabbarData.nowTab },
          }),
      },
      content: () => (
        <div class="main">
          <div class="list">
            {this.listData.map((item) => (
              <BaseItem
                data={{ ...item, jumpUrl: `/course/detail?id=${item.id}` }}
              />
            ))}
          </div>
          <div class="paginationBox">
            <span class="info">翻页查看更多</span>
            <div class="pagination">
              <i
                class={`icon-prve ${this.nowPage <= 1 && "disabled"}`}
                onclick={this.onPrevClick}
              />
              <span class="nowPage">
                {this.nowPage}/{Math.ceil(this.list.length / this.pageSize)}
              </span>
              <i
                class={`icon-next ${
                  this.nowPage >= Math.ceil(this.list.length / this.pageSize) &&
                  "disabled"
                }`}
                onclick={this.onNextClick}
              />
            </div>
          </div>
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
  }
  .paginationBox {
    display: flex;
    align-items: center;
    margin-top: 22px;
    justify-content: flex-end;
    .info {
      font-size: 14px;
      font-weight: 400;
      color: #a4a8af;
      margin-right: 17px;
    }
    .pagination {
      display: flex;
      align-items: center;
      .icon-prve {
        width: 25px;
        height: 25px;
        background-color: RGBA(0, 110, 235, 1);
        border-radius: 50%;
        background-image: url("../../../assets/images/icon-arrows-white.png");
        background-repeat: no-repeat;
        background-size: 9px 13px;
        background-position: center;
        cursor: pointer;
        transform: rotateY(180deg);
      }
      .icon-prve.disabled {
        background-image: url("../../../assets/images/icon-arrows-blue.png");
        background-color: RGBA(240, 247, 255, 1);
        transform: rotateY(0deg);
      }

      .icon-next {
        width: 25px;
        height: 25px;
        background-color: RGBA(0, 110, 235, 1);
        border-radius: 50%;
        background-image: url("../../../assets/images/icon-arrows-white.png");
        background-repeat: no-repeat;
        background-size: 9px 13px;
        background-position: center;
        cursor: pointer;
      }
      .icon-next.disabled {
        background-color: RGBA(240, 247, 255, 1);
        background-image: url("../../../assets/images/icon-arrows-blue.png");
        transform: rotateY(180deg);
      }
      .nowPage {
        font-size: 15px;
        font-weight: 400;
        color: #262626;
        margin: 0 15px;
      }
    }
  }
}
</style>