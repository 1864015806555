<script>
export default {
  name: "ModelBoxMy",
  props: {
    data: Object,
  },
  methods: {
    onTabClick(item) {
      this.data.tabbar.nowTab = item.id;
      this.data.ontabclick && this.data.ontabclick(item);
    },
    onSecondTabClick(item){
      this.data.secondTabBar.nowTab = item.id;
      this.data.onsecondtabclick && this.data.onsecondtabclick(item);
    }
  },
  render() {
    return (
      <div class="Box">
        <div class="header">
          <div class="titleBox">
            <span class="blue">{this.data.blue}</span>
            <span class="yellow">{this.data.yellow}</span>
          </div>
          {this.data.tabbar ? (
            <div class="tabbar">
              {this.data.tabbar.list.map((item) => (
                <span
                  class={`tab-item ${
                    item.id === this.data.tabbar.nowTab ? "active" : ""
                  }`}
                  onclick={() => this.onTabClick(item)}
                >
                  {item.value}
                </span>
              ))}
            </div>
          ) : null}
        </div>
        {this.data.secondTabBar ? (
          <div class="second-tabbar">
            {this.data.secondTabBar.list.map((item) => (
              <span class={`${item.id === this.data.secondTabBar.nowTab ? "active" : ""}`} onclick={()=>this.onSecondTabClick(item)}>
                {item.value}
              </span>
            ))}
          </div>
        ) : null}

        <div class="content">{this.data.content()}</div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.Box {
  padding: 24px;
  background: #ffffff;
  margin-left: 23px;
  border-radius: 16px;
  max-width: 882px;
  width: 882px;
  box-sizing: border-box;

  .header {
    border-bottom: 1px solid #e6ebf3;
    display: flex;
    .titleBox {
      font-size: 22px;
      font-weight: bold;
      padding-bottom: 7px;
      margin-right: 32px;
      span.blue {
        color: #006eeb;
      }
      span.yellow {
        color: #ff833e;
      }
    }
    .tabbar {
      display: flex;
      align-items: center;
      span {
        font-weight: 400;
        color: #9ca6b2;
        font-size: 18px;
        margin-right: 26px;
        cursor: pointer;
      }
      span.active {
        font-weight: bold;
        color: #006eeb;
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 30px;
          height: 3px;
          position: absolute;
          background: #006eeb;
          bottom: -7px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  .second-tabbar {
    margin-top: 20px;
    display: flex;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: 400;
      color: #9ca6b2;
      margin-right: 22px;
      cursor: pointer;
    }
    span.active {
      color: #006eeb;
      position: relative;
      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        width: 30px;
        height: 3px;
        background: #006eeb;
      }
    }
  }
  .content {
    margin-top: 24px;
  }
}
</style>