<script>
export default {
  name: "ModelBox",
  props: {
    data: Object,
  },
  methods: {
    onTabClick(item) {
      this.data.tabbar.nowTab = item.id;
      this.data.ontabclick(item);
    },
  },
  render() {
    return (
      <div class="ModelBox" style={this.data.style}>
        <div class="head">
          <div class="titleBox">
            {this.data.blue && this.data.yellow ? (
              <div class="title">
                <span class="blue">{this.data.blue}</span>
                <span class="yellow">{this.data.yellow}</span>
              </div>
            ) : null}
            {this.data.tabbar ? (
              <div class="tabbar">
                {this.data.tabbar.list.map((item) => (
                  <span
                    class={`tab-item ${
                      item.id === this.data.tabbar.nowTab ? "active" : ""
                    }`}
                    onclick={() => this.onTabClick(item)}
                  >
                    {item.value}
                  </span>
                ))}
              </div>
            ) : null}
          </div>
          {this.data.more ? (
            <div class="more" onclick={this.data.more.onclick}>
              <span>{this.data.more.title}</span>
              <i class="icon-more"></i>
            </div>
          ) : null}
        </div>
        <div class="content">{this.data.content()}</div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.ModelBox {
  padding: 24px;
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 32px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .titleBox {
      display: flex;
      align-items: center;
      .title {
        margin-right: 30px;
        span {
          font-size: 22px;
          font-weight: bold;
        }
        span.blue {
          color: #006eeb;
        }
        span.yellow {
          color: rgba(255, 131, 62, 1);
        }
      }
      .tabbar {
        .tab-item {
          font-size: 18px;
          font-weight: 400;
          color: #9ca6b2;
          margin-right: 24px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          &.active {
            font-size: 18px;
            font-weight: bold;
            color: #006eeb;
            position: relative;
            &::before {
              content: "";
              display: block;
              width: 30px;
              height: 3px;
              background: #006eeb;
              border-radius: 2px;
              position: absolute;
              bottom: -5px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }

    .more {
      width: 92px;
      height: 24px;
      background: #e8f3ff;
      border-radius: 11px;
      display: flex;
      cursor: pointer;
      align-items: center;
      span {
        font-weight: 400;
        font-size: 13px;
        color: #006eeb;
        padding: 6px 0px 6px 12px;
      }
      .icon-more {
        margin-left: 5px;
        display: block;
        width: 20px;
        height: 20px;
        background-image: url("../assets/images/icon-more.png");
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }
  }
}
</style>