<script>
import BreadCrumbsMy from "@components/BreadCrumbsMy.vue";
import Item from "./item";
import Modal from "../modal";
import { urlNocanshu } from "../../../../utils/index.js";
export default {
  name: "detail",
  data() {
    return {
      breadCrumbsList: [
        {
          name: "积分商城",
          onclick: () => this.onInfoClick("shop"),
        },
        {
          name: "积分订单",
          onclick: () => this.onInfoClick("order"),
        },
        {
          name: "订单详情",
        },
      ],
      showModal: false,
    };
  },
  async mounted() {
    const { integralShopDetailId } = this.$store.state.MyModel;
    try {
      await this.$store.dispatch("MyModel/getShopOrderDetail", {
        id: integralShopDetailId,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    const { integralShopDetail } = this.$store.state.MyModel;
    try {
      await this.$store.dispatch("MyModel/getRelevantGoodList", {
        id: integralShopDetail.goodsId,
		page: 1,
		pageSize: 6,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  methods: {
    onInfoClick(state) {
      this.$store.commit("MyModel/updateState", { integralState: state });
    },
    onShowModal() {
      this.showModal = true;
    },
    copy(id) {
      const spanText = document.getElementById(id).innerText;
      const oInput = document.createElement("input");
      oInput.value = spanText;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$message({
        showClose: true,
        message: "复制成功！",
        type: "success",
      });
    },
    // 用户打开Live800记录
    async immediatelyConsult() {
      try {
        await this.$store.dispatch("MyModel/recordBehavior", {
          app:3, 
          action:1,  
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pageurl:urlNocanshu(),
          accountId:window.$cookies.get("accountId") || ''
        });
      } catch (msg) {
        // this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
  },
  render() {
    const { integralShopDetail, relevantGoodList } = this.$store.state.MyModel;
    return (
      <div class="detail-box">
        <BreadCrumbsMy list={this.breadCrumbsList} />
        {integralShopDetail && integralShopDetail.type === 2 ? (
          <div class="status-box">
            <span>已完成</span>/<span>待支付</span>/<span>已失效</span>
          </div>
        ) : null}
        {integralShopDetail && integralShopDetail.type === 2 ? (
          <div class="address">
            <span class="label">配送至</span>
            北京 北京市 朝阳区 建外街道 国贸建外soho 东区B座602 高女士
            13288888888
          </div>
        ) : null}
        {integralShopDetail ? (
          <div class="order-box">
            <img src={integralShopDetail.coverPhoto} />
            <div class="info">
              <span class="orderNum">
                订单编号：{integralShopDetail.number}
              </span>
              <h3>{integralShopDetail.title}</h3>
              <div class="tags">
                <span>{integralShopDetail.scorePrice}积分</span>x1
              </div>
              {/*  <div class="other-info">
              <span>颜色：白色</span>
              <span>尺寸：常规</span>
            </div>*/}
              <div class="btn-group">
                {integralShopDetail.type === 2 ? (
                  <span class="blue" onclick={this.onShowModal}>
                    物流状态
                  </span>
                ) : null}
                <a
                  class="red"
                  target="_bank"
                  onclick={this.immediatelyConsult}
                  href="https://live800.eiceducation.com.cn/live800/chatClient/chatbox.jsp?companyID=8940&configID=79"
                >
                  联系客服
                </a>
              </div>
            </div>
          </div>
        ) : null}
        {integralShopDetail ? (
          <div class="info-box1">
            <div class="item">
              <span class="label">支付方式</span>
              <span class="value">积分兑换</span>
            </div>
            <div class="item">
              <span class="label">订单总价</span>
              <span class="value red">{integralShopDetail.scorePrice}积分</span>
            </div>
          </div>
        ) : null}
        {integralShopDetail ? (
          <div class="info-box2">
            <h3>订单信息</h3>
            <div class="item">
              <span class="lable">订单号：</span>
              <div class="value">
                <span id="copy"> {integralShopDetail.number}</span>
                <span class="btn" onclick={() => this.copy("copy")}>
                  复制
                </span>
              </div>
            </div>
            <div class="item">
              <span class="lable">下单时间：</span>
              <div class="value">{integralShopDetail.createTime}</div>
            </div>
          </div>
        ) : null}
        <div class="correlation-box">
          <h3>相关产品</h3>
          <div class="list">
            {relevantGoodList.map((item) => (
              <Item data={item} />
            ))}
          </div>
        </div>
        <div class="btn-box">
          <span class="btn" onclick={() => this.onInfoClick("shop")}>
            返回商城首页
          </span>
        </div>

        {this.showModal ? (
          <Modal
            close={() => (this.showModal = false)}
            data={integralShopDetail}
          />
        ) : null}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.detail-box {
  padding: 24px;
  background: #ffffff;
  margin-left: 23px;
  border-radius: 16px;
  max-width: 882px;
  width: 882px;
  box-sizing: border-box;
  .status-box {
    padding: 20px 0;
    color: #000000;
    font-size: 20px;
    font-weight: 400;
  }
  .address {
    background: #dae9fc;
    font-size: 20px;
    font-weight: 400;
    color: #066eeb;
    border-radius: 8px;
    padding: 0 12px;
    height: 50px;
    display: flex;
    align-items: center;
    .label {
      font-size: 20px;
      font-weight: 500;
      color: #066eeb;
      margin-right: 20px;
    }
  }
  .order-box {
    display: flex;
    padding-bottom: 20px;
    margin-top: 20px;
    border-bottom: 1px solid #dce0e5;
    img {
      width: 263px;
      height: 167px;
      object-fit: cover;
      border-radius: 8px;
      margin-right: 24px;
    }
    .info {
      padding-top: 20px;
      width: 100%;
      .orderNum {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: #9ca6b2;
        margin-bottom: 12px;
      }
      h3 {
        font-size: 18px;
        font-weight: 500;
        color: #000000;
        margin-bottom: 13px;
      }
      .tags {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        span {
          display: block;
          padding: 0 7px;
          background: #feecef;
          border-radius: 8px;
          height: 24px;
          line-height: 24px;
          font-size: 16px;
          font-weight: 500;
          color: #f94164;
          margin-right: 10px;
        }
      }
      .other-info {
        display: flex;
        align-items: center;
        span {
          font-size: 14px;
          font-weight: 400;
          color: #9ca6b2;
          margin-right: 10px;
        }
      }
      .btn-group {
        display: flex;
        justify-content: flex-end;
        a,
        span {
          display: block;
          padding: 0 11px;
          border-radius: 8px;
          height: 28px;
          line-height: 28px;
          font-weight: 500;
          cursor: pointer;
          font-size: 16px;
          text-decoration: none;
        }
        .blue {
          background: #066eeb;
          color: #ffffff;
          margin-right: 18px;
        }
        .red {
          background: #feecef;
          color: #f94164;
        }
      }
    }
  }
  .info-box1 {
    border-bottom: 1px solid #dce0e5;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 5px;
    align-items: center;
    .item {
      width: 47%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      .label {
        font-size: 20px;
        font-weight: 400;
        color: #000000;
      }
      .value {
        font-size: 20px;
        font-weight: 400;
        color: #9ca6b2;
      }
      .value.red {
        color: #f94164;
      }
    }
  }
  .info-box2 {
    border-bottom: 1px solid #dce0e5;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    h3 {
      font-size: 22px;
      font-weight: 400;
      color: #000000;
      margin-bottom: 17px;
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      .label {
        font-size: 20px;
        font-weight: 400;
        color: #000000;
      }
      .value {
        font-size: 20px;
        font-weight: 400;
        color: #9ca6b2;
        .btn {
          background: #e5f0fd;
          padding: 0 13px;
          font-size: 18px;
          font-weight: 500;
          color: #006eeb;
          border-radius: 8px;
          cursor: pointer;
          margin-left: 12px;
        }
      }
    }
  }
  .correlation-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    h3 {
      font-size: 22px;
      font-weight: 400;
      color: #000000;
      padding: 20px 0px;
    }
    .list {
      display: flex;flex-wrap: wrap;
    }
  }
  .btn-box {
    display: flex;
    justify-content: center;
    .btn {
      display: block;
      width: 204px;
      height: 50px;
      line-height: 50px;
      border-radius: 8px;
      text-align: center;
      font-size: 20px;
      background: #066eeb;
      font-weight: 600;
      cursor: pointer;
      color: #ffffff;
    }
  }
}
</style>