<script>
import ModelBox from "../../../components/ModelBox";
import England from "@assets/images/England.png";
import America from "@assets/images/America.png";
import Australian from "@assets/images/Australian.png";
import Canada from "@assets/images/Canada.png";
import France from "@assets/images/France.png";
import Germany from "@assets/images/Germany.png";
import Hongkong from "@assets/images/Hongkong.png";
import Japanese from "@assets/images/Japanese.png";
import Korea from "@assets/images/Korea.png";
import Netherlands from "@assets/images/Netherlands.png";
import Singapore from "@assets/images/Singapore.png";
import Switzerland from "@assets/images/Switzerland.png";
import Zealand from "@assets/images/Zealand.png";
import Italy from "@assets/images/Italy.png";
export default {
  name: "studyDest",
  data() {
    return {
      list: [
        { id: 2, pageInfoId: 156, name: "英国", img: England },
        { id: 1, pageInfoId: 155, name: "美国", img: America },
        { id: 4, pageInfoId: 158, name: "加拿大", img: Canada },
        { id: 5, pageInfoId: 159, name: "新西兰", img: Zealand },
        { id: 6, pageInfoId: 160, name: "荷兰", img: Netherlands },
        { id: 13, pageInfoId: 166, name: "中国香港", img: Hongkong },
        { id: 11, pageInfoId: 168, name: "德国", img: Germany },
        { id: 9, pageInfoId: 163, name: "法国", img: France },
        { id: 7, pageInfoId: 161, name: "日本", img: Japanese },
        { id: 14, pageInfoId: 167, name: "新加坡", img: Singapore },
        { id: 10, pageInfoId: 164, name: "瑞士", img: Switzerland },
        { id: 12, pageInfoId: 165, name: "意大利", img: Italy },
        { id: 8, pageInfoId: 162, name: "韩国", img: Korea },
        { id: 3, pageInfoId: 157, name: "澳大利亚", img: Australian },
      ],
    };
  },
  methods: {
    onImgClick(record) {
      this.$router.push({
        path: `/country/detail?id=${record.id}&pageInfoId=${record.pageInfoId}`,
      });
    },
  },
  render() {
    const modelBoxData = {
      blue: "留学",
      yellow: "目的地",
      content: () => (
        <div class="list">
          {this.list.map((item) => (
            <img src={item.img} onclick={() => this.onImgClick(item)} />
          ))}
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  img {
    cursor: pointer;
    width: 136px;
    height: 100px;
    object-fit: cover;
    margin-right: 16px;
    margin-bottom: 16px;
    &:nth-child(7n) {
      margin-right: 0;
    }
  }
}
</style>