<script>
export default {
  name: "navBar",
  data() {
    return {
      nowPage: "",
    };
  },
  mounted() {
    this.nowPage = this.$route.path;
  },
  methods: {
    jumpUrl(url) {
      if (!window.$cookies.get("token")) {
        this.$store.commit("LoginModel/updateState", {
          isShowLogin: true,
          loginState: "login",
        });
        return;
      }
      this.$router.push({ path: url });
    },
  },
  watch: {
    $route(to, from) {
      this.nowPage = to.path;
    },
  },
  render() {
    return (
      <div class="navBarBox">
        <span
          class={this.nowPage === "/my/learnRecord" ? "active" : null}
          onclick={() => this.jumpUrl("/my/learnRecord")}
        >
          学习记录
        </span>
        <span
          class={this.nowPage === "/my/appointment" ? "active" : null}
          onclick={() => this.jumpUrl("/my/appointment")}
        >
          我的预约
        </span>
        <span
          class={this.nowPage === "/my/info" ? "active" : null}
          onclick={() => this.jumpUrl("/my/info")}
        >
          个人信息
        </span>
        <span
          class={this.nowPage === "/my/message" ? "active" : null}
          onclick={() => this.jumpUrl("/my/message")}
        >
          我的消息
        </span>
        <span
          class={this.nowPage === "/my/order" ? "active" : null}
          onclick={() => {
            this.jumpUrl("/my/order");
            this.$store.commit("MyModel/updateState", {
              orderState: "list",
              orderDetailNumber: null,
            });
          }}
        >
          我的订单
        </span>
        <span
          class={this.nowPage === "/my/shop" ? "active" : null}
          onclick={() => {
            this.jumpUrl("/my/shop");
            this.$store.commit("MyModel/updateState", {
              integralState: "shop",
              integralId: null,
            });
          }}
        >
          积分商城
        </span>
        <span
          class={this.nowPage === "/my/integral" ? "active" : null}
          onclick={() => {
            this.jumpUrl("/my/integral");
          }}
        >
          我的积分
        </span>
        <span
          class={this.nowPage === "/my/collect" ? "active" : null}
          onclick={() => this.jumpUrl("/my/collect")}
        >
          我的收藏
        </span>
        <span
          class={this.nowPage === "/my/learnTime" ? "active" : null}
          onclick={() => this.jumpUrl("/my/learnTime")}
        >
          学习时长
        </span>
        <span
          class={this.nowPage === "/my/discounts" ? "active" : null}
          onclick={() => this.jumpUrl("/my/discounts")}
        >
          优惠管理
        </span>
        <span
          class={this.nowPage === "/my/prize" ? "active" : null}
          onclick={() => this.jumpUrl("/my/prize")}
        >
          我的奖品
        </span>
        {/*<span
          class={this.nowPage === "/my/overlord" ? "active" : null}
          onclick={() => this.jumpUrl("/my/overlord")}
        >
          领霸王课
        </span>*/}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.navBarBox {
  padding: 22px 0;
  background: #ffffff;
  border-radius: 16px;
  width: 194px;
  box-sizing: border-box;
  span,
  a {
    display: block;
    height: 42px;
    line-height: 42px;
    text-align: center;
    color: #4e4e4e;
    font-size: 22px;
    font-weight: 500;
    padding: 5px 0;
    cursor: pointer;
    text-decoration: none;
  }
  span.active,
  a.active {
    background: #066eeb;
    color: #ffffff;
  }
}
</style>