<script>
export default {
  name: "Pagination",
  props: {
    paginationObj: Object,
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  mounted() {
    this.currentPage = this.paginationObj.currentPage || 1;
  },
  methods: {
    onNextClick() {
      const { totalPage } = this.paginationObj;
      if (this.currentPage + 1 <= totalPage) {
        this.currentPage = this.currentPage + 1;
        this.paginationObj.pageChange(this.currentPage);
      }
    },
    onPrevClick() {
      if (this.currentPage - 1 >= 1) {
        this.currentPage = this.currentPage - 1;
        this.paginationObj.pageChange(this.currentPage);
      }
    },
    onPageChange(item) {
      this.currentPage = item;
      this.paginationObj.pageChange(item);
    },
    onKeyDown(e) {
      const { keyCode } = e;
      if ((keyCode < 48 || keyCode > 57) && keyCode != 190 && keyCode != 8) {
        e.preventDefault();
        return;
      }
    },
    onKeyUp(e) {
      if (e.keyCode === 13) {
        if (Number(e.target.value) > this.paginationObj.totalPage) {
          return;
        }
        if (Number(e.target.value) < 1) {
          return;
        }
        this.currentPage = Number(e.target.value);
        this.paginationObj.pageChange(e.target.value);
      }
    },
  },
  computed: {
    pageList() {
      const { totalPage } = this.paginationObj;
      const { currentPage } = this;
      let maxButton =
        this.paginationObj.maxButton < 4 ? 4 : this.paginationObj.maxButton;
      if (totalPage <= maxButton) {
        return [...new Array(totalPage)].map((item, index) => index + 1);
      }
      if (currentPage <= maxButton - 3 || currentPage === maxButton - 2) {
        return [...new Array(maxButton)].map((item, index) => index + 1);
      }

      if (currentPage >= totalPage - 1 || currentPage === totalPage - 2) {
        const arr = [];
        [...new Array(maxButton)].map((item, index) => {
          if (index < maxButton) {
            arr.push(totalPage - index);
          }
        });
        return arr.reverse();
      }

      const arr = [];
      [...new Array(maxButton)].map((item, index) => {
        arr.push(currentPage + (index - 1));
      });
      return arr;
    },
  },
  render() {
    if (this.paginationObj.currentPage) {
      this.currentPage = this.paginationObj.currentPage || 1;
    }

    const { totalPage } = this.paginationObj;

    return (
      <div class="pagination">
        <span class="sumPage">共{this.paginationObj.totalPage}页</span>
        <div class="page-box">
          <span
            class={`btn prev ${this.currentPage === 1 ? "disabled" : ""}`}
            onclick={this.onPrevClick}
          >
            上一页
          </span>
          <div class="pageList">
            {this.pageList.map((item) => (
              <span
                class={`item ${this.currentPage === item ? "active" : ""}`}
                onclick={() => this.onPageChange(item)}
              >
                {item}
              </span>
            ))}
          </div>
          <span
            class={`btn next ${
              this.currentPage + 1 <= totalPage ? "" : "disabled"
            }`}
            onclick={this.onNextClick}
          >
            下一页
          </span>
        </div>
        <div class="goPage-box">
          前往
          <input
            onkeydown={this.onKeyDown.bind(this)}
            onkeyup={this.onKeyUp.bind(this)}
          />
          页
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.pagination {
  display: flex;
  align-items: center;
  .sumPage {
    font-size: 14px;
    font-weight: 400;
    color: #262626;
  }
  .page-box {
    margin-left: 24px;
    display: flex;
    align-items: center;
    .btn {
      width: 90px;
      height: 36px;
      background: #ffffff;
      border: 1px solid #e6eaf0;
      border-radius: 4px;
      font-size: 14px;
      color: #262626;
      font-weight: 400;
      text-align: center;
      line-height: 36px;
      cursor: pointer;
    }
    .btn.disabled {
      color: #999999;
    }
    .pageList {
      display: flex;
      align-items: center;
      margin-left: 8px;
      .item {
        height: 36px;
        background: #ffffff;
        border: 1px solid #e6eaf0;
        border-radius: 4px;
        padding: 0 14px;
        min-width: 36px;
        text-align: center;
        cursor: pointer;
        margin-right: 8px;
        box-sizing: border-box;
        line-height: 36px;
        &:hover {
          background: #f0f7ff;
          border: 1px solid #256ef5;
        }
      }
      .item.active {
        background: #256ef5;
        color: #ffffff;
      }
    }
  }
  .goPage-box {
    font-size: 14px;
    font-weight: 400;
    margin-left: 23px;
    color: #262626;
    input {
      background: #ffffff;
      border: 1px solid #e6eaf0;
      border-radius: 4px;
      width: 36px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      margin: 0 8px;
    }
  }
}
</style>