<script>
// 近期直播
import ModelBox from "@components/ModelBox";
import ActiveBlock from "./ActiveBlock.vue";
import RecentItem from "./RecentItem.vue";
import LiveItem from "./LiveItem.vue";
export default {
  name: "recentLive",
  props: {
    list: Array,
  },
  data() {
    return {
      nowData: {}, // 当前选中数据
      activeId: null, // 选中id
    };
  },
  mounted() {
    this.nowData = this.list[0];
    this.activeId = this.list[0].id;
  },
  methods: {
    onMouseOver(record) {
      this.nowData = record;
      this.activeId = record.id;
    },
  },
  render() {
    const modelBoxData = {
      blue: "近期",
      yellow: "直播",
      more: {
        title: "更多直播",
        onclick: () => {
          this.$router.push({ path: "/live/list" });
        },
      },
      content: () => (
        <div class="main">
          <ActiveBlock data={this.nowData} />
          <div class="list">
            {this.list.map((item) => {
              if (item.status === 1)
                return (
                  <RecentItem
                    data={item}
                    mouseover={this.onMouseOver}
                    activeid={this.activeId}
                  />
                );
              if (item.status === 2)
                return (
                  <LiveItem
                    data={item}
                    mouseover={this.onMouseOver}
                    activeid={this.activeId}
                  />
                );
            })}
          </div>
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.main {
  margin-top: 21px;
  display: flex;
  justify-content: space-between;
  .list {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    height: 295px;
    overflow-y: scroll;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
}
</style>