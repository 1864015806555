<script>
// 大学排名
import ModelBox from "@components/ModelBox";
import BaseItem from "../baseItem.vue";
export default {
  name: "universityRank",
  props: {
    list: Array,
  },
  render() {
    const modelBoxData = {
      blue: "大学排名",
      yellow: "解析",
      more: {
        title: "全部院校",
        onclick: () => {
          this.$router.push({ path: "/university/list" });
        },
      },
      content: () => (
        <div class="list">
          {this.list.map((item, index) => (
            <BaseItem data={item} />
          ))}
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;
}
</style>