<script>
import DialogComp from "@components/Dialog";
export default {
  name: "integralRule",
  props: {
    isShow: Boolean,
    closeFunc: {
      type: Function,
    },
  },
  render() {
    const dialogData = {
      onclose: this.closeFunc,
      style: { width: "570px" },
      content: () => (
        <div class="dialog-box">
          <h1>积分获取</h1>
          <p>
            用户可以通过完成任务获取积分，累积积分可以用来兑换积分商城的奖励。积分获取任
            务分为以下3种：
          </p>
          <div class="main-box">
            <div class="content-box">
              <h2>新手任务</h2>
              <table>
                <thead>
                  <tr>
                    <th width="160px">新手任务描述</th>
                    <th width="85px">获取积分</th>
                    <th>说明</th>
                  </tr>
                </thead>
                <tr>
                  <td>完成注册</td>
                  <td>10</td>
                  <td>一个账号获取一次</td>
                </tr>
                <tr>
                  <td>完善个人资料</td>
                  <td>50</td>
                  <td>包括生日、留学阶段、意向专业、所在城市</td>
                </tr>
                <tr>
                  <td>累计签到5天</td>
                  <td>50</td>
                  <td>一个账号获取一次</td>
                </tr>
              </table>
            </div>
            <div class="content-box">
              <h2>日常任务</h2>
              <p>
                根据会员类别，日常任务的内容有所区别，任务获取的积分有上限，具体详见下表：
              </p>
              <table>
                <thead>
                  <tr>
                    <th width="120px">会员类别名称</th>
                    <th width="190px">日常任务描述</th>
                    <th width="85px">获取积分</th>
                    <th width="140px">说明</th>
                  </tr>
                </thead>
                <tr>
                  <td rowspan="4">留学萌新</td>
                  <td>个人中心签到</td>
                  <td>5</td>
                  <td>每日限获取1次</td>
                </tr>
                <tr>
                  <td>观看站内课程</td>
                  <td>10</td>
                  <td>每日限获取1次</td>
                </tr>
                <tr>
                  <td>观看站内直播</td>
                  <td>10</td>
                  <td>每日限获取1次</td>
                </tr>
                <tr>
                  <td>分享站内内容到微信动态</td>
                  <td>20</td>
                  <td>每日限获取3次</td>
                </tr>
                <tr>
                  <td rowspan="6">留学探路者/留学领队</td>
                  <td>个人中心签到</td>
                  <td>5</td>
                  <td>每日限获取1次</td>
                </tr>
                <tr>
                  <td>观看站内课程</td>
                  <td>10</td>
                  <td>每日限获取1次</td>
                </tr>
                <tr>
                  <td>观看站内直播</td>
                  <td>10</td>
                  <td>每日限获取1次</td>
                </tr>
                <tr>
                  <td>分享站内内容到微信动态</td>
                  <td>20</td>
                  <td>每日限获取3次</td>
                </tr>
                <tr>
                  <td>搜索想要观看的内容</td>
                  <td>20</td>
                  <td>每日限获取1次</td>
                </tr>
                <tr>
                  <td>在课程下发表评论</td>
                  <td>20</td>
                  <td>20字以上长评每条20分，每日限获取1次</td>
                </tr>
                <tr>
                  <td rowspan="5">留学践行者</td>
                  <td>个人中心签到</td>
                  <td>5</td>
                  <td>每日限获取1次</td>
                </tr>
                <tr>
                  <td>分享到微信动态</td>
                  <td>20/次</td>
                  <td>每日限获取3次</td>
                </tr>
                <tr>
                  <td>在课程下发表评论</td>
                  <td>50/次</td>
                  <td>不少于20字的长评，每周限获取1次</td>
                </tr>
                <tr>
                  <td>观看站内课程</td>
                  <td>10</td>
                  <td>每日限获取1次</td>
                </tr>
                <tr>
                  <td>观看站内直播</td>
                  <td>10</td>
                  <td>每日限获取1次</td>
                </tr>
              </table>
            </div>
            <div class="content-box">
              <h2>限时活动</h2>
              <p>根据活动不定时推出，请届时查看任务界面的说明。</p>
            </div>
          </div>
        </div>
      ),
    };
    return <DialogComp v-show={this.isShow} data={dialogData} />;
  },
};
</script>
<style lang="less" scoped>
.dialog-box {
  padding: 0 32px;
  h1 {
    font-size: 22px;
    font-weight: 600;
    color: #262626;
    margin-bottom: 15px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #262626;
    margin-bottom: 20px;
  }
  .main-box {
    height: 460px;
    overflow-y: scroll;
    padding-right: 18px;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #99c5f7;
    }
    .content-box {
      h2 {
        font-size: 18px;
        font-weight: 400;
        color: #262626;
        margin-bottom: 15px;
      }
      table {
        margin-bottom: 30px;
        border-collapse: collapse;
        text-align: center;
      }
      table thead th {
        color: #515a6e;
        font-weight: 600;
        font-size: 14px;
        background: #f8f8f9;
        height: 35px;
        line-height: 35px;
      }
      table td,
      table th {
        border: 1px solid #dee2e6;
        height: 30px;
        font-size: 14px;
        font-weight: 400;
        color: #515a6e;
      }
    }
  }
}
</style>