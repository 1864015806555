
<script>
export default {
  name: "Item",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="item">
        <div
          class="imgBox"
          onclick={()=>this.$router.push({ path: `/live/detail?id=${this.data.id}`}) }
        >
          <img src={this.data.coverPhoto} />
          <div class="shadow">
            <i class="icon-live-text" />
            <div class="people">
              <i class="icon-people" />
              {this.data.subscribeCount}人预约
            </div>
          </div>
        </div>
        <div class="content">
          <p>{this.data.name}</p>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.item {
  width: 230px;
  margin-bottom: 24px;
  .imgBox {
    width: 230px;
    height: 146px;
    border-radius: 16px;
    cursor: pointer;
    position: relative;
    .shadow {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      justify-content: space-between;
      height: 42px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      padding: 17px 12px 0px 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .icon-live-text {
        display: block;
        width: 37px;
        height: 13px;
        background-image: url("../../../assets/images/live-text.png");
        background-size: 100%;
        background-repeat: no-repeat;
      }
      .people {
        display: flex;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        .icon-people {
          display: block;
          width: 17px;
          height: 17px;
          background-image: url("../../../assets/images/icon-people.png");
          background-size: 100%;
          background-repeat: no-repeat;
          margin-right: 8px;
        }
      }
      .watch {
        display: flex;
        align-items: center;
        .icon-eye {
          width: 16px;
          height: 13px;
          display: block;
          background-image: url("../../../assets/images/icon-eye.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 8px;
        }
        p {
          color: #ffffff;
          font-size: 14px;
          font-weight: 300;
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content {
    p {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
      margin-top: 9px;
    }
  }
}
</style>