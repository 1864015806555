
<script>
import BreadCrumbsMy from "@components/BreadCrumbsMy.vue";
import PayModel from "./PayMode.vue";
import Info from "./Info.vue";
import Item from "./Item.vue";
import { urlNocanshu } from "../../../../utils/index.js";
export default {
  name: "orderDetail",
  data() {
    return {
      breadCrumbsList: [
        {
          name: "我的订单",
          onclick: () =>
            this.$store.commit("MyModel/updateState", { orderState: "list" }),
        },
        {
          name: "订单详情",
        },
      ],
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    const { orderDetailNumber } = this.$store.state.MyModel;
    try {
      await this.$store.dispatch("MyModel/getOrderDetail", {
        number: orderDetailNumber,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    const { orderDetailData } = this.$store.state.MyModel;
    if (orderDetailData.type === "course") {
      try {
        await this.$store.dispatch("MyModel/getRelevantCourseList", {
          id: orderDetailData.productId,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    }

    if (orderDetailData.type === "live") {
      try {
        await this.$store.dispatch("MyModel/getRelevantLiveList", {
          id: orderDetailData.productId,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    }
  },
  methods: {
    async cancleOrder(number) {
      let msg;
      try {
        msg = await this.$store.dispatch("MyModel/cancleOrder", { number });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.$message({ showClose: true, message: msg, type: "success" });
      const { orderDetailNumber } = this.$store.state.MyModel;
      try {
        await this.$store.dispatch("MyModel/getOrderDetail", {
          number: orderDetailNumber,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
    // 用户打开Live800记录
    async immediatelyConsult() {
      try {
        await this.$store.dispatch("MyModel/recordBehavior", {
          app:3, 
          action:1,  
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pageurl:urlNocanshu(),
          accountId:window.$cookies.get("accountId") || ''
        });
      } catch (msg) {
        // this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
  },
  render() {
    const {
      orderDetailData,
      relevantCourseList,
      relevantLiveList,
    } = this.$store.state.MyModel;
    return (
      <div class="Box">
        <BreadCrumbsMy list={this.breadCrumbsList} />
        <div class="status">
          {orderDetailData.state === 1 && "待支付"}
          {orderDetailData.state === 8 && "已完成"}
          {orderDetailData.state === 9 && "已失效"}
        </div>
        <div class="infoBox">
          <img
            src={orderDetailData.coverPhoto}
            onclick={() =>
              this.$router.push({
                path: `/${orderDetailData.type}/detail?id=${orderDetailData.productId}`,
              })
            }
          />
          <div class="content">
            <div class="top">
              <div class="orderId">
                <p>订单编号：{orderDetailData.number}</p>
                <a
                  class="btn pink"
                  target="_bank"
                  onclick={this.immediatelyConsult}
                  href="https://live800.eiceducation.com.cn/live800/chatClient/chatbox.jsp?companyID=8940&configID=79"
                >
                  联系客服
                </a>
              </div>
              <p class="des">{orderDetailData.title}</p>
            </div>
            <div class="bottom">
              <p class="price">￥{orderDetailData.totalPrice}</p>
              {orderDetailData.state === 1 ? (
                <div class="btnGroup">
                  <span
                    class="btn blue"
                    style={{ marginRight: "12px" }}
                    onclick={() => this.cancleOrder(orderDetailData.number)}
                  >
                    取消订单
                  </span>
                  <span
                    class="btn red"
                    onclick={() =>
                      this.$router.push({
                        path: `/order/info?id=${orderDetailData.productId}&type=${orderDetailData.type}`,
                      })
                    }
                  >
                    立即支付
                  </span>
                </div>
              ) : null}
              {orderDetailData.state === 8 ? (
                <span
                  class="btn blue2"
                  onclick={() =>
                    this.$router.push({
                      path: `/${orderDetailData.type}/detail?id=${orderDetailData.productId}`,
                    })
                  }
                >
                  {orderDetailData.type === "course" ? "观看课程" : ""}
                  {orderDetailData.type === "live" ? "观看直播" : ""}
                </span>
              ) : null}
              {orderDetailData.state === 9 ? (
                <span
                  class="btn orange"
                  onclick={() =>
                    this.$router.push({
                      path: `/order/info?id=${orderDetailData.productId}&type=${orderDetailData.type}`,
                    })
                  }
                >
                  重新下单
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <PayModel data={orderDetailData} />
        <Info data={orderDetailData} />
        <div class="similarClass">
          <p class="title">
            {orderDetailData.type === "course" ? "同类课程" : ""}
            {orderDetailData.type === "live" ? "同类直播" : ""}
          </p>
          {orderDetailData.type === "live" ? (
            <div class="list">
              {relevantLiveList.map((item) => (
                <Item data={{ ...item, type: orderDetailData.type }} />
              ))}
            </div>
          ) : null}
          {orderDetailData.type === "course" ? (
            <div class="list">
              {relevantCourseList.map((item) => (
                <Item data={{ ...item, type: orderDetailData.type }} />
              ))}
            </div>
          ) : null}
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.Box {
  padding: 24px;
  background: #ffffff;
  margin-left: 23px;
  border-radius: 16px;
  max-width: 882px;
  width: 882px;
  box-sizing: border-box;
  .status {
    font-size: 18px;
    color: #006eeb;
    font-weight: bold;
    padding: 24px 0;
  }
  .similarClass {
    .title {
      padding: 20px 0;
      font-size: 20px;
      font-weight: bold;
      color: #1a1a1a;
    }
    .list {
      display: flex;
      flex-direction: column;
    }
  }
  .infoBox {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid #dce0e5;
    img {
      border-radius: 16px;
      width: 263px;
      height: 167px;
      object-fit: cover;
      cursor: pointer;
    }
    .content {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      .top {
        .orderId {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            font-size: 18px;
            font-weight: 400;
            color: #9ca6b2;
          }
          .des {
            font-size: 20px;
            color: #4e4e4e;
          }
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .price {
          font-size: 18px;
          font-weight: 400;
          color: #f94164;
        }
        .btnGroup {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  a.btn {
    text-decoration: none;
  }
  .btn {
    display: block;
    min-width: 100px;
    height: 30px;
    line-height: 30px;
    border-radius: 8px;
    text-align: center;
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 16px;
    cursor: pointer;
  }
  .btn.pink {
    background: #fee3e8;
    color: #f94164;
  }
  .btn.blue {
    background: #dae9fc;
    color: #066eeb;
  }
  .btn.blue2 {
    background: #066eeb;
    color: #ffffff;
  }
  .btn.red {
    background: #f94164;
    color: #ffffff;
  }
  .btn.orange {
    background: #ff833e;
    color: #ffffff;
  }
}
</style>