<script>
import BreadCrumbs from "@components/BreadCrumbs.vue";
import Pagination from "@components/Pagination.vue";
import SearchArea from "./searchArea/index";
import TeacherItem from "./teacherItem/index";
import Loading from "../../components/Loading";
import { randomNumber,GetQueryString,urldelCode,urlNocanshu } from "../../utils/index.js";
export default {
  name: "teachList",
  async mounted() {
    window.scrollTo(0, 0);
    if (window.$cookies.get("token")) {
        urldelCode()
    }
    const { countryId, categoryId } = this.$route.query;
    const { nowPage, searchData, pageSize } = this.$store.state.TeachListModel;
    this.$store.commit("TeachListModel/updateState", {
      isLoadingShow: true,
      searchData: {
        categoryId: categoryId || -1,
        keyword: "", // 搜索关键字
        countryId: Number(countryId) || -1, // 国家ID
        professionalId: -1, // 专业ID
      },
    });
    await this.$store.dispatch("TeachListModel/getTeacherList", {
      page: nowPage,
      pageSize,
      ...searchData,
      countryId,
      categoryId,
    });
    await this.$store.dispatch("TeachListModel/getCountryList");
    await this.$store.dispatch("TeachListModel/getEnumList");
    this.$store.commit("TeachListModel/updateState", { isLoadingShow: false });
    try {
        await this.$store.dispatch("TeachListModel/recordAllPage", {
          app:3, 
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pvId:randomNumber(),
          medium: window.$cookies.get("datachannelmed") || '',
          extendurl: window.$cookies.get("dataurl") || '',
          pageurl:urlNocanshu(),
          fullpageurl:window.location.href,
          accountId:window.$cookies.get("accountId") || ''
        });
      } catch (msg) {
        // this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
  },
  methods: {
    async onPrevClick() {
      const {
        nowPage,
        pageSize,
        searchData,
      } = this.$store.state.TeachListModel;
      if (nowPage <= 1) return;
      this.$store.commit("TeachListModel/updateState", {
        nowPage: nowPage - 1,
      });
      await this.$store.dispatch("TeachListModel/getTeacherList", {
        page: nowPage - 1,
        pageSize,
        ...searchData,
      });
    },
    async onNextClick() {
      const {
        nowPage,
        count,
        pageSize,
        searchData,
      } = this.$store.state.TeachListModel;
      const totalPage = Math.ceil(count / pageSize);
      if (nowPage >= totalPage) return;
      this.$store.commit("TeachListModel/updateState", {
        nowPage: nowPage + 1,
      });
      await this.$store.dispatch("TeachListModel/getTeacherList", {
        page: nowPage + 1,
        pageSize,
        ...searchData,
      });
    },
  },
  render() {
    const {
      teacherList,
      count,
      nowPage,
      pageSize,
      isLoadingShow,
      searchData,
    } = this.$store.state.TeachListModel;
    const totalPage = Math.ceil(count / pageSize);
    const paginationObj = {
      totalPage,
      maxButton: 5,
      currentPage: nowPage,
      pageChange: async (record) => {
        try {
          await this.$store.dispatch("TeachListModel/getTeacherList", {
            page: record,
            pageSize,
            ...searchData,
          });
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      },
    };
    return (
      <div class="container">
        <BreadCrumbs />
        <SearchArea />
        <div class="pageBox">
          <p>
            共<span class="yellow">{count}</span>条相关内容
          </p>
          <div class="pagination">
            <i
              class={`icon-prev ${nowPage <= 1 ? "disabled" : ""}`}
              onclick={this.onPrevClick}
            />
            <span>
              {totalPage ? nowPage : 0}/{totalPage}
            </span>
            <i
              class={`icon-next ${nowPage >= totalPage ? "disabled" : ""}`}
              onclick={this.onNextClick}
            />
          </div>
        </div>
        <div class="listBox">
          <div class="list">
            {teacherList.map((item) => (
              <TeacherItem data={item} />
            ))}
            <div class="paginationBox">
              <Pagination paginationObj={paginationObj} />
            </div>
          </div>
        </div>
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 1100px;
  margin: 0 auto;
  .crumbs {
    padding: 13px 0;
    align-items: center;
    display: flex;
    .tips {
      display: flex;
      align-items: center;
      color: #a4aab3;
      .icon-location {
        width: 16px;
        height: 16px;
        display: block;
        background-image: url("../../assets/images/location.png");
        background-size: 100%;
        background-repeat: no-repeat;
        margin-right: 7px;
      }
    }
    .prev {
      color: #a4aab3;
      cursor: pointer;
    }
    .now {
      color: #5c6066;
      font-size: 14px;
      margin-left: 8px;
    }
  }
  .pageBox {
    margin-top: 24px;
    padding: 22px 24px;
    background: #ffffff;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    p {
      font-size: 18px;
      font-weight: 400;
      color: #1a1a1a;
      span.yellow {
        color: #ffa000;
      }
    }
    .pagination {
      display: flex;
      align-items: center;
      .icon-prev {
        display: block;
        width: 25px;
        height: 25px;
        transform: rotateY(180deg);
        background-image: url("../../assets/images/next.png");
        background-size: 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        margin-right: 12px;
      }
      .icon-prev.disabled {
        transform: rotateY(0deg);
        background-image: url("../../assets/images/prev.png");
      }
      span {
        font-size: 18px;
        font-weight: 400;
        margin-right: 12px;
        color: #262626;
      }
      .icon-next {
        display: block;
        width: 25px;
        height: 25px;
        background-image: url("../../assets/images/next.png");
        background-size: 100%;
        background-repeat: no-repeat;
        cursor: pointer;
      }
      .icon-next.disabled {
        background-image: url("../../assets/images/prev.png");
        transform: rotateY(180deg);
      }
    }
  }
  .listBox {
    background: #ffffff;
    padding: 24px;
    border-radius: 16px;
    margin-top: 24px;
    margin-bottom: 64px;
    .list {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .paginationBox {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
</style>