<script>
import Item from "./Item";
import ModelBox from "@components/ModelBox";
export default {
  name: "guessLike",
  props: {
    list: Array,
  },
  render() {
    const modelBoxData = {
      blue: "猜你",
      yellow: "喜欢",
      content: () => (
        <div class="list">
          {this.list.map((item) => (
            <Item data={item} />
          ))}
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
}
</style>