<script>
import SearchArea from "./SearchArea";
import Loading from "@components/Loading";
import BreadCrumbs from "@components/BreadCrumbs.vue";
import Pagination from "@components/Pagination.vue";
import SchoolItem from "./SchoolItem";
import TeacherItem from "./TeacherItem";
import LiveItem from "./LiveItem";
import CourseItem from "./CourseItem";
import Page from "./Page";
import { randomNumber,GetQueryString,urldelCode,urlNocanshu,formatDateTime } from "../../utils/index.js";
export default {
  name: "searchResult",
  async mounted() {
    window.scrollTo(0, 0);
    if (window.$cookies.get("token")) {
        urldelCode()
    }
    const { searchData } = this.$store.state.SearchResult;
    const { keyword } = this.$route.query;
    this.$store.commit("SearchResult/updateState", {
      searchData: {
        ...searchData,
        keyword,
      },
    });
    try {
      await this.$store.dispatch("SearchResult/getCountryList");
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    try {
      await this.$store.dispatch("SearchResult/getEnumList");
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    this.getList();
    try {
        await this.$store.dispatch("SearchResult/recordAllPage", {
          app:3, 
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pvId:randomNumber(),
          medium: window.$cookies.get("datachannelmed") || '',
          extendurl: window.$cookies.get("dataurl") || '',
          pageurl:urlNocanshu(),
          fullpageurl:window.location.href,
          accountId:window.$cookies.get("accountId") || ''
        });
    } catch (msg) {
        // this.$message({ showClose: true, message: msg, type: "error" });
        return;
    }
  },

  methods: {
    async getList() {
      const { searchData, limit } = this.$store.state.SearchResult;
      try {
        await this.$store.dispatch("SearchResult/siteSearch", {
          ...searchData,
          page: 1,
          limit,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
  },
  render() {
    const {
      isLoadingShow,
      listData,
      count,
      nowPage,
      limit,
      searchData,
    } = this.$store.state.SearchResult;
		// console.log(listData,"listData")
    const paginationObj = {
      totalPage: Math.ceil(count / limit),
      maxButton: 5,
      currentPage: Number(nowPage),
      pageChange: async (record) => {
				// console.log(nowPage,"nowPage")
				// console.log(record,"record")
        try {
					await this.$store.commit("SearchResult/updateState", {
					  nowPage: Number(record)
					});
          await this.$store.dispatch("SearchResult/siteSearch", {
            page: record,
            limit,
            ...searchData,
          });
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      },
    };
    return (
      <div class="container">
        <BreadCrumbs list={this.breadCrumbsList} />
        <SearchArea />
        <Page />
        <div class="listBox">
          <div class="list">
            {listData.map((item) => {
              if (item.ModuleType === '11') return <TeacherItem data={item} />;
              if (item.ModuleType === '5') return <CourseItem data={item} />;
              if (item.ModuleType === '4') return <LiveItem data={item} />;
              if (item.ModuleType === '3') return <SchoolItem data={item} />;
            })}
            <div class="paginationBox">
              <Pagination paginationObj={paginationObj} />
            </div>
          </div>
        </div>
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 1100px;
  margin: 0 auto;

  .pageBox {
    margin-top: 24px;
    padding: 22px 24px;
    background: #ffffff;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;

    p {
      font-size: 18px;
      font-weight: 400;
      color: #1a1a1a;

      span.yellow {
        color: #ffa000;
      }
    }

    .pagination {
      display: flex;
      align-items: center;

      .icon-prev {
        display: block;
        width: 25px;
        height: 25px;
        transform: rotateY(180deg);
        background-image: url("../../assets/images/next.png");
        background-size: 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        margin-right: 12px;
      }

      .icon-prev.disabled {
        transform: rotateY(0deg);
        background-image: url("../../assets/images/prev.png");
      }

      span {
        font-size: 18px;
        font-weight: 400;
        margin-right: 12px;
        color: #262626;
      }

      .icon-next {
        display: block;
        width: 25px;
        height: 25px;
        background-image: url("../../assets/images/next.png");
        background-size: 100%;
        background-repeat: no-repeat;
        cursor: pointer;
      }

      .icon-next.disabled {
        background-image: url("../../assets/images/prev.png");
        transform: rotateY(180deg);
      }
    }
  }

  .listBox {
    background: #ffffff;
    padding: 24px;
    border-radius: 16px;
    margin-top: 24px;
    margin-bottom: 64px;

    .list {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .paginationBox {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
</style>