<script>
import BreadCrumbs from "@components/BreadCrumbs.vue";
import Banner from "./banner";
import Loading from "@components/Loading";
import Abstract from "./abstract";
import TabModel from "./tabModel";
import University from "./university";
import { randomNumber,GetQueryString,urldelCode,urlNocanshu } from "../../utils/index.js";
export default {
  name: "schoolDetail",
  data() {
    return {
      pageSize: 5,
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    if (window.$cookies.get("token")) {
        urldelCode()
    }
    const { id } = this.$route.query;
    try {
      await this.$store.dispatch("UniversityDetail/getSchoolDetail", { id });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    try {
      await this.$store.dispatch("UniversityDetail/getRelevantSchoolList", {
        id,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    try {
        await this.$store.dispatch("UniversityDetail/recordAllPage", {
          app:3, 
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pvId:randomNumber(),
          medium: window.$cookies.get("datachannelmed") || '',
          extendurl: window.$cookies.get("dataurl") || '',
          pageurl:urlNocanshu(),
          fullpageurl:window.location.href,
          accountId:window.$cookies.get("accountId") || ''
        });
      } catch (msg) {
        // this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
  },
  render() {
    const { isLoadingShow, detailData } = this.$store.state.UniversityDetail;
    return (
      <div class="container">
        <BreadCrumbs nowName={detailData.name} />
        <div class="main">
          <Banner />
          <Abstract />
          <TabModel />
          <University />
        </div>
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 1100px;
  margin: 0 auto;
  .main {
    display: flex;
    flex-direction: column;
    padding-bottom: 64px;
  }
}
</style>