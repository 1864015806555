<script>
import ModelBox from "../ModelBox";
import Item from "./Item";
export default {
  name: "studentShare",
  props: {
    list: Array,
    tabclick: {
      type: Function,
    },
  },
  data() {
    return {
      tabbarData: {
        list: [
          // 此ID根据枚举接口获取,不可更改
          { id: "18", value: "申请心得" },
          { id: "19", value: "语言准备" },
          { id: "20", value: "海外生活" },
          { id: "21", value: "实习就业" },
        ],
        nowTab: "18",
      },
      jumpUrl:"/live/list?categoryId=18"
    };
  },
  methods: {
    onTabClick(record) {
      this.tabclick(record);
      this.jumpUrl = `/live/list?categoryId=${record.id}`
    },
  },
  render() {
    const modelBoxData = {
      blue: "学子",
      yellow: "分享",
      more: {
        title: "全部分享",
        onclick: () => {
          this.$router.push({ path: this.jumpUrl })
        },
      },
      tabbar: this.tabbarData,
      ontabclick: this.onTabClick,
      content: () => (
        <div class="list">
          {this.list.map((item) => (
            <Item data={item} />
          ))}
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.list {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
</style>