<script>
import OrderList from "./orderList";
import OrderDetail from "./orderDetai";
import ConfirmOrder from "./confirmOrder";
export default {
  name: "myOrder",
  render() {
    const { orderState } = this.$store.state.MyModel;
    return (
      <div class="Box">
        {orderState === "list" ? <OrderList /> : null}
        {orderState === "detail" ? <OrderDetail /> : null}
        {orderState === "comfirm" ? <ConfirmOrder /> : null}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
</style>