
<script>
export default {
  name: "blockComp",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="block">
        <img src={this.data.coverPhoto} />
        <div class="shadowBox">
          {this.data.status === 2 ? (
            <span
              class="btn yellow"
              onclick={() =>
                this.$router.push({ path: `/live/detail?id=${this.data.id}` })
              }
            >
              立即学习
            </span>
          ) : (
            <span
              class="btn blue"
              onclick={() =>
                this.$router.push({
                  path: `/live/detail?id=${this.data.id}`,
                })
              }
            >
              立即预约
            </span>
          )}
          {this.data.status === 2 ? (
            <div class="living">
              <i class="icon-live" />
              <span>直播中</span>
            </div>
          ) : null}
          <div class="infoBox">
            <div class="text">{this.data.name}</div>
            <div class="info_bottom">
              <div class="speaker"></div>
              <div class="numberBox">
                <i class="icon-people" />
                <span>{this.data.subscribeCount || 0}人预约</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};
</script>

<style lang="less" scoped>
.block {
  width: 464px;
  height: 295px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .shadowBox {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    // display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      display: block;
      width: 147px;
      height: 46px;
      font-weight: bold;
      border-radius: 23px;
      cursor: pointer;
      text-align: center;
      line-height: 46px;
      font-size: 22px;
    }
    .btn.yellow {
      background: #fce9dd;
      color: #ff833e;
    }
    .btn.blue {
      background: #f0f7ff;
      color: #006eeb;
    }
    .infoBox {
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 100%;
      bottom: 0;
      left: 0;
      padding: 0 20px 13px 20px;
      box-sizing: border-box;
      .text {
        color: #ffffff;
        font-weight: 400;
        font-size: 22px;
        margin-bottom: 15px;
      }
      .info_bottom {
        display: flex;
        justify-content: space-between;
        .speaker {
          color: #ffffff;
          font-size: 16px;
          font-weight: 400;
        }
        .numberBox {
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          align-items: center;
          .icon-people {
            display: block;
            width: 17px;
            height: 17px;
            background-image: url("../../../assets/images/icon-people.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-right: 8px;
          }
        }
      }
    }
    .living {
      position: absolute;
      top: 20px;
      left: 20px;
      background: #ff833e;
      width: 111px;
      height: 25px;
      border-radius: 13px;
      padding: 4px 17px 4px 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .icon-live {
        display: block;
        width: 18px;
        height: 15px;
        margin-right: 10px;
        background-image: url("../../../assets/images/icon-live-white.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
</style>