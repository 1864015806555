<script>
export default {
  name: "teacherItem",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="teacherItem">
        <div class="photo">
          <img
            src={this.data.head}
            onclick={() =>
              this.$router.push({
                path: `/teacher/detail?id=${this.data.id}`,
              })
            }
          />
        </div>
        <div class="content">
          <div>
            <div class="infoBox">
              <p class="teachName">{this.data.name}</p>
              <p class="teachTitle">{this.data.position}</p>
            </div>
            {/* <div class="classBox">
              <div class="item">
                <div class="className">
                  <i class="icon-play" />
                  <span>解锁日本语言学校留学，宝藏...</span>
                </div>
              </div>
              <div class="item">
                <div class="className">
                  <i class="icon-play" />
                  <span>解锁日本语言学校留学，宝藏...</span>
                </div>
              </div>
            </div>*/}
          </div>
          <div
            class="allClass"
            onclick={() =>
              this.$router.push({
                path: `/teacher/detail?id=${this.data.id}`,
              })
            }
          >
            {"老师全部课程 >"}
          </div>
        </div>
      </div>
    );
  },
};
</script>

<style lang="less" scoped>
.teacherItem {
  background: #f0f7ff;
  border-radius: 16px;
  padding: 23px 13px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  &:nth-child(2n) {
    margin-right: 0;
  }
  &:nth-last-child(1) {
    margin-bottom: 0px;
  }
  &:nth-last-child(2) {
    margin-bottom: 0px;
  }
  .photo {
    width: 112px;
    min-width: 112px;
    height: 112px;
    overflow: hidden;
    border-radius: 8px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: #fff;
      cursor: pointer;
    }
  }
  .content {
    margin-left: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .infoBox {
      display: flex;
      flex-direction: column;
      .teachName {
        font-size: 18px;
        font-weight: 400;
        color: #262626;
      }
      .teachTitle {
        margin-top: 5px;
        font-size: 12px;
        font-weight: 400;
        color: #8a9099;
        width: 216px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
    .classBox {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0px;
        }
        .className {
          display: flex;
          align-items: center;
          .icon-play {
            display: block;
            width: 15px;
            height: 15px;
            background-image: url("../../assets/images/icon-player.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-right: 6px;
          }
          span {
            width: 193px;
            font-size: 14px;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #262626;
          }
        }
        .price {
          font-size: 16px;
          font-weight: bold;
          color: #ff833e;
        }
        .price.free {
          color: #51b3ac;
        }
      }
    }
    .allClass {
      cursor: pointer;
      font-size: 12px;
      font-weight: 400;
      color: #ff833e;
      margin-top: 10px;
      text-decoration: underline;
    }
  }
}
</style>