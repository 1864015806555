
<script>
export default {
  name: "BreadCrumbsMy",
  props: {
    list: Array,
  },
  render() {
    return (
      <div class="crumbs">
        {this.list.map((item, index) => {
          if (index === this.list.length - 1) {
            return <span class="now">{item.name}</span>;
          } else {
            return (
              <span class="prev" onclick={item.onclick}>
                {item.name} {">"}{" "}
              </span>
            );
          }
        })}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.crumbs {
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 7px;
  border-bottom: 1px solid #e6ebf3;
  span {
    font-size: 22px;
    font-weight: 400;
  }
  .prev {
    color: #a4aab3;
    cursor: pointer;
  }
  .now {
    color: #006eeb;
  }
}
</style>