<script>
import Item from "./Item";
import ModelBox from "@components/ModelBox";
export default {
  name: "hotSchool",
  async mounted() {
    try {
      await this.$store.dispatch("HomeModel/getSchoolList", {
        page: 1,
        pageSize: 4,
        categoryId: "1", // 官方认证
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  render() {
    const { schoolList } = this.$store.state.HomeModel;
    const modelBoxData = {
      blue: "热门院校",
      yellow: "推荐",
      more: {
        title: "全部院校",
        onclick: () => {
          // this.$router.push({ path: "/university/list" });
          this.$router.push({ name: "universityList" });
        },
      },
      content: () => (
        <div class="list">
          {schoolList.map((item) => {
            return <Item data={item} />;
          })}
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
</style>