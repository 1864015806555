<script>
import * as api from "@src/service/index";
import Loading from "@components/Loading";
import Swiper from "@components/Swiper.vue";
import MyAppointment from "./myAppointment/index";
import RecentLive from "./recentLive/index";
import HotLive from "./hotLive";
import TeacherOnline from "./teacherOnline";
import StudentShare from "@components/studentShare";
import Record from "./record";
import { randomNumber,GetQueryString,urldelCode,urlNocanshu } from "../../utils/index.js";
export default {
  name: "livePage",
  methods: {
    // 热门直播tab点击事件
    async onHotLiveTabClick(record) {
      try {
        record.id === "4"
          ? await this.$store.dispatch("LiveModel/getLiveListByIp")
          : await this.$store.dispatch("LiveModel/getHotLiveList", {
              type: record.id,
            });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
    // 学子分享组件tab切换事件
    async onShareTabClick(record) {
      try {
        await this.$store.dispatch("LiveModel/getStudentShareLiveList", {
          categoryId: record.id,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
    // 学子分享组件tab切换事件(全部)
    async onShareTabClick(record) {
      try {
        await this.$store.dispatch("LiveModel/getLiveList", {
          page: 1,
          pageSize:4,
          categoryId: record.id,
          status: 4
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    if (window.$cookies.get("token")) {
        urldelCode()
    }    
    try {
      await this.$store.dispatch("LiveModel/getPageInfoList", { id: 128 });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
      await this.$store.dispatch("LiveModel/getRecentLive", {
        page: 1,
        pageSize: 10,
        type: 2,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
      await this.$store.dispatch("LiveModel/getHotLiveList", {
        type: "1",
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
      await this.$store.dispatch("LiveModel/getHotRecordList", {
        page: 1,
        pageSize: 4,
        status: 2,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
      await this.$store.dispatch("LiveModel/getNewRecordList", {
        page: 1,
        pageSize: 4,
        status: 1,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
      await this.$store.dispatch("LiveModel/getTeacherOnlineLiveList", {
        categoryId: "8",
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    try {
      await this.$store.dispatch("LiveModel/getLiveList", {
        page: 1,
        pageSize:4,
        categoryId:"18",
        status: 4
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    try {
      await this.$store.dispatch("LiveModel/getStudentShareLiveList", {
        categoryId: "18",
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

        try {
        await this.$store.dispatch("LiveModel/recordAllPage", {
          app:3, 
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pvId:randomNumber(),
          medium: window.$cookies.get("datachannelmed") || '',
          extendurl: window.$cookies.get("dataurl") || '',
          pageurl:urlNocanshu(),
          fullpageurl:window.location.href,
          accountId:window.$cookies.get("accountId") || ''
        });
    } catch (msg) {
        // this.$message({ showClose: true, message: msg, type: "error" });
        return;
    }

    //获取用户预约直播
    try {
      await this.$store.dispatch("LiveModel/getUserSubLiveList", {
        status: 2,
        // page: 1,
        // pageSize: 2,
      });
    } catch (msg) {
      // this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    
    // 获取用户直播回放
    try {
      await this.$store.dispatch("LiveModel/getUserLiveBack", {
        page: 1,
        pageSize: 4,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  render() {
    const {
      isLoadingShow,
      swiperList,
      liveList,
      hotLiveList,
      studentShareList,
      teacherOnlineList,
      userSubLiveList,
      studentShareLists
    } = this.$store.state.LiveModel;
    return (
      <div class="container">
        <Swiper list={swiperList} />
        {window.$cookies.get("token") && userSubLiveList.length ? (
          <MyAppointment list={userSubLiveList} />
        ) : null}
        {liveList.length ? <RecentLive list={liveList} /> : null}
        <HotLive list={hotLiveList} tabclick={this.onHotLiveTabClick} />
        <TeacherOnline list={teacherOnlineList} />
        <StudentShare list={studentShareLists} tabclick={this.onShareTabClick} />
        <Record />
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 1100px;
  margin: 0 auto;
  padding: 32px 0 64px;
}
</style>