<script>
import BreadCrumbs from "@components/BreadCrumbs.vue";
import VideoInfo from "./videoInfo";
import CourseDes from "./courseDes";
import Comment from "./Comment";
import Speaker from "./speaker";
import Recommend from "./recommend";
import Loading from "@components/Loading";
import Series from "./series";
import { randomNumber,GetQueryString,urldelCode,urlNocanshu } from "../../utils/index.js";
export default {
  name: "ClassDetail",
  data() {
    return {
      breadCrumbsList: [
        {
          name: "首页",
          onclick: () => this.$router.push({ path: "/home" }),
        },
        {
          name: "课程列表",
          onclick: () => this.$router.push({ path: "/course/list" }),
        },
        {
          name: "课程详情页",
        },
      ],
      pageSize: 5,
      pvId:randomNumber(),
      seriesId:'',
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    if (window.$cookies.get("token")) {
        urldelCode()
    }
    const { id } = this.$route.query;
    console.log(id)
    this.seriesId = id
    this.$store.commit("ClassDetailModel/updateState", {
      detailData: {},
      relevantCourseList:[]
    });
    if (window.$cookies.get("token")) {
      try {
        await this.$store.dispatch("MyModel/getUserInfo");
      } catch (error) {
          if (error.code == 1016) {
            this.$message({ showClose: true, message: error.msg, type: "error" });
            window.$cookies.set("token",'')
          }
          return;
        }
    }

    try {
      await this.$store.dispatch("ClassDetailModel/getCourseDetail", { id });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
      await this.$store.dispatch("ClassDetailModel/getCourseReview", {
        id,
        page: 1,
        pageSize: this.pageSize,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
      await this.$store.dispatch("ClassDetailModel/getRelevantCourseList", {
        id,
        page: 1,
        pageSize: 5,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    try {
        await this.$store.dispatch("ClassDetailModel/recordAllPage", {
          app:3, 
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pvId:this.pvId,
          medium: window.$cookies.get("datachannelmed") || '',
          extendurl: window.$cookies.get("dataurl") || '',
          pageurl:urlNocanshu(),
          fullpageurl:window.location.href,
          accountId:window.$cookies.get("accountId") || ''
        });
    } catch (msg) {
      // this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    const { detailData } = this.$store.state.ClassDetailModel;
    const { detail } = detailData;
    const { name } = detail;
    try {
      await this.$store.dispatch("ClassDetailModel/recordDetailPage", {
        app:3, 
        module:2,
        datatype:1,
        contentTitle:name,
        uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
        pvId:this.pvId,
        project:'',
        country:'',
        contentId:id,
        medium: window.$cookies.get("datachannelmed") || '',
        extendurl: window.$cookies.get("dataurl") || '',
        pageurl:urlNocanshu(),
        accountId:window.$cookies.get("accountId") || ''
      });
    } catch (msg) {
        // this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  render() {
    const {
      isLoadingShow,
      detailData,
      reviewList,
      reviewCount,
      relevantCourseList,
    } = this.$store.state.ClassDetailModel;
    const { detail, teachers, courses } = detailData;
    return (
      <div class="container">
        <BreadCrumbs list={this.breadCrumbsList} />
        <div class="main">
          <div class="left">
            {detail && <VideoInfo seriesId = {this.seriesId} data={detail} list={courses} />}
            {detail && detail.mode === 2 && courses.length ? (
              <Series seriesId = {this.seriesId} list={courses} />
            ) : null}
            {detail && <CourseDes data={detail.introduction} />}
            {detail && (
              <Comment
                list={reviewList}
                count={reviewCount}
                pageSize={this.pageSize}
              />
            )}
          </div>
          <div class="right">
            {teachers && teachers.length ? <Speaker list={teachers} /> : null}
            {relevantCourseList.length ? (
              <Recommend list={relevantCourseList} />
            ) : null}
          </div>
        </div>
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 1100px;
  margin: 0 auto;
  .main {
    display: flex;
    padding-bottom: 64px;
    .left {
      max-width: 798px;
      display: flex;
      flex-direction: column;
    }
    .right {
      margin-left: 24px;
      max-width: 278px;
      width: 278px;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>