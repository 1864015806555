<script>
import VipRule from "@components/VipRule";
import Loading from "@components/Loading";
import { urlNocanshu } from "../../../utils/index.js";
export default {
  name: "myInfo",
  data() {
    return {
      isVipRuleShow: false,
      infoData: {},
    };
  },
  async mounted() {
    if (!window.$cookies.get("token")) return;
    await this.$store.dispatch("MyModel/isSign");
    try {
      await this.$store.dispatch("MyModel/getUserInfo");
    } catch (error) {
          if (error.code == 1016) {
            this.$message({ showClose: true, message: error.msg, type: "error" });
            window.$cookies.set("token",'')
          }
          return;
        }
  },
  methods: {
    async onSignClick() {
      const { isSign } = this.$store.state.MyModel;
      if (isSign) return;
      if (!window.$cookies.get("token")) {
        this.$store.commit("LoginModel/updateState", {
          isShowLogin: true,
          loginState: "login",
        });
        return;
      }
      let msg;
      try {
        msg = await this.$store.dispatch("MyModel/userSign");
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.$message({ showClose: true, message: msg, type: "success" });
    },
    onEditInfoClick() {
      if (!window.$cookies.get("token")) {
        this.$store.commit("LoginModel/updateState", {
          isShowLogin: true,
          loginState: "login",
        });
        return;
      }
      this.$router.push({ path: "/my/info" });
    },
    // 用户打开Live800记录
    async immediatelyConsult() {
      try {
        await this.$store.dispatch("MyModel/recordBehavior", {
          app:3, 
          action:1,  
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pageurl:urlNocanshu(),
          accountId:window.$cookies.get("accountId") || ''
        });
      } catch (msg) {
        // this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
  },
  render() {
    const { isSign, isLoadingShow, userInfo } = this.$store.state.MyModel;
    return (
      <div>
        <div class="myInfoBox">
          <div class="avatarBox">
            <img src={userInfo && userInfo.head} />
            <div class="avatar-info">
              <div class="nickNameBox">
                <span>{userInfo && userInfo.nickname}</span>
                {userInfo && userInfo.grade === 0 && (
                  <i
                    class="tag icon-lxmx"
                    onclick={() => (this.isVipRuleShow = true)}
                  />
                )}
                {userInfo && userInfo.grade === 1 && (
                  <i
                    class="tag icon-lxtl"
                    onclick={() => (this.isVipRuleShow = true)}
                  />
                )}
                {userInfo && userInfo.grade === 2 && (
                  <i
                    class="tag icon-lxld"
                    onclick={() => (this.isVipRuleShow = true)}
                  />
                )}
                {userInfo && userInfo.grade === 3 && (
                  <i
                    class="tag icon-lxjx"
                    onclick={() => (this.isVipRuleShow = true)}
                  />
                )}
              </div>
              <div class="description">{userInfo && userInfo.signature}</div>
            </div>
          </div>
          <div class="btnGroup">
            <div class={`btn ${isSign ? "nosign" : "signIn"}`}>
              <i class={`icon ${isSign ? "icon-nosign" : "icon-signIn"}`} />
              <span onclick={this.onSignClick}>
                {isSign ? "已签到" : "签到"}
              </span>
            </div>
            <div class="btn compile">
              <i class="icon icon-compile" />
              <span onclick={this.onEditInfoClick}>编辑个人资料</span>
            </div>
            <div class="btn service">
              <i class="icon icon-service" />
              <a
                target="_bank"
                onclick={this.immediatelyConsult}
                href="https://live800.eiceducation.com.cn/live800/chatClient/chatbox.jsp?companyID=8940&configID=79"
              >
                客服
              </a>
            </div>
          </div>
        </div>
        {this.isVipRuleShow ? (
          <VipRule
            isShow={this.isVipRuleShow}
            closeFunc={() => (this.isVipRuleShow = false)}
          />
        ) : null}
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.myInfoBox {
  width: 100%;
  // height: 148px;
  box-sizing: border-box;
  background: #ffffff;
  padding: 31px 24px 28px 39px;
  border-radius: 16px;
  display: flex;
  align-items: center;

  justify-content: space-between;
  .avatarBox {
    display: flex;

    img {
      width: 89px;
      height: 89px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 22px;
    }
    .avatar-info {
      display: flex;
      flex-direction: column;
      padding-top: 18px;
      .nickNameBox {
        display: flex;
        span {
          font-size: 22px;
          color: #1a1a1a;
          font-weight: 600;
        }
        .tag {
          display: block;
          width: 119px;
          height: 33px;
          background-size: 100%;
          background-repeat: no-repeat;
          margin-left: 11px;
          cursor: pointer;
        }
        .tag.icon-lxtl {
          background-image: url("../../../assets/images/lxtl.png");
        }
        .tag.icon-lxmx {
          width: 103px;
          height: 32px;
          background-image: url("../../../assets/images/lxmx.png");
        }
        .tag.icon-lxld {
          width: 103px;
          height: 32px;
          background-image: url("../../../assets/images/lxld.png");
        }
        .tag.icon-lxjx {
          background-image: url("../../../assets/images/lxjx.png");
        }
      }
      .description {
        width: 316px;
        font-size: 16px;
        margin-top: 10px;
        color: #5c6066;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .btnGroup {
    display: flex;
    .btn {
      display: flex;
      align-items: center;
      padding: 0 16px;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 1px;
        height: 23px;
        background: #c2c6cc;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &:last-child::before {
        display: none;
      }
      span,
      a {
        cursor: pointer;
        font-size: 18px;
        border-radius: 10px;
        padding: 0 17px;
        height: 27px;
        line-height: 27px;
      }
      .icon {
        display: block;
        width: 25px;
        height: 27px;
        background-size: 100%;
        background-repeat: no-repeat;
        margin-right: 12px;
      }
      .icon-signIn {
        background-image: url("../../../assets/images/signin.png");
      }
      .icon-nosign {
        background-image: url("../../../assets/images/nosignin.png");
      }
      .icon-compile {
        background-image: url("../../../assets/images/compile.png");
      }
      .icon-service {
        background-image: url("../../../assets/images/service.png");
      }
    }
    .btn.signIn {
      span {
        background: #fff2eb;
        color: #ff833e;
        font-weight: 600;
      }
    }
    .btn.nosign {
      span {
        background: #f3f3f3;
        color: #adadad;
        font-weight: 600;
      }
    }
    .btn.compile {
      span {
        background: #e5f0fd;
        color: #006eeb;
      }
    }
    .btn.service {
      a {
        background: #feecef;
        color: #f94164;
        text-decoration: none;
      }
    }
  }
}
</style>