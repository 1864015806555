<script>
export default {
  name: "CourseDes",
  props: {
    data: String,
  },
  render() {
    return (
      <div class="desBox">
        <div class="header">
          <div class="title">
            <span class="blue">课程</span>
            <span class="yellow">介绍</span>
          </div>
          <div class="copyrightBox">
            <span>
              <i class="icon-copyright" />
              版权声明
            </span>
            <p>本内容系视频独家，未经授权，不得转载</p>
          </div>
        </div>
        <div class="body" domPropsInnerHTML={this.data} />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.desBox {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 24px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 22px;
      font-weight: bold;
      .blue {
        color: #006eeb;
      }
      .yellow {
        color: #ff833e;
      }
    }
    .copyrightBox {
      display: flex;
      align-items: center;
      span {
        background: #ffe2e8;
        display: flex;
        align-items: center;
        line-height: 25px;
        padding: 0 12px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        font-size: 12px;
        font-weight: 500;
        color: #f94164;
        .icon-copyright {
          display: block;
          width: 16px;
          height: 14px;
          background-image: url("../../../assets/images/quanyi@2x.png");
          background-size: 100%;
          margin-right: 8px;
          background-repeat: no-repeat;
        }
      }
      p {
        background: #eff1f3;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        line-height: 25px;
        font-size: 12px;
        font-weight: 400;
        color: #7a8089;
        padding-left: 20px;
        padding-right: 28px;
      }
    }
  }
  .body {
    margin-top: 27px;
    p {
      font-size: 16px;
      font-weight: 400;
      color: #5c6066;
      line-height: 26px;
    }
  }
}
</style>