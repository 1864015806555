<script>
export default {
  name: "modal",
  props: {
    show: Boolean,
    data: Object,
    close: {
      type: Function,
    },
  },
  data() {
    return {
      list: null,
    };
  },
  async mounted() {
    try {
      const res = await this.$store.dispatch("MyModel/getOrderExpress", {
        number: this.data.number,
      });
      this.list = res.results;
    } catch (msg) {
      // this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  methods: {
    copy(id) {
      const spanText = document.getElementById(id).innerText;
      const oInput = document.createElement("input");
      oInput.value = spanText;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$message({
        showClose: true,
        message: "复制成功！",
        type: "success",
      });
    },
  },
  render() {
    return (
      <div class="shadow" style={{ display: "flex" }}>
        <div class="main">
          {/*   <h3>
            <span class="active">开始处理</span>/<span>已寄出</span>/
            <span>派送中</span>/<span>已送达</span>
          </h3>*/}
          <div class="info-box">
            <img src={this.data.coverPhoto} />
            <div class="content">
              <p class="title">{this.data.title}</p>
              <div class="number">
                <span class="num" id="copy">
                  {this.data.number}
                </span>
                <span class="btn" onclick={()=>this.copy("copy")}>复制</span>
              </div>
            </div>
          </div>

          {this.list ? (
            <div class="list-box">
              <div class="item start">
                <p class="title">已完成</p>
                <p class="des">这里是该步骤的描述信息</p>
              </div>
              <div class="item">
                <p class="title">02-19 18:55</p>
                <p class="des">山东苍山县公司已揽收</p>
              </div>
              <div class="item disabled">
                <p class="title">02-19 18:55</p>
                <p class="des">山东苍山县公司已揽收</p>
              </div>
              <div class="item end disabled">
                <p class="title">EmperLi</p>
                <p class="des">北京朝阳SOHO 1501</p>
              </div>
            </div>
          ) : (
            <div class="null">暂无物流信息</div>
          )}

          <div class="btn-box">
            <i class="icon close" onclick={this.close}></i>
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  .main {
    width: 665px;
    background: #ffffff;
    border-radius: 8px;
    padding: 0 76px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .null {
      text-align: center;
    }
    h3 {
      font-size: 32px;
      font-weight: 600;
      margin-top: 84px;
      span {
        color: #1a1a1a;
      }
      span.active {
        color: #006eeb;
      }
    }
    .info-box {
      margin-top: 35px;
      display: flex;
      margin-bottom: 50px;

      img {
        width: 140px;
        height: 90px;
        border-radius: 8px;
        object-fit: cover;
      }
      .content {
        width: 100%;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        padding-top: 5px;
        .title {
          font-size: 30px;
          font-weight: 400;
          color: #1a1a1a;
        }
        .number {
          margin-top: 5px;
          display: flex;
          justify-content: space-between;
          .num {
            font-size: 22px;
            font-weight: 400;
            color: #a4aab3;
          }
          .btn {
            cursor: pointer;
            display: block;
            height: 30px;
            padding: 0 15px;
            line-height: 30px;
            font-size: 20px;
            font-weight: 400;
            color: #1a1a1a;
            background: #edf0f5;
            border-radius: 8px;
          }
        }
      }
    }
    .list-box {
      height: 383px;
      overflow-y: scroll;
      padding-left: 159px;
      display: flex;
      margin-bottom: 59px;
      flex-direction: column;
      .item {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-bottom: 70px;
        &::after {
          display: block;
          content: "";
          background: #e6e6e6;
          position: absolute;
          height: 100%;
          left: -78px;
          width: 2px;
        }
        &::before {
          position: absolute;
          left: -85px;
          content: "";
          display: block;
          width: 17px;
          height: 17px;
          background: #0086ff;
          border-radius: 50%;
          top: 10px;
          z-index: 999;
        }
        .title {
          font-size: 24px;
          font-weight: 400;
          color: #1a1a1a;
          margin-bottom: 5px;
        }
        .des {
          font-size: 26px;
          font-weight: 400;
          color: #a4aab3;
        }
      }
      .item.disabled {
        .title {
          color: #a4aab3;
        }
        .des {
          color: #a4aab3;
        }
        &::before {
          position: absolute;
          left: -85px;
          content: "";
          display: block;
          width: 17px;
          height: 17px;
          background: #e7e7e7;
          border-radius: 50%;
          top: 10px;
          z-index: 999;
        }
      }
      .item.start {
        &::before {
          position: absolute;
          left: -100px;
          content: "寄";
          display: block;
          width: 47px;
          height: 47px;
          background: #0086ff;
          color: #ffffff;
          font-size: 24px;
          text-align: center;
          line-height: 47px;
          border-radius: 50%;
          top: 0;
        }
      }
      .item.end {
        &::before {
          position: absolute;
          left: -100px;
          content: "收";
          display: block;
          width: 47px;
          height: 47px;
          background: #e7e7e7;
          color: #a4aab3;
          font-size: 24px;
          text-align: center;
          line-height: 47px;
          border-radius: 50%;
          top: 0;
        }
        &::after {
          display: none;
        }
      }
    }
    .btn-box {
      display: flex;
      justify-content: center;
      padding-bottom: 29px;
      .icon {
        display: block;
        background-size: 100%;
        background-repeat: no-repeat;
        cursor: pointer;
      }
      .icon.close {
        width: 46px;
        height: 46px;
        background-image: url("../../../assets/images/pay-close.png");
      }
    }
  }
}
</style>