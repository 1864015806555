<script>
export default {
  name: "TeacherItem",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="teacher-item">
        <img src={this.data.coverPhoto} />
        <div class="content-box">
          <div class="info-box">
            <p class="title">{this.data.name}</p>
            <p class="des">{this.data.abstract}</p>
            {/* <p class="content">
              启德资深五星讲师，6年工作经验。长春理工大学硕士研究生，专业外国语言文学(英语)，属于一级学科。专业英语一次性过四、八级(65分)，有高级教师资格证书，获教育部CATTI笔译三级证书。
            </p>*/}
          </div>
          <div class="btnBox">
            <span
              class="btn"
              onclick={() =>
                this.$router.push({
                  path: `/teacher/detail?id=${this.data.id}`,
                })
              }
            >
              名师详情
            </span>
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.teacher-item {
  display: flex;
  padding: 16px;
  background: #f4f9ff;
  margin-bottom: 24px;
  border-radius: 16px;
  img {
    width: 124px;
    height: 154px;
    object-fit: cover;
  }
  .content-box {
    width: 100%;
    display: flex;
    margin-left: 17px;
    margin-bottom: 10px;
    justify-content: space-between;
    .info-box {
      width: 442px;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 20px;
        font-weight: 400;
        color: #262626;
      }
      .des {
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        margin-top: 9px;
      }
      .content {
        font-size: 14px;
        font-weight: 400;
        color: #8a9099;
        margin-top: 20px;
        line-height: 20px;
      }
    }
    .btnBox {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .btn {
        min-width: 116px;
        padding: 0 20px;
        box-sizing: border-box;
        height: 30px;
        background: #066eeb;
        border-radius: 8px;
        line-height: 30px;
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        color: #ffffff;
        &::after {
          content: ">";
          margin-left: 5px;
        }
      }
    }
  }
}
</style>