<script>
export default {
  name: "recentItem",
  props: {
    data: Object,
    mouseover: {
      type: Function,
    },
    activeid:Number
  },
  render() {
    return (
      <div
        class={`recentLive-item  ${this.data.id === this.activeid?"active":""}`}
        onmouseover={()=>this.mouseover(this.data)}
      >
        <span class="date">
          {this.$moment.unix(this.data.liveTime).format("MM-DD")}
        </span>
        <span class="time">
          {this.$moment.unix(this.data.liveTime).format("HH:mm")}
        </span>
        <span class="text">{this.data.name}</span>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.recentLive-item {
  background: #f0f7ff;
  border-radius: 16px;
  min-height: 46px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  position: relative;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  .date {
    width: 48px;
    height: 20px;
    background: #006eeb;
    border-radius: 6px;
    margin-right: 10px;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    line-height: 20px;
  }
  .time {
    font-size: 18px;
    font-weight: 400;
    color: #006eeb;
    margin-right: 10px;
  }
  .text {
    width: 418px;
    font-size: 16px;
    font-weight: 400;
    color: #262626;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.recentLive-item.active {
  background: #006eeb;
  &::before {
    content: "";
    display: block;
    width: 10px;
    height: 18px;
    background-image: url("../../../assets/images/icon-triangle-blue.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    left: -16px;
  }
  .date {
    background: #ffffff;
    color: #006eeb;
  }
  .time {
    color: #ffffff;
  }
  .text {
    color: #ffffff;
  }
}
</style>