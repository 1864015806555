<script>
import SearchArea from "./SearchArea";
import Pagination from "@components/Pagination.vue";
import BreadCrumbs from "@components/BreadCrumbs.vue";
import Page from "./Page";
import Loading from "@components/Loading";
import Item from "./Item";
import { randomNumber,GetQueryString,urldelCode,urlNocanshu } from "../../utils/index.js";
export default {
  name: "LiveList",
  async mounted() {
    window.scrollTo(0, 0);
    if (window.$cookies.get("token")) {
        urldelCode()
    }
    const { categoryId, status } = this.$route.query;
    const { searchData, pageSize } = this.$store.state.LiveList;
    this.$store.commit("LiveList/updateState", {
      searchData: {
        categoryId: categoryId || -1,
        status: Number(status) || -1,
        keyword: "", // 搜索关键字
        speakerType: -1,
        gradeId: -1,
        countryId: -1, // 国家ID
        professionId: -1, // 专业ID
      },
    });

    try {
      await this.$store.dispatch("LiveList/getLiveList", {
        ...searchData,
        page: 1,
        pageSize,
        categoryId,
        status,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
      await this.$store.dispatch("LiveList/getCountryList");
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    try {
      await this.$store.dispatch("LiveList/getEnumList");
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
        await this.$store.dispatch("LiveList/recordAllPage", {
          app:3, 
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pvId:randomNumber(),
          medium: window.$cookies.get("datachannelmed") || '',
          extendurl: window.$cookies.get("dataurl") || '',
          pageurl:urlNocanshu(),
          fullpageurl:window.location.href,
          accountId:window.$cookies.get("accountId") || ''
        });
      } catch (msg) {
        // this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
  },
  render() {
    const {
      isLoadingShow,
      listData,
      count,
      pageSize,
      nowPage,
      searchData,
    } = this.$store.state.LiveList;
    const paginationObj = {
      totalPage: Math.ceil(count / pageSize),
      maxButton: 5,
      currentPage: nowPage,
      pageChange: async (record) => {
        try {
          await this.$store.dispatch("LiveList/getLiveList", {
            page: record,
            pageSize,
            ...searchData,
          });
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      },
    };
    return (
      <div class="container">
        <BreadCrumbs list={this.breadCrumbsList} />
        <SearchArea />
        <Page />
        <div class="list">
          {listData.map((item) => (
            <Item data={item} />
          ))}
          <div class="paginationBox">
            <Pagination paginationObj={paginationObj} />
          </div>
        </div>
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 1100px;
  margin: 0 auto;
  .list {
    background: #ffffff;
    padding: 24px;
    border-radius: 16px;
    margin-bottom: 64px;
    display: flex;
    flex-wrap: wrap;
    .paginationBox {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
</style>