<script>
import BreadCrumbsMy from "@components/BreadCrumbsMy.vue";
export default {
  name: "shopDetail",
  data() {
    return {
      breadCrumbsList: [
        {
          name: "积分商城",
          onclick: () => this.toJump("shop",true),
        },
        {
          name: "商品详情",
        },
      ],
      tabbarData: [
        { name: "商品详情", id: 0 },
        { name: "商品参数", id: 1 },
      ],
      tabActive: 0,
    };
  },
  async mounted() {
    const { integralId } = this.$store.state.MyModel;
    try {
      await this.$store.dispatch("MyModel/getGoodsDetail", {
        id: integralId,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  methods: {
    toJump(state,backToList = false) {
      const { shopListFilter } = this.$store.state.MyModel;
      shopListFilter.shopDetailBack = backToList; //只有商品详情页返回商品列表页的时候，才更新当前页数，保证返回商品所在分页
      this.$store.commit("MyModel/updateState", {
        integralState: state,
      });
    },
    async onTabClick(item) {
      this.tabActive = item.id;
    },
  },
  render() {
    const { integralDetail } = this.$store.state.MyModel;
    //console.log (integralDetail);
    return (
      <div class="box">
        <BreadCrumbsMy list={this.breadCrumbsList} />
        <div class="info-box">
          <img src={integralDetail && integralDetail.coverPhoto} />
          <div class="info">
            <h3>{integralDetail && integralDetail.title}</h3>
            <p>{integralDetail && integralDetail.subTitle}</p>
            <div class="tag-box">
              <span class="tag">
                {integralDetail && integralDetail.scorePrice}积分
              </span>
              库存：{integralDetail && integralDetail.count}
            </div>
          </div>
        </div>
        <div class="detail-box">
          <div class="tabbar">
            {this.tabbarData.map((item) => (
              <span
                onclick={() => this.onTabClick(item)}
                class={`tab-item  ${
                  item.id === this.tabActive ? "active" : ""
                }`}
              >
                {item.name}
              </span>
            ))}
          </div>
          {this.tabActive == 0 ? (
            <div
              class="body"
              domPropsInnerHTML={integralDetail && integralDetail.detail}
            />
          ) : (
            <div class="table">
              {JSON.parse(integralDetail && integralDetail.params).map(
                (item) => (
                  <div class="item">
                    {item.specifications}：{item.desc}
                  </div>
                )
              )}
            </div>
          )}
        </div>
        <div class="btn-box">
          <span class="btn" onclick={() => this.toJump("confirmOrder")}>
            立即兑换
          </span>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.box {
  padding: 24px;
  background: #ffffff;
  margin-left: 23px;
  border-radius: 16px;
  max-width: 882px;
  width: 882px;
  box-sizing: border-box;
  .info-box {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;

    margin-top: 19px;
    img {
      width: 263px;
      height: 168px;
      object-fit: cover;
      border-radius: 8px;
      cursor: pointer;
    }
    .info {
      margin-left: 25px;
      display: flex;
      flex-direction: column;
      padding: 24px 0;
      width: 100%;
      h3 {
        font-size: 18px;
        font-weight: 500;
        color: #000000;
        margin-bottom: 15px;
      }
      .tag-box {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #9ca6b2;
        margin-top: 25px;
        span.tag {
          display: block;
          background: #feecef;
          height: 24px;
          line-height: 24px;
          padding: 0 8px;
          font-weight: 500;
          font-size: 16px;
          color: #f94164;
          border-radius: 8px;
          width: fit-content;
          margin-right: 17px;
        }
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: #9ca6b2;
      }
    }
  }
  .detail-box {
    border-top: 1px solid #dce0e5;
    display: flex;
    flex-direction: column;
    padding-bottom: 111px;

    .tabbar {
      margin: 20px 0;
      .tab-item {
        font-size: 18px;
        font-weight: 400;
        color: #9ca6b2;
        margin-right: 24px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          font-size: 18px;
          font-weight: bold;
          color: #006eeb;
          position: relative;
          &::before {
            content: "";
            display: block;
            width: 30px;
            height: 3px;
            background: #006eeb;
            border-radius: 2px;
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    h3 {
      font-size: 20px;
      font-weight: 400;
      color: #066eeb;
      line-height: 50px;
    }
    .table {
      border: 1px solid #9ca6b2;
      border-bottom: 0;
      border-right: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .item {
        border-right: 1px solid #9ca6b2;
        border-bottom: 1px solid #9ca6b2;
        width: 50%;
        box-sizing: border-box;
        text-align: center;
        height: 30px;
        line-height: 30px;
      }
    }
    .body {
      padding-top: 10px;
      p {
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        color: #5c6066;
        line-height: 26px;
 
      }
    }
  }
  .btn-box {
    display: flex;
    justify-content: center;
    .btn {
      width: 204px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: #f94164;
      font-size: 20px;
      font-weight: 600;
      color: #ffffff;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}
/*富文本图片宽度100%设置，deep用法不影响其他图片，但不支持嵌套使用*/
.body /deep/ img {
        display: inline-block;
        width: 100% !important;
        height: auto !important; /*防止图片变形*/
}
</style>
