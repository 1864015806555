<script>
import BreadCrumbs from "@components/BreadCrumbs.vue";
import ModelBox from "@components/ModelBox";
import Detail from "./detail";
import MainItem from "./mainItem";
import Loading from "@components/Loading";
import Pagination from "@components/Pagination.vue";
import TeacherItem from "./teacherItem";
import { randomNumber,GetQueryString,urldelCode,urlNocanshu } from "../../utils/index.js";
export default {
  name: "teacherDetail",
  data() {
    return {
      tabbarData: {
        list: [
          // 此ID根据枚举接口获取,不可更改
          { id: "11", value: "课程" },
          { id: "10", value: "直播" },
        ],
        nowTab: "11",
      },

      pageSize: 5,
    };
  },
  inject:["reload"],
  async mounted() {
    window.scrollTo(0, 0);
    if (window.$cookies.get("token")) {
        urldelCode()
    }
    const { id } = this.$route.query;
    try {
      await this.$store.dispatch("TeacherDetail/getTeacherDetail", { id });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
      await this.$store.dispatch("TeacherDetail/getRelevantTeacherList", {
        id,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    if (this.tabbarData.nowTab === "11") {
      try {
        await this.$store.dispatch(
          "TeacherDetail/getTeacherRelevancyCourseList",
          { id, page: 1, pageSize: this.pageSize }
        );
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    }

    if (this.tabbarData.nowTab === "10") {
      try {
        await this.$store.dispatch(
          "TeacherDetail/getTeacherRelevancyLiveList",
          { id, page: 1, pageSize: this.pageSize }
        );
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    }
    try {
        await this.$store.dispatch("TeacherDetail/recordAllPage", {
          app:3, 
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pvId:randomNumber(),
          medium: window.$cookies.get("datachannelmed") || '',
          extendurl: window.$cookies.get("dataurl") || '',
          pageurl:urlNocanshu(),
          fullpageurl:window.location.href,
          accountId:window.$cookies.get("accountId") || ''
        });
      } catch (msg) {
        // this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
  },
  methods: {
    async onTabClick(record) {
      const { id } = this.$route.query;
      if (record.id === "11") {
        try {
          await this.$store.dispatch(
            "TeacherDetail/getTeacherRelevancyCourseList",
            { id, page: 1, pageSize: this.pageSize }
          );
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      }

      if (record.id === "10") {
        try {
          await this.$store.dispatch(
            "TeacherDetail/getTeacherRelevancyLiveList",
            { id, page: 1, pageSize: this.pageSize }
          );
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      }
    },
  },
  render() {
    const {
      isLoadingShow,
      detailData,
      courseList,
      courseCount,
      liveList,
      liveCount,
      teacherList,
    } = this.$store.state.TeacherDetail;
    // 课程分页配置
    const CoursePaginationObj = {
      totalPage: Math.ceil(courseCount / this.pageSize),
      maxButton: 4,
      pageChange: (record) => {},
    };

    // 直播分页配置
    const LivePaginationObj = {
      totalPage: Math.ceil(liveCount / this.pageSize),
      maxButton: 4,
      pageChange: (record) => {},
    };

    const LeftModelBoxData = {
      tabbar: this.tabbarData,
      ontabclick: this.onTabClick,
      content: () => (
        <div>
          <div class="lists">
            {this.tabbarData.nowTab === "11"
              ? courseList.map((item) => (
                  <MainItem data={{ ...item, islive: false }} />
                ))
              : null}
            {this.tabbarData.nowTab === "10"
              ? liveList.map((item) => (
                  <MainItem data={{ ...item, islive: true }} />
                ))
              : null}
          </div>
          <div class="paginationBox">
            {this.tabbarData.nowTab === "11" &&
              (courseCount > this.pageSize ? (
                <Pagination paginationObj={CoursePaginationObj} />
              ) : null)}
            {this.tabbarData.nowTab === "10" &&
              (liveCount > this.pageSize ? (
                <Pagination paginationObj={LivePaginationObj} />
              ) : null)}
          </div>
        </div>
      ),
    };

    const ModelBoxData = {
      blue: "相关",
      yellow: "名师",
      more: {
        title: "查看全部",
        onclick: () => {
          this.$router.push({
            path: `/teacher/list`,
          });
        },
      },
      content: () => (
        <div class="lists">
          {teacherList.map((item) => (
            <TeacherItem data={item} />
          ))}
        </div>
      ),
    };

    return (
      <div class="container">
        <BreadCrumbs list={this.breadCrumbsList} />
        {detailData ? <Detail data={detailData} /> : null}
        <div class="main">
          <div class="left">
            <ModelBox data={LeftModelBoxData} />
          </div>
          <div class="right">
            <ModelBox data={ModelBoxData} />
          </div>
        </div>
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 1100px;
  margin: 0 auto;
  .main {
    display: flex;
    justify-content: space-between;
    .left {
      width: 654px;
      max-width: 654px;
      .lists {
        margin-top: 19px;
        margin-bottom: 42px;
      }
    }
    .right {
      width: 422px;
      max-width: 422px;
      .lists {
        margin-top: 19px;
      }
    }
  }
}
.paginationBox {
  display: flex;
  justify-content: center;
}
</style>