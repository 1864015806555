<script>
import teacherItem from "./teacherItem/index";
import ModelBox from "@components/ModelBox";
export default {
  name: "teacherRCD",
  props: {
    list: Array,
  },
  render() {
    const modelBoxData = {
      blue: "名师",
      yellow: "推荐",
      more: {
        title: "全部名师",
        onclick: () => {
          this.$router.push({ name: "teacherList" });
        },
      },
      content: () => (
        <div class="list">
          {this.list.map((item) => {
            return <teacherItem data={item} />;
          })}
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>

<style lang="less" scoped>
.list {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
</style>