<script>
import Item from "../baseItem";
import ModelBox from "@components/ModelBox";
export default {
  name: "hotSchool",
  props: {
    list: Array,
  },
  render() {
    const modelBoxData = {
      blue: "官方认证",
      yellow: "院校",
      more: {
        title: "相关院校",
        onclick: () => this.$router.push({ path: `/university/list?categoryId=1` }),
      },
      content: () => (
        <div class="list">
          {this.list.map((item, index) => index <= 3 && <Item data={item} />)}
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
</style>