<script>
// VIP专区
import ModelBox from "@components/ModelBox";
import BaseItem from "../baseItem.vue";
export default {
  name: "vip",
  props: {
    tabclick: {
      type: Function,
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch("ClassModel/getVipList", {
        page: 1,
        pageSize: 8,
        categoryId: "14",
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  data() {
    return {
      tabbarData: {
        list: [
          // 此ID根据枚举接口获取,不可更改
          { id: "14", value: "离境知识" },
          { id: "15", value: "通关指南" },
          { id: "16", value: "实习求职" },
          { id: "17", value: "付费精品" },
        ],
        nowTab: "14",
      },
    };
  },
  methods: {
    onTabClick(record) {
      this.tabclick(record);
    },
  },
  render() {
    const { vipList } = this.$store.state.ClassModel;
    const modelBoxData = {
      blue: "VIP",
      yellow: "专区",
      tabbar: this.tabbarData,
      ontabclick: this.onTabClick,
      more: {
        title: "查看全部",
        onclick: () =>
          this.$router.push({
            path: "/course/list",
            query: { categoryId: this.tabbarData.nowTab },
          }),
      },
      content: () => (
        <div class="main">
          <div class="list">
            {vipList.map((item) => (
              <BaseItem
                data={{ ...item, jumpUrl: `/course/detail?id=${item.id}` }}
              />
            ))}
          </div>
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}
</style>