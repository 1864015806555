
<script>
export default {
  name: "baseItem",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="base-item">
        <div class="imgBox">
          <img
            src={this.data.coverPhoto}
            onclick={() => {
              this.data.nowTab === "1" &&
                this.$router.push({
                  path: `/course/detail?id=${this.data.id}`,
                });
              this.data.nowTab === "2" &&
                this.$router.push({ path: `/live/detail?id=${this.data.id}` });
            }}
          />
          {/*  <div class="shadow">
            <i class="icon-eye" />
            <p>1000人已观看</p>
          </div>*/}
        </div>
        <div class="content">
          <p>{this.data.name}</p>
          <div class="state-box">
            <div class="state">
              {this.data.nowTab === "1" ? (
                <span class="watch">
                  已观看：
                  {parseInt((this.data.seconds / this.data.videoTimes) * 100)}%
                </span>
              ) : null}
              {this.data.nowTab === "2" && this.data.status === 1 ? (
                <span class="end">未开始</span>
              ) : null}
              {this.data.nowTab === "2" && this.data.status === 2 ? (
                <span class="live">正在直播</span>
              ) : null}
              {this.data.nowTab === "2" && this.data.status === 3 ? (
                <span class="end">已结束</span>
              ) : null}
            </div>
            <span class="time">{this.data.onlineDate}</span>
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.base-item {
  width: 262px;
  margin-right: 24px;
  margin-bottom: 24px;
  .imgBox {
    width: 262px;
    height: 166px;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    .shadow {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 42px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      padding: 17px 0 0px 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
        font-weight: 300;
        color: #ffffff;
      }
      .icon-eye {
        width: 16px;
        height: 13px;
        display: block;
        background-image: url("../../../assets/images/icon-eye.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-right: 8px;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }
  .content {
    p {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
      margin-top: 9px;
      margin-bottom: 13px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      min-height: 43px;
    }
    .state-box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .time {
        font-size: 13px;
        font-weight: 400;
        color: #9ca6b2;
      }
      .state {
        display: flex;
        .watch {
          display: block;
          background: #d9e9fc;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 400;
          color: #006eeb;
          height: 24px;
          line-height: 24px;
          padding: 0 6px;
        }
        .end {
          background: #e5e5e5;
          display: block;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 400;
          color: #9ca6b2;
          height: 24px;
          line-height: 24px;
          padding: 0 6px;
        }
        .live {
          font-size: 14px;
          font-weight: 400;
          color: #f94164;
          display: flex;
          align-items: center;
          &::after {
            content: "";
            display: block;
            width: 12px;
            height: 13px;
            background-image: url("../../../assets/images/live.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-left: 12px;
          }
        }
      }
    }
  }
  &:nth-child(3n) {
    margin-right: 0;
  }
}
</style>