<script>
// 看课指南
import ModelBox from "@components/ModelBox";
import BaseItem from "../baseItem.vue";
export default {
  name: "lookClass",
  props: {
    list: Array,
    tabclick: {
      type: Function,
    },
  },
  data() {
    return {
      tabbarData: {
        list: [
          // 此ID根据枚举接口获取,不可更改
          { id: "139", categoryId: "29", value: "小白攻略" },
          { id: "141", categoryId: "31", value: "留学移民" },
          { id: "142", categoryId: "32", value: "免费留学" },
          { id: "143", categoryId: "33", value: "家长必读" },
        ],
        nowTab: "139",
      },
    };
  },
  methods: {
    onTabClick(record) {
      this.tabclick(record);
    },
  },
  render() {
    const modelBoxData = {
      blue: "看课",
      yellow: "指南",
      tabbar: this.tabbarData,
      ontabclick: this.onTabClick,
      more: {
        title: "查看全部",
        onclick: () => {
          let categoryId;
          this.tabbarData.list.map((item) => {
            if (item.id === this.tabbarData.nowTab) {
              categoryId = item.categoryId;
            }
          });
          this.$router.push({
            path: "/course/list",
            query: { categoryId },
          });
        },
      },
      content: () => (
        <div class="main">
          {this.list.map((item) => (
            <div class="listBox">
              <p>{item.title}</p>
              <div class="list">
                {item.list.map(
                  (item, index) => index <= 3 && <BaseItem data={item} />
                )}
              </div>
            </div>
          ))}
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  .listBox {
    display: flex;
    flex-direction: column;
    margin-top: 13px;
    p {
      height: 48px;
      background: #e8f3ff;
      border-radius: 16px;
      font-size: 22px;
      color: #006eeb;
      font-weight: 400;
      line-height: 48px;
      text-align: center;
    }
    .list {
      display: flex;
      margin-top: 16px;
      flex-wrap: wrap;
    }
  }
}
</style>