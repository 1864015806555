<script>
import BreadCrumbsMy from "@components/BreadCrumbsMy.vue";
import Pagination from "@components/Pagination.vue";
import Modal from "../modal";
import Item from "./item";
import eicdata2empty from "@components/eicdata2empty.vue";
export default {
  name: "orderList",
  data() {
    return {
      breadCrumbsList: [
        {
          name: "积分商城",
          onclick: () => this.onInfoClick(),
        },
        {
          name: "积分订单",
        },
      ],
      pageSize: 5,
      showModal: false,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("MyModel/getShopOrderList", {
        page: 1,
        pageSize: this.pageSize,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  methods: {
    onInfoClick() {
      this.$store.commit("MyModel/updateState", { integralState: "shop" });
    },
  },
  render() {
    const {
      integralShopOrderList,
      integralShopOrderCount,
    } = this.$store.state.MyModel;
    const paginationObj = {
      totalPage: Math.ceil(integralShopOrderCount / this.pageSize),
      maxButton: 5,
      pageChange: async (record) => {
        try {
          await this.$store.dispatch("MyModel/getShopOrderList", {
            page: record,
            pageSize: this.pageSize,
          });
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      },
    };
    return (
      <div class="order-list">
        <BreadCrumbsMy list={this.breadCrumbsList} />
        <div class="list">
          {integralShopOrderList.map((item) => (
            <Item data={item} />
          ))}
        </div>
        {integralShopOrderCount > this.pageSize ? (
          <div class="paginationBox">
            <Pagination paginationObj={paginationObj} />
          </div>
        ) : null}
        {integralShopOrderCount == 0 ? (
            <div>
              <eicdata2empty/>
            </div>
          ) : null}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.order-list {
  padding: 24px;
  background: #ffffff;
  margin-left: 23px;
  border-radius: 16px;
  max-width: 882px;
  width: 882px;
  box-sizing: border-box;
  .list {
    display: flex;
    margin-top: 17px;
    flex-direction: column;
  }
  .paginationBox {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>