<script>
import ModelBoxMy from "@components/ModelBoxMy.vue";
import Loading from "@components/Loading";
import Pagination from "@components/Pagination.vue";
import eicdata2empty from "@components/eicdata2empty.vue";
import Item from "./Item.vue";
export default {
  name: "MyMessage",
  data() {
    return {
      pageSize: 10,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("MyModel/getNoticeList", {
        page: 1,
        pageSize: this.pageSize,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  render() {
    const {
      isLoadingShow,
      noticeListCount,
      noticeList,
    } = this.$store.state.MyModel;
    const paginationObj = {
      totalPage: Math.ceil(noticeListCount / this.pageSize),
      maxButton: 5,
      pageChange: async (record) => {
        try {
          await this.$store.dispatch("MyModel/getNoticeList", {
            page: record,
            pageSize: this.pageSize,
          });
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      },
    };
    const modelData = {
      blue: "我的",
      yellow: "消息",
      // secondTabBar: {
      //   list: [
      //     { id: 0, value: "全部", isActive: true },
      //     { id: 0, value: "本周", isActive: false },
      //     { id: 0, value: "本月", isActive: false },
      //   ],
      // },
      content: () => (
        <div>
          <div class="list">
            {noticeList.map((item) => (
              <Item data={item} />
            ))}
          </div>
          {noticeListCount > this.pageSize ? (
            <div class="paginationBox">
              <Pagination paginationObj={paginationObj} />
            </div>
          ) : null}
          {noticeListCount == 0 ? (
            <div>
              <eicdata2empty/>
            </div>
          ) : null}
          <Loading isShow={isLoadingShow} />
        </div>
      ),
    };
    return <ModelBoxMy data={modelData} />;
  },
};
</script>
<style lang="less" scoped>
.paginationBox {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>