<script>
import AddAddress from "./addAddress/index";
// import BaseInfo from "./baseInfo/index";
import BaseInfo from "./baseInfo";
import UpdatePhone from "./updatePhone/index";
import UpdateEmail from "./updateEmail/index";
export default {
  name: "personInfo",
  render() {
    const {
      updatePhoneShow,
      updateEmailShow,
      infoState,
    } = this.$store.state.MyModel;
    return (
      <div class="Box">
        {infoState === "address" ? <AddAddress /> : null}
        {infoState === "info" ? <BaseInfo /> : null}
        {updatePhoneShow ? <UpdatePhone /> : null}
        {updateEmailShow ? <UpdateEmail /> : null}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
// .Box {
//   padding: 24px;
//   background: #ffffff;
//   margin-left: 23px;
//   border-radius: 16px;
//   max-width: 882px;
//   width: 882px;
//   box-sizing: border-box;
// }
</style>