<script>
export default {
  name: "Null",
  render() {
    return (
      <div class="box">
        <div class="content">
          <i class="icon-null" />
          暂无更多预约
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.box {
  // width: 514px;
  width:100%;
  height: 186px;
  background: #ffffff;
  border: 1px dashed #c7ccd3;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    font-size: 30px;
    color: #d9d9d9;
    display: flex;
    align-items: center;
    .icon-null {
      display: block;
      width: 99px;
      height: 88px;
      background-image: url("../../../assets/images/null.png");
      background-size: 100%;
      background-repeat: no-repeat;
      margin-right: 25px;
    }
  }
}
</style>