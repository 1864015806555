
<script>
import ModelBox from "@components/ModelBox";
import Item from "./item";
export default {
  name: "moreLive",
  props: {
    list: Array,
  },
  render() {
    const modelBoxData = {
      blue: "更多",
      yellow: "直播",
      more: {
        title: "查看全部",
        onclick: () => this.$router.push({ path: "/live/list" }),
      },
      content: () => (
        <div class="list">
          {this.list.map((item) => (
            <Item data={item} />
          ))}
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
</style>