<script>
import ModelBoxMy from "@components/ModelBoxMy.vue";
import Pagination from "@components/Pagination.vue";
export default {
  name: "overlordClass",
  render() {
    const paginationObj = {
      totalPage: 5,
      maxButton: 5,
      pageChange: async (record) => {},
    };
    const modelData = {
      blue: "免费领取",
      yellow: "霸王课",
      content: () => (
        <div>
          <div class="lists">
            {[1, 1, 1, 1, 1, 1].map(() => (
              <div class="item">
                <img src="awewq" />
                <div class="content">
                  <p>24小时学术指导课程</p>
                  <div class="moneyBox">
                    <span class="price">¥ 24999</span>
                    <span class="people">2000人已领取</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div class="paginationBox">
            <Pagination paginationObj={paginationObj} />
          </div>
        </div>
      ),
    };

    return <ModelBoxMy data={modelData} />;
  },
};
</script>
<style lang="less" scoped>
.lists {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;

  .item {
    display: flex;
    flex-direction: column;
    max-width: 405px;
    margin-right: 24px;
    margin-bottom: 24px;
    &:nth-child(2n) {
      margin-right: 0px;
    }
    img {
      width: 405px;
      height: 94px;
      border-radius: 16px;
    }
    .content {
      display: flex;
      align-items: center;
      padding: 0 13px;
      justify-content: space-between;
      p {
        color: #1a1a1a;
        font-size: 16px;
      }
      .moneyBox {
        display: flex;
        align-items: center;
        .price {
          color: #ff833e;
          font-weight: 600;
          font-size: 18px;
        }
        .people {
          margin-left: 13px;
          color: #a4aab3;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
.paginationBox {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>