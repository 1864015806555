<script>
import Shop from "./shop";
import Order from "./order";
import Detail from "./detial";
import ShopDetail from "./shopDetail";
import ConfirmOrder from "./confirmOrder";
import Loading from "@components/Loading";
export default {
  name: "integralShop",
  render() {
    const { integralState,isLoadingShow } = this.$store.state.MyModel;
    return (
      <div class="main-box">
        {integralState === "shop" ? <Shop /> : null}
        {integralState === "order" ? <Order /> : null}
        {integralState === "detail" ? <Detail /> : null}
        {integralState === "shopDetail" ? <ShopDetail /> : null}
        {integralState === "confirmOrder" ? <ConfirmOrder /> : null}
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.main-box {
  max-width: 882px;
  width: 882px;
  box-sizing: border-box;
}

</style>