
<script>
// import Swiper from "swiper";
export default {
  name: "swiper",
  props: {
    list: Array,
  },
  data() {
    return {
      swiper: null,
      activeIndex: null,
    };
  },
  mounted() {
    this.$nextTick(function () {
      // this.swiper = new Swiper(".swiper-container", {
      //   loop: true,
      //   autoplay: 5000,
      //   speed: 500,
      //   onSlideChangeEnd: (swiper) => {
      //     this.activeIndex = swiper.activeIndex;
      //   },
      // });
    });
  },
  methods: {
    toUrl(url) {
      var expression=/http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
      var objExp=new RegExp(expression);
      if (objExp.test(url)) {
        window.location.href = url;
        return;
      }
      this.$router.push({ path: url });
    },
  },
  render() {
    return (
      <div class="about">
        <el-carousel trigger="click" height="400px">
          {this.list.map((item) => (
            <el-carousel-item>
              <img
                src={item.imageUrl}
                onclick={() => this.toUrl(item.jumpUrl)}
              />
            </el-carousel-item>
          ))}
        </el-carousel>
        {/* <div class="swiper-container">
          <div class="swiper-wrapper">
            {this.list.map((item) => (
              <div class="swiper-slide">
                <img
                  src={item.imageUrl}
                  style={{ width: "100%" }}
                  onclick={() => this.toUrl(item.jumpUrl)}
                />
              </div>
            ))}
          </div>
          <div class="swiper-pagination-box">
            {this.list.map((item, index) => (
              <i
                class={`item ${this.activeIndex === index ? "active" : ""}`}
                onclick={() => {
                  this.swiper.slideTo(index, 500, false);
                }}
              />
            ))}
          </div>
        </div>
         */}
      </div>
    );
  },
};
</script>

<style lang="less">
// .swiper-container {
//   height: 400px;
//   width: 100%;
//   margin-bottom: 32px;
//   border-radius: 16px;
//   .swiper-wrapper {
//     .swiper-slide {
//       cursor: pointer;
//       width: 100%;
//       height: 100%;
//       text-align: center;
//       line-height: 400px;
//       img {
//         width: 100%;
//         height: 100%;
//         object-fit: contain;
//         background: #000000;
//       }
//     }
//   }
//   .swiper-pagination-box {
//     display: flex;
//     position: absolute;
//     bottom: 16px;
//     left: 50%;
//     transform: translateX(-50%);
//     z-index: 1;
//     .item {
//       display: block;
//       width: 10px;
//       height: 10px;
//       background-color: rgba(255, 255, 255, 0.5);
//       cursor: pointer;
//       border-radius: 50%;
//       margin-right: 15px;
//     }
//     .item.active {
//       background: #ffffff;
//     }
//   }
// }
.el-carousel {
  margin-bottom: 32px;
  border-radius: 16px;
  .el-carousel__container {
    height: 400px;
    width: 100%;
    .el-carousel__item {
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .el-carousel__arrow {
      display: none !important;
    }
  }
}
.el-carousel__indicator.is-active {
  .el-carousel__button {
    background: #ffffff;
  }
}
.el-carousel__indicator {
  .el-carousel__button {
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.el-carousel__indicators {
  display: block !important;
}
</style>