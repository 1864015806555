<script>
import ModelBoxMy from "@components/ModelBoxMy.vue";
import IntegralRule from "@components/IntegralRule.vue";
export default {
  name: "myIntegral",
  data() {
    return {
      isShowRule: false,
      nowTab: 1,
      tabbarData: [
        { name: "积分获取", value: 1 },
        { name: "积分记录", value: 2 },
      ],
    };
  },
  async mounted() {
    const { tab } = this.$route.query;
    this.nowTab = Number(tab) || 1;
    this.getTaskList();
    this.getScoreRecord();
    this.getUserInfo();
  },
  methods: {
    async getScoreRecord() {
      try {
        await this.$store.dispatch("MyModel/getScoreRecordList");
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
    // 获取用户信息
    async getUserInfo() {
      try {
        await this.$store.dispatch("MyModel/getUserInfo");
      } catch (error) {
          if (error.code == 1016) {
            this.$message({ showClose: true, message: error.msg, type: "error" });
            window.$cookies.set("token",'')
          }
          return;
        }
    },

    // 获取任务列表
    async getTaskList() {
      try {
        await this.$store.dispatch("MyModel/getTaskList");
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
    jumpClick(record) {
      this.$router.push({ path: record.jumpUrl_PC });
    },
    async receiveIntegral(record) {
      let msg;
      try {
        msg = await this.$store.dispatch("MyModel/getTaskScore", {
          id: record.id,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.$message({ showClose: true, message: msg, type: "success" });
      this.getTaskList();
      this.getUserInfo();
    },
  },
  render() {
    const { taskList, userInfo, scoreRecordList } = this.$store.state.MyModel;
    const modelData = {
      blue: "我的",
      yellow: "积分",
      content: () => (
        <div class="my-integral">
          <div class="info-box">
            <div class="left">
              <p class="label">我的积分</p>
              <p class="value">{userInfo && userInfo.score}</p>
            </div>
            <i class="icon money" />
          </div>

          <div class="list-box">
            <div class="title-box">
              <div class="tabbar">
                {this.tabbarData.map((item) => (
                  <p
                    class={`${item.value === this.nowTab ? "active" : ""}`}
                    onclick={() => (this.nowTab = item.value)}
                  >
                    {item.name}
                  </p>
                ))}
              </div>
              <span onclick={() => (this.isShowRule = true)}>积分规则</span>
            </div>
            {this.nowTab === 1 ? (
              <div class="list">
                {taskList.map((item) => (
                  <div class="taskline">
                    <div class="left">
                      <span class="num">+{item.score}</span>
                      <div class="info">
                        <h3>{item.name}</h3>
                        <div class="progress">
                          <el-progress
                            style={{ width: "100%" }}
                            percentage={(item.completedTime / item.time) * 100}
                          />
                          {item.completedTime}/{item.time}
                        </div>
                        {/* <p>
                        点击签到按钮即可以签到领取积分每天签到一次可以领取5分
                      </p> */}
                      </div>
                    </div>
                    {!item.redeemScore ? (
                      item.completedTime === item.time ? (
                        <span class="btn gray">已领取</span>
                      ) : (
                        <span
                          class="btn blue2"
                          onclick={() => this.jumpClick(item)}
                        >
                          立即前往
                        </span>
                      )
                    ) : null}
                    {item.redeemScore ? (
                      <span
                        class="btn blue1"
                        onclick={() => this.receiveIntegral(item)}
                      >
                        立即领取
                      </span>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : null}
            {this.nowTab === 2 ? (
              <div class="list">
                {scoreRecordList.map((item) => (
                  <div class="line">
                    <div class="left">
                      <span class={`num ${item.type === 1 ? "add" : ""}`}>
                        {item.type === 1 && "+"}
                        {item.type === 2 && "-"}
                        {item.score}
                      </span>
                      <p>{item.name}</p>
                    </div>
                    <span>{item.createTime}</span>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      ),
    };
    return (
      <div>
        <ModelBoxMy data={modelData} />
        <IntegralRule
          isShow={this.isShowRule}
          closeFunc={() => (this.isShowRule = false)}
        />
      </div>
    );
  },
};
</script>

<style lang="less" scoped>
.my-integral {
  margin-top: 24px;
  .info-box {
    width: 100%;
    height: 120px;
    background-image: url("../../../assets/images/integralBG2.png");
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    padding: 0px 122px 0px 30px;
    box-sizing: border-box;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      padding-left: 30px;
      .label {
        font-size: 24px;
        font-weight: 600;
        color: #fefefe;
        margin-right: 28px;
      }
      .value {
        font-size: 40px;
        font-weight: 500;
        color: #fefefe;
      }
    }
    .icon.money {
      width: 117px;
      height: 90px;
      background-image: url("../../../assets/images/money2.png");
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
  .title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;

    h3 {
      font-size: 20px;
      font-weight: 500;
      color: #000000;
    }
    span {
      font-size: 18px;
      font-weight: 500;
      color: #006eeb;
      text-decoration: underline;
      cursor: pointer;
    }
    .tabbar {
      display: flex;
      p {
        color: #9ca6b2;
        font-size: 20px;
        font-weight: 400;
        margin-right: 30px;
        cursor: pointer;
      }
      p.active {
        font-weight: 500;
        color: #006eeb;
        position: relative;
        &::after {
          content: "";
          display: block;
          width: 30px;
          height: 3px;
          position: absolute;
          background: #006eeb;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  .list-box {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    .list {
      margin-top: 20px;
      .taskline {
        display: flex;
        background: #f3f6fa;
        justify-content: space-between;
        border-radius: 20px;
        padding: 0 30px;
        height: 104px;
        align-items: center;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .left {
          display: flex;
          align-items: center;
          .num {
            display: flex;
            width: 86px;
            align-items: center;
            justify-content: center;
            background: #cfe2f8;
            height: 66px;
            font-size: 26px;
            font-weight: 600;
            color: #006eeb;
            border-radius: 8px;
            margin-right: 23px;
          }
          .info {
            display: flex;
            flex-direction: column;
            h3 {
              font-size: 20px;
              font-weight: 600;
              color: #1a1a1a;
            }
            .progress {
              width: 424px;
              display: flex;
              align-items: center;
              font-size: 18px;
              font-weight: 600;
              color: #1a1a1a;
            }
            P {
              font-size: 14px;
              color: #1a1a1a;
              font-weight: 400;
            }
          }
        }
        .btn {
          cursor: pointer;
          display: block;
          width: 108px;
          height: 42px;
          border-radius: 20px;
          line-height: 42px;
          text-align: center;
        }

        .btn.blue1 {
          background: #006eeb;
          color: #ffffff;
        }
        .btn.gray {
          background: #d5d5d5;
          color: #ffffff;
        }
        .btn.blue2 {
          background: #cfe2f8;
          color: #006eeb;
        }
      }
      .line {
        display: flex;
        background: #f3f6fa;
        justify-content: space-between;
        border-radius: 20px;
        padding: 0 30px;
        height: 104px;
        align-items: center;
        margin-bottom: 20px;
        .left {
          display: flex;
          align-items: center;
          .num {
            display: flex;
            width: 86px;
            align-items: center;
            justify-content: center;
            background: #f4dbe4;
            height: 66px;
            font-size: 26px;
            font-weight: 600;
            color: #f94164;

            border-radius: 8px;
            margin-right: 23px;
          }
          .num.add {
            background: #cfe2f8;
            color: #006eeb;
          }
          p {
            font-size: 20px;
            color: #1a1a1a;
            font-weight: 600;
          }
        }
        span {
          color: #8a9099;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}
</style>