<script>
// 热门直播
import ModelBox from "@components/ModelBox";
import Item from "./Item.vue";
export default {
  name: "hotLive",
  props: {
    list: Array,
    tabclick: {
      type: Function,
    },
  },
  data() {
    return {
      tabbarData: {
        list: [
          // 此ID根据枚举接口获取,不可更改
          { id: "1", value: "留学目的地" },
          { id: "2", value: "留学专业" },
          { id: "3", value: "申请阶段" },
          { id: "4", value: "附近直播" },
        ],
        nowTab: "1",
      },
    };
  },
  methods: {
    onTabClick(record) {
      this.tabclick(record);
    },
  },
  render() {
    const modelBoxData = {
      blue: "热门",
      yellow: "直播",
      more: {
        title: "查看全部",
        onclick: () => {
          this.$router.push({ path: "/live/list" });
        },
      },
      tabbar: this.tabbarData,
      ontabclick: this.onTabClick,
      content: () => (
        <div class="main">
          {this.list.map((item) => (
            <Item data={item} />
          ))}
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.main {
  display: flex;
  flex-wrap: wrap;
  margin-top: 13px;
}
</style>