
<script>
import Loading from "@components/Loading";
export default {
  name: "updatePhone",
  props: {
    toggleUpdatePhone: {
      type: Function,
    },
  },
  data() {
    return {
      isPhoneError: false,
      isAuthCodeClick: false, // 是否点击获取验证码
      time: 60, // 重新获取time
      formData: {
        countryCode: "",
        mobile: "",
        type: "updMobile",
        code: "", // 验证码
      },
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("LoginModel/getCountryCodeList");
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    const { countryCodeList } = this.$store.state.LoginModel;
    this.formData.countryCode = countryCodeList[0].code;
  },
  methods: {
    onClose() {
      this.$store.commit("MyModel/updateState", { updatePhoneShow: false });
    },
    async onSave() {
      const { baseInfoForm } = this.$store.state.MyModel;
      let msg;
      try {
        await this.$store.dispatch("MyModel/upUserMobile", this.formData);
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.$message({ showClose: true, message: msg, type: "success" });

      this.$store.commit("MyModel/updateState", {
        baseInfoForm: {
          ...baseInfoForm,
          mobile: this.formData.mobile,
        },
      });
      this.onClose();
    },
    // 区号选择事件
    onSelectChagne(e) {
      const { value } = e.target;
      this.formData.countryCode = value;
      this.areaCode = e.target.value;
    },

    // 手机号输入事件
    onPhoneInput(e) {
      e.target.value = e.target.value.replace(/[^\d]/g, "");
      const { value } = e.target;
      this.phone = e.target.value;
      this.formData.mobile = value;
      if (value !== "") {
        this.isPhoneError = false;
        return;
      }
      // this.isPhoneError = !/^1(3|4|5|6|7|8|9)\d{9}$/.test(value);
    },

    // 输入验证码
    onCodeInput(e) {
      const { value } = e.target;
      this.formData.code = value;
    },

		// 点击获取验证码
		async onAuthCodeClick() {
			if (!this.formData.mobile) return (this.isPhoneError = true);

			this.getGraphicVerification(this.handlerVerify)
		},

		async handlerVerify(ev) {
			console.log(ev.detail, "ev.detail")
			if (ev.detail.ret === 0) {
				// 获取验证码
				try {
					await this.$store.dispatch("LoginModel/sendCode", {
						...this.formData,
						ticket: ev.detail.ticket,
						randstr: ev.detail.randstr,
						clentType: 2,
					});
				} catch (msg) {
					this.$message({
						showClose: true,
						message: msg,
						type: "error"
					});
					return;
				}

				this.isAuthCodeClick = true;

				const timer = setInterval(() => {
					if (this.time === 1) {
						this.isAuthCodeClick = false;
						this.time = 60;
						clearInterval(timer);
					} else {
						this.time = this.time - 1;
					}
				}, 1000);
			} else {
				// 验证失败
				// 请不要在验证失败中调用refresh，验证码内部会进行相应处理
			}
		},
  },
  render() {
    const { countryCodeList, isLoadingShow } = this.$store.state.LoginModel;
    return (
      <div class="updatePhoneBox">
        <div class="container">
          <i class="icon-close" onclick={this.onClose} />
          <div class="title">更换手机号</div>
          <div class="formBox">
            <div class={`form-item ${this.isPhoneError ? "error" : ""}`}>
              <select onchange={(e) => this.onSelectChagne(e)}>
                {countryCodeList.map((item) => (
                  <option value={item.code}>+{item.code}</option>
                ))}
              </select>
              <div class="input-box">
                <input
                  placeholder="请输入新的手机号"
                  oninput={this.onPhoneInput}
                />
                {this.isPhoneError ? (
                  <span class="error">
                    <i class="icon info" />
                    手机号不能为空
                  </span>
                ) : null}
              </div>
            </div>
            <div class="verificationBox">
              <div class="inputBox">
                <input placeholder="请输入验证码" oninput={this.onCodeInput} />
              </div>

              {this.isAuthCodeClick ? (
                <span class="btn gray">重新获取({this.time})</span>
              ) : (
                <span class="btn" onclick={this.onAuthCodeClick}>
                  获取验证码
                </span>
              )}
            </div>
            <div class="btngroup">
              <span class="save" onclick={this.onSave}>
                确认
              </span>
            </div>
          </div>
        </div>
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.updatePhoneBox {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  .container {
    position: relative;
    padding: 0 37px;
    background: #ffffff;
    width: 690px;
    border-radius: 10px;
    box-sizing: border-box;
    .icon-close {
      display: block;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      background-color: #edf0f5;
      background-image: url("../../../../assets/images/icon-close.png");
      background-size: 18px 18px;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      right: 25px;
      top: 25px;
      cursor: pointer;
    }
    .title {
      margin-top: 66px;
      font-weight: 600;
      color: #1a1a1a;
      font-size: 36px;
      text-align: center;
    }
    .formBox {
      margin-top: 47px;
      .form-item {
        width: 616px;
        height: 62px;
        background: #edf0f5;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 24px;
        border-radius: 10px;
        box-sizing: border-box;
        select {
          border: 0;
          font-size: 22px;
          color: #1a1a1a;
          background: none;
          outline: none;
        }
        .input-box {
          display: flex;
          justify-content: space-between;
          input {
            border: 0;
            outline: none;
            background: none;
            font-size: 22px;
            color: #a4aab3;
            padding-left: 50px;
            line-height: 62px;
            width: 100%;
          }
          .error {
            display: flex;
            width: 100%;
            align-items: center;
            font-size: 22px;
            font-weight: 400;
            color: #f84164;
            justify-content: flex-end;
            .icon.info {
              width: 25px;
              height: 25px;
              min-width: 25px;
              background-image: url("../../../../assets/images/error-info.png");
              margin-right: 4px;
            }
          }
        }
      }
      .form-item.error {
        background: #fee3e8;
        input {
          color: #f94063;
        }
        span.error {
          color: #f84164;
        }
      }
      .verificationBox {
        margin-top: 23px;
        width: 616px;
        display: flex;
        .inputBox {
          width: 405px;
          background: #edf0f5;
          height: 62px;
          display: flex;
          align-items: center;
          padding: 0 15px;
          border-radius: 10px;
          input {
            border: 0;
            outline: none;
            background: none;
            font-size: 22px;
            color: #a4aab3;
            line-height: 62px;
          }
        }
        .btn {
          width: 200px;
          height: 62px;
          background: #d9e9fc;
          border-radius: 10px;
          line-height: 62px;
          margin-left: 10px;
          color: #006eeb;
          font-weight: 500;
          font-size: 22px;
          text-align: center;
          cursor: pointer;
        }
        .btn.gray {
          color: #a4aab3;
          pointer-events: none;
        }
      }

      .btngroup {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        padding-bottom: 62px;
        span.save {
          display: block;
          width: 204px;
          height: 64px;
          background: #006eeb;
          border-radius: 10px;
          font-size: 22px;
          color: #ffffff;
          font-weight: 600;
          line-height: 64px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>