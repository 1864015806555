<script>
import Loading from "@components/Loading";
import BreadCrumbsMy from "@components/BreadCrumbsMy.vue";
import VDistpicker from "v-distpicker";
export default {
  name: "addAddress",
  components: { VDistpicker },
  data() {
    return {
      breadCrumbsList: [
        {
          name: "个人信息",
          onclick: () => this.onInfoClick(),
        },
        {
          name: "管理收货地址",
        },
      ],
      isShowPicker: false,
      isAreaError: false, // 所在区域Error
      isAddressError: false, // 详细地址error
      isNameError: false, // 收货人error
      isPhoneError: false, // 手机号error
      isPhoneErrorText: "",
      formData: {
        region: "", //所在区域
        address: "", //详细地址
        realName: "", // 真实姓名
        mobile: "", // 手机号
        default: 0, //是否是默认地址
      },
    };
  },
  async mounted() {
    this.getAddressList();
  },
  methods: {
    async getAddressList() {
      try {
        await this.$store.dispatch("MyModel/getUserAddressList");
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
    onCheckClick() {
      this.formData = {
        ...this.formData,
        default: Number(!this.formData.default),
      };
    },
    onInfoClick() {
      this.$store.commit("MyModel/updateState", { infoState: "info" });
    },
    async saveAddress() {
      if (!this.formData.region) this.isAreaError = true;
      if (!this.formData.address) this.isAddressError = true;
      if (!this.formData.realName) this.isNameError = true;
      if (!this.formData.mobile) this.isPhoneError = true;
      if (
        this.formData.region &&
        this.formData.address &&
        this.formData.realName &&
        this.formData.mobile
      ) {
        let msg;
        try {
          msg = await this.$store.dispatch(
            "MyModel/addOrUpUserAddress",
            this.formData
          );
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
        this.$message({ showClose: true, message: msg, type: "success" });
        this.resetForm();
        this.getAddressList();
      }
    },
    updateClick(record) {
      this.formData = record;
      this.getAddressList();
    },
    async deleteClick(record) {
      let msg;
      try {
        msg = await this.$store.dispatch("MyModel/delUserAddress", {
          id: record.id,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.$message({ showClose: true, message: msg, type: "success" });

      this.getAddressList();
      this.resetForm();
    },
    // 修改默认地址
    async updateDefault(record) {
      const params = { ...record, default: Number(!record.default) };
      try {
        await this.$store.dispatch("MyModel/addOrUpUserAddress", params);
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.getAddressList();
    },
    phoneInput(e) {
      // e.target.value = e.target.value.replace(/[^\d]/g, "");
      const { value } = e.target;
      this.formData = {
        ...this.formData,
        mobile: value,
      };
      // this.isPhoneError = !/^1(3|4|5|6|7|8|9)\d{9}$/.test(value);
      this.isPhoneErrorText = this.isPhoneError ? "手机号格式错误" : "";
      if (value === "") {
        this.isPhoneError = false;
      }
    },
    resetForm() {
      this.formData = {
        region: "", //所在区域
        address: "", //详细地址
        realName: "", // 真实姓名
        mobile: "", // 手机号
        default: 0, //是否是默认地址
      };
    },

    onSelectPicker(data) {
      this.formData.region = `${data.province.value} / ${data.city.value} / ${data.area.value}`;
      this.isShowPicker = false;
      this.isAreaError = false;
    },
  },
  render() {
    const { userAddressList, isLoadingShow } = this.$store.state.MyModel;
    return (
      <div class="addressBox">
        <BreadCrumbsMy list={this.breadCrumbsList} />
        <div class="main">
          <div class="addAddressBox">
            <div class="titleBox">
              <p class="title">新增收获地址</p>
              <p class="tip">仅支持国内（除港澳台）寄送地址</p>
            </div>
            <div class="formBox">
              <div class="form-item">
                <div class="label required">所在区域</div>
                <div class={`inputBox ${this.isShowPicker ? "picker" : ""}`}>
                  {!this.isShowPicker ? (
                    <div class="error-box">
                      <input
                        placeholder="选择所在省/市/区"
                        onclick={() => (this.isShowPicker = true)}
                        value={this.formData.region}
                      />
                      {this.isAreaError ? (
                        <span class="error">
                          <i class="icon info" />
                          所在区域不能为空
                        </span>
                      ) : null}
                    </div>
                  ) : (
                    <v-distpicker
                      type="mobile"
                      onselected={this.onSelectPicker}
                    />
                  )}
                </div>
              </div>
              <div class="form-item">
                <div class="label required">详细地址</div>
                <div class="inputBox">
                  <div class="error-box">
                    <input
                      placeholder="请输入详细地址"
                      maxLength="50"
                      oninput={(e) => {
                        this.isAddressError = !e.target.value;
                        this.formData = {
                          ...this.formData,
                          address: e.target.value,
                        };
                      }}
                      value={this.formData.address}
                    />
                    {this.isAddressError ? (
                      <span class="error">
                        <i class="icon info" />
                        详细地址不能为空
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* <div class="form-item">
                <div class="label">邮政编码</div>
                <div class="inputBox">
                  <input placeholder="请填写邮编" />
                </div>
              </div>
               */}
              <div class="form-item">
                <div class="label required">收货人</div>
                <div class="inputBox">
                  <div class="error-box">
                    <input
                      maxLength="10"
                      placeholder="请填写收货人姓名"
                      oninput={(e) => {
                        this.isNameError = !e.target.value;
                        this.formData = {
                          ...this.formData,
                          realName: e.target.value,
                        };
                      }}
                      value={this.formData.realName}
                    />
                    {this.isNameError ? (
                      <span class="error">
                        <i class="icon info" />
                        收货人不能为空
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div class="form-item">
                <div class="label required">手机号码</div>
                <div class="inputBox">
                  <div class="error-box">
                    <input
                      placeholder="请输入手机号"
                      oninput={this.phoneInput}
                      value={this.formData.mobile}
                    />
                    {this.isPhoneError ? (
                      <span class="error">
                        <i class="icon info" />
                        {this.isPhoneErrorText}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div class="setDefaultBox" onclick={this.onCheckClick}>
              <i class={`icon-check ${this.formData.default ? "" : "no"}`} />
              设置为默认收货地址
            </div>
            <div class="btnBox">
              <span class="save" onclick={this.saveAddress}>
                保存
              </span>
            </div>
          </div>
          <div class="tableBox">
            <p>已保存{userAddressList.length}条地址</p>

            <table class="pure-table">
              <thead>
                <tr>
                  <th>收货人</th>
                  <th>所在区域</th>
                  <th>详细地址</th>
                  {/*  <th>邮编</th>*/}
                  <th>手机</th>
                  <th style={{ width: "120px" }}>操作</th>
                  <th style={{ width: "100px" }}>地址状态</th>
                </tr>
              </thead>
              <tbody>
                {userAddressList.map((item) => (
                  <tr>
                    <td>{item.realName}</td>
                    <td>{item.region}</td>
                    <td>{item.address}</td>
                    {/* <td>000000</td>*/}
                    <td>{item.mobile}</td>
                    <td>
                      <div class="btngroup">
                        <span onclick={() => this.updateClick(item)}>修改</span>
                        <span onclick={() => this.deleteClick(item)}>删除</span>
                      </div>
                    </td>
                    {item.default ? (
                      <td>默认地址</td>
                    ) : (
                      <td>
                        <span
                          class="setAddress"
                          onclick={() => this.updateDefault(item)}
                        >
                          设置默认地址
                        </span>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.addressBox {
  padding: 24px;
  background: #ffffff;
  margin-left: 23px;
  border-radius: 16px;
  max-width: 882px;
  width: 882px;
  box-sizing: border-box;
  .main {
    padding: 20px 0;
    .addAddressBox {
      padding-bottom: 20px;
      border-bottom: 1px solid #c2c6cc;
      .titleBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title {
          color: #000000;
          font-size: 20px;
          font-weight: 400;
        }
        .tip {
          font-size: 16px;
          font-weight: 400;
          color: #a4aab3;
        }
      }
      .formBox {
        margin-top: 30px;
        .form-item {
          display: flex;
          margin-bottom: 23px;
          .label {
            width: 90px;
            height: 30px;
            line-height: 30px;
            font-size: 20px;
            text-align: right;
          }
          .label.required {
            &::before {
              display: inline;
              content: "*";
              color: #f94164;
            }
          }
          .inputBox {
            margin-left: 29px;
            border: 1px solid #a4aab2;
            border-radius: 10px;
            display: flex;
            width: 470px;
            padding: 0 30px;
            .error-box {
              display: flex;
              width: 100%;
            }
            input {
              height: 30px;
              line-height: 30px;
              font-size: 16px;
              font-weight: 400;
              color: #a4aab3;
              border: 0;
              outline: none;
              width: 100%;
            }
          }
          .inputBox.picker {
            height: 300px;
            overflow-y: scroll;
            .distpicker-address-wrapper {
              width: 100%;
            }
          }
        }
      }
      .setDefaultBox {
        margin-left: 140px;
        width: fit-content;
        display: flex;
        cursor: pointer;
        align-items: center;
        line-height: 20px;
        .icon-check {
          display: block;
          width: 20px;
          height: 20px;
          background-image: url("../../../../assets/images/icon-check.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          margin-right: 5px;
        }
        .icon-check.no {
          background-image: url("../../../../assets/images/icon-nocheck.png");
        }
      }
      .btnBox {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;
        .save {
          display: block;
          height: 46px;
          line-height: 46px;
          border-radius: 10px;
          background: #006eeb;
          color: #ffffff;
          font-weight: 500;
          font-size: 20px;
          width: 181px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
    .tableBox {
      padding-top: 20px;
      p {
        font-size: 20px;
        color: #000000;
        font-weight: 400;
      }
      .el-table {
        margin-top: 20px;
      }
    }
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #c2c6cc;
  width: 100%;
  margin-top: 20px;
  tr {
    border-bottom: 1px solid #c2c6cc;
  }
  thead {
    background: #f3f6fa;
    text-align: center;
    vertical-align: bottom;
    line-height: 46px;
    color: #000000;
    font-size: 18px;
    height: 46px;
    th {
      font-weight: 400;
      border-right: 1px solid #c2c6cc;
      &:last-child {
        border-right: 0;
      }
    }
  }

  td {
    text-align: center;
    border-right: 1px solid #c2c6cc;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    .btngroup {
      background: #e5f0fd;
      height: 28px;
      line-height: 28px;
      margin: 0 7px;
      padding: 0 5px;
      border-radius: 10px;
      span {
        font-size: 16px;
        font-weight: 400;
        color: #006eeb;
        margin-right: 14px;
        line-height: 28px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
          margin-left: 14px;
          position: relative;
          &::after {
            content: "";
            display: block;
            width: 1px;
            height: 19px;
            background: #006eeb;
            left: -14px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
    .setAddress {
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      color: #006eeb;
    }
  }
}
.error {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #f84164;
  line-height: 30px;
  width: 100%;
  justify-content: flex-end;
  .icon.info {
    width: 15px;
    height: 15px;
    background-image: url("../../../../assets/images/error-info.png");
    margin-right: 4px;
  }
}
</style>