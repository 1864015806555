
<script>
import BreadCrumbs from "@components/BreadCrumbs.vue";
import VideoInfo from "./videoInfo";
import Describe from "./describe";
import Series from "./series";
import Speaker from "./speaker";
import Loading from "@components/Loading";
import MoreLive from "./moreLive";
import { randomNumber,GetQueryString,urldelCode,urlNocanshu } from "../../utils/index.js";
export default {
  name: "liveDetail",
  data() {
    return {
      pvId:randomNumber(),
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    if (window.$cookies.get("token")) {
        urldelCode()
    }
    const { id } = this.$route.query;
    if (window.$cookies.get("token")) {
      try {
        await this.$store.dispatch("MyModel/getUserInfo");
      } catch (error) {
          if (error.code == 1016) {
            this.$message({ showClose: true, message: error.msg, type: "error" });
            window.$cookies.set("token",'')
          }
          return;
        }
    }
    try {
      await this.$store.dispatch("LiveDetailModel/getliveDetal", { id });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    try {
      await this.$store.dispatch("LiveDetailModel/getRelevantLiveList", { id });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    // const { detailData } = this.$store.state.LiveDetailModel;
    // const { detail } = detailData;
    // const { isSubscribe } = detail;

    // try {
    //   await this.$store.dispatch("LiveDetailModel/getLiveUrl", { id });
    // } catch (msg) {
    //   this.$message({ showClose: true, message: msg, type: "error" });
    //   return;
    // }

    try {
        await this.$store.dispatch("LiveDetailModel/recordAllPage", {
          app:3, 
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pvId:this.pvId,
          medium: window.$cookies.get("datachannelmed") || '',
          extendurl: window.$cookies.get("dataurl") || '',
          pageurl:urlNocanshu(),
          fullpageurl:window.location.href,
          accountId:window.$cookies.get("accountId") || ''
        });
      } catch (msg) {
        // this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    const { detailData } = this.$store.state.LiveDetailModel;
    const { detail } = detailData;
    const { name } = detail;
    try {
      await this.$store.dispatch("LiveDetailModel/recordDetailPage", {
        app:3, 
        module:1,
        datatype:1,
        contentTitle:name,
        uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
        pvId:this.pvId,
        project:'',
        country:'',
        contentId: id,
        medium: window.$cookies.get("datachannelmed") || '',
        extendurl: window.$cookies.get("dataurl") || '',
        pageurl:urlNocanshu(),
        accountId:window.$cookies.get("accountId") || ''
      });
    } catch (msg) {
        // this.$message({ showClose: true, message: msg, type: "error" });
        return;
    }
  },
  render() {
    const {
      isLoadingShow,
      detailData,
      liveList,
    } = this.$store.state.LiveDetailModel;
    const { detail, teachers, lives } = detailData;
    return (
      <div class="container">
        <BreadCrumbs />
        <div class="main">
          <div class="left">
            {detail && <VideoInfo data={detail} />}
            {detail && detail.mode === 2 ? <Series list={lives} /> : null}
            {detail && <Describe data={detail.introduction} />}
          </div>
          <div class="right">
            {teachers && teachers.length ? <Speaker list={teachers} /> : null}
            {liveList.length ? <MoreLive list={liveList} /> : null}
          </div>
        </div>
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 1100px;
  margin: 0 auto;
  .main {
    display: flex;
    padding-bottom: 64px;
    .left {
      max-width: 798px;
      display: flex;
      flex-direction: column;
    }
    .right {
      margin-left: 24px;
      max-width: 278px;
      width: 278px;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>