<script>
import ModelBoxMy from "@components/ModelBoxMy.vue";
import Pagination from "@components/Pagination.vue";
import Item from "./Item.vue";
import eicdata1empty from "@components/eicdata1empty.vue";
export default {
  name: "orderList",
  data() {
    return {
      pageSize: 5,
      secondTabBarData: {
        list: [
          { id: 0, value: "全部" },
          { id: 1, value: "待支付" },
          { id: 8, value: "已完成" },
          { id: 9, value: "已失效" },
        ],
        nowTab: 0,
      },
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("MyModel/getUserOrderList", {
        state: this.secondTabBarData.nowTab,
        page: 1,
        pageSize: this.pageSize,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  methods: {
    async onSecondTabClick(record) {
      try {
        await this.$store.dispatch("MyModel/getUserOrderList", {
          state: record.id,
          page: 1,
          pageSize: this.pageSize,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
  },
  render() {
    const { orderList, orderListCount } = this.$store.state.MyModel;
    const paginationObj = {
      totalPage: Math.ceil(orderListCount / this.pageSize),
      maxButton: 5,
      pageChange: async (record) => {
        try {
          await this.$store.dispatch("MyModel/getUserOrderList", {
            state: this.secondTabBarData.nowTab,
            page: record,
            pageSize: this.pageSize,
          });
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      },
    };
    const modelData = {
      blue: "我的",
      yellow: "订单",
      secondTabBar: this.secondTabBarData,
      onsecondtabclick: this.onSecondTabClick,
      content: () => (
        <div>
          <div class="list">
            {orderList.map((item) => (
              <Item data={item} />
            ))}
          </div>
          {orderListCount > this.pageSize ? (
            <div class="paginationBox">
              <Pagination paginationObj={paginationObj} />
            </div>
          ) : null}
          {orderListCount == 0 ? (
            <div>
              <eicdata1empty/>
            </div>
          ) : null}
        </div>
      ),
    };
    return <ModelBoxMy data={modelData} />;
  },
};
</script>
<style lang="less" scoped>
.paginationBox {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>