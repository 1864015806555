<script>
import { debounce } from "../../../utils/index.js";
export default {
  name: "searchArea",
  methods: {
    async onInput(e) {
      const { searchData, pageSize } = this.$store.state.UniversityListModel;
      this.$store.commit("UniversityListModel/updateState", {
        searchData: { ...searchData, keyword: e.target.value },
      });

      try {
        await this.$store.dispatch("UniversityListModel/getSchoolList", {
          ...searchData,
          keyword: e.target.value,
          page: 1,
          pageSize,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },

    // 国家条件筛选
    async onCountryTagClick(record) {
      const { searchData, pageSize } = this.$store.state.UniversityListModel;
      this.$store.commit("UniversityListModel/updateState", {
        searchData: {
          ...searchData,
          countryId: record.id,
        },
      });

      try {
        await this.$store.dispatch("UniversityListModel/getSchoolList", {
          ...searchData,
          countryId: record.id,
          page: 1,
          pageSize,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },

    // 学校排名条件筛选
    async onRankClick(record) {
      const { searchData, pageSize } = this.$store.state.UniversityListModel;
      this.$store.commit("UniversityListModel/updateState", {
        searchData: {
          ...searchData,
          rankingType: record.id,
        },
      });

      try {
        await this.$store.dispatch("UniversityListModel/getSchoolList", {
          ...searchData,
          rankingType: record.id,
          page: 1,
          pageSize,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },

    // 学校类别条件筛选
    async onSchoolTypeClick(record) {
      const { searchData, pageSize } = this.$store.state.UniversityListModel;
      this.$store.commit("UniversityListModel/updateState", {
        searchData: {
          ...searchData,
          categoryId: record.value,
        },
      });

      try {
        await this.$store.dispatch("UniversityListModel/getSchoolList", {
          ...searchData,
          categoryId: record.value,
          page: 1,
          pageSize,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },

    // 区间点击事件
    async onRankingIntervalClick(record) {
      const { searchData, pageSize } = this.$store.state.UniversityListModel;
      this.$store.commit("UniversityListModel/updateState", {
        searchData: {
          ...searchData,
          rankingInterval: record.id,
        },
      });
      try {
        await this.$store.dispatch("UniversityListModel/getSchoolList", {
          ...searchData,
          rankingInterval: record.id,
          page: 1,
          pageSize,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },

    // 学校性质点击事件
    async onNatureTypeClick(record) {
      const { searchData, pageSize } = this.$store.state.UniversityListModel;
      this.$store.commit("UniversityListModel/updateState", {
        searchData: {
          ...searchData,
          nature: record.value,
        },
      });
      try {
        await this.$store.dispatch("UniversityListModel/getSchoolList", {
          ...searchData,
          nature: record.value,
          page: 1,
          pageSize,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
  },
  render() {
    const {
      countryList,
      rankingTypeList,
      schoolTypeList,
      natureTypeList,
      rankingIntervalList,
      searchData,
    } = this.$store.state.UniversityListModel;
    return (
      <div class="box">
        <div class="searchItem" style={{ alignItems: "center" }}>
          <span class="label black">您已输入</span>
          <div class="inputBox">
            <input onInput={debounce((e) => this.onInput(e), 500)} />
          </div>
        </div>
        <div class="searchItem">
          <span class="label blue">国家/地区</span>
          <div class="tagGroup">
            {countryList.map((item) => (
              <span
                class={`tag ${
                  item.id === searchData.countryId ? "active" : ""
                }`}
                onclick={() => this.onCountryTagClick(item)}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div class="searchItem">
          <span class="label blue">类别</span>
          <div class="tagGroup">
            {schoolTypeList.map((item) => (
              <span
                class={`tag ${
                  item.value === searchData.categoryId ? "active" : ""
                }`}
                onclick={() => this.onSchoolTypeClick(item)}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div class="searchItem">
          <span class="label blue">排名</span>
          <div class="tagGroup">
            {rankingTypeList.map((item) => (
              <span
                class={`tag ${
                  item.id === searchData.rankingType ? "active" : ""
                }`}
                onclick={() => this.onRankClick(item)}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div class="searchItem">
          <span class="label blue">学校性质</span>
          <div class="tagGroup">
            {natureTypeList.map((item) => (
              <span
                class={`tag ${
                  item.value === searchData.nature ? "active" : ""
                }`}
                onclick={() => this.onNatureTypeClick(item)}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div class="searchItem">
          <span class="label blue">快速筛选</span>
          <div class="tagGroup">
            {rankingIntervalList.map((item) => (
              <span
                class={`tag ${
                  item.id === searchData.rankingInterval ? "active" : ""
                }`}
                onclick={() => this.onRankingIntervalClick(item)}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.box {
  padding: 24px;
  background: #fff;
  border-radius: 16px;
  .searchItem {
    display: flex;
    align-items: baseline;
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
    .inputBox {
      width: 260px;
      height: 32px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      background: #f3f6fa;
      input {
        margin-left: 15px;
        color: #333333;
      }
    }
    .tagGroup {
      display: flex;
      flex-wrap: wrap;
      .tag {
        color: #333333;
        cursor: pointer;
        font-size: 14px;
        padding: 0 20px;
        height: 32px;
        line-height: 32px;
        margin-bottom: 5px;
      }
      .tag.active {
        background: #f0f7ff;
        border-radius: 8px;
        color: #006eeb;
        font-weight: bold;
      }
    }
    .label {
      display: block;
      min-width: 80px;
      text-align: left;
      font-size: 18px;
      margin-right: 25px;
      white-space: nowrap;
    }
    .label.black {
      color: #1a1a1a;
    }
    .label.blue {
      color: #006eeb;
    }
  }
}
</style>