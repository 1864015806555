<script>
import ModelBox from "@components/ModelBox";
import Item from "./item";
import Swiper from "swiper";
import 'swiper/dist/css/swiper.min.css';
export default {
  name: "tabModel",
  data() {
    return {
      tabbarData: {
        list: [
          // 此ID根据枚举接口获取,不可更改
          { id: "35", value: "院校速览" },
          { id: "36", value: "专业解读" },
          { id: "23", value: "申请攻略" },
          { id: "37", value: "学生体验" },
        ],
        nowTab: "35",
      },
    };
  },
  async mounted() {
    const { id } = this.$route.query;
    try {
      await this.$store.dispatch(
        "UniversityDetail/getSchoolRelevancyCourseList",
        { id, categoryId: this.tabbarData.nowTab,pageSize:100}
      );
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    // new Swiper ('.swiper-container', {
    //   slidesPerView: 4,
    //   slidesPerColumn: 2,
    //   spaceBetween: 30,
    //   observer: true,//修改swiper自己或子元素时，自动初始化swiper
    //   observeParents: true,
    //   pagination: '.swiper-pagination',
    //   nextButton: '.swiper-button-next',
    //   prevButton: '.swiper-button-prev',
    // })
  },
  methods: {
    async onTabClick(record) {
      const { id } = this.$route.query;
      try {
        await this.$store.dispatch(
          "UniversityDetail/getSchoolRelevancyCourseList",
          { id, 
            categoryId: record.id,
            pageSize:100
          }
        );
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
    },
  },
  render() {
    const { relevancyCourseList } = this.$store.state.UniversityDetail;
    const modelBoxData = {
      tabbar: this.tabbarData,
      ontabclick: this.onTabClick,
      content: () => (
        <div class="main">
          <div class="list">
              {relevancyCourseList.map((item) => (
                <Item data={item} />
              ))}
          </div>
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>
<style lang="less" scoped>
.swiper-container {
    margin-left: 0; 
}
.main {
  margin-top: 20px;
  .list {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>