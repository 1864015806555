<script>
import * as api from "@src/service/index";
import { debounce } from "../../../utils/index.js";
export default {
  name: "searchArea",
  methods: {
    // 国家标签切换
    async onCountryTagClick(record) {
      const { searchData } = this.$store.state.TeachListModel;
      this.$store.commit("TeachListModel/updateState", {
        searchData: {
          ...searchData,
          countryId: record.id,
        },
        isLoadingShow: true,
      });
      await this.$store.dispatch("TeachListModel/getTeacherList", {
        ...searchData,
        countryId: record.id,
        page: 1,
        pageSize: 10,
      });
      this.$store.commit("TeachListModel/updateState", {
        isLoadingShow: false,
      });
    },

    //名师分类标签切换
    async onTeachTypeTagClick(record) {
      const { searchData, pageSize } = this.$store.state.TeachListModel;
      this.$store.commit("TeachListModel/updateState", {
        searchData: {
          ...searchData,
          categoryId: record.value,
        },
        isLoadingShow: true,
      });
      await this.$store.dispatch("TeachListModel/getTeacherList", {
        ...searchData,
        categoryId: record.value,
        page: 1,
        pageSize,
      });
      this.$store.commit("TeachListModel/updateState", {
        isLoadingShow: false,
      });
    },

    // 专业方向tag 切换
    async onProfessionalClick(record) {
      const { searchData, pageSize } = this.$store.state.TeachListModel;
      this.$store.commit("TeachListModel/updateState", {
        searchData: {
          ...searchData,
          professionalId: record.value,
          isLoadingShow: true,
        },
      });
      await this.$store.dispatch("TeachListModel/getTeacherList", {
        ...searchData,
        professionalId: record.value,
        page: 1,
        pageSize,
      });
      this.$store.commit("TeachListModel/updateState", {
        isLoadingShow: false,
      });
    },

    // 搜索框输入事件
    async onInput(e) {
      const { searchData, pageSize } = this.$store.state.TeachListModel;
      this.$store.commit("TeachListModel/updateState", {
        searchData: { ...searchData, keyword: e.target.value },
      });
      this.$store.commit("TeachListModel/updateState", { isLoadingShow: true });
      await this.$store.dispatch("TeachListModel/getTeacherList", {
        ...searchData,
        keyword: e.target.value,
        page: 1,
        pageSize,
      });
      this.$store.commit("TeachListModel/updateState", { isLoadingShow: true });
    },
  },
  render() {
    const {
      countryList,
      teacherTypeList,
      professionalList,
      searchData,
    } = this.$store.state.TeachListModel;
    return (
      <div class="box">
        <div class="searchItem" style={{ alignItems: "center" }}>
          <span class="label black">您已输入</span>
          <div class="inputBox">
            <input onInput={debounce((e) => this.onInput(e), 500)} />
          </div>
        </div>
        <div class="searchItem">
          <span class="label blue">国家/地区</span>
          <div class="tagGroup">
            {countryList.map((item) => (
              <span
                class={`tag ${
                  item.id === searchData.countryId ? "active" : ""
                }`}
                onclick={() => this.onCountryTagClick(item)}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div class="searchItem">
          <span class="label blue">专业方向</span>
          <div class="tagGroup">
            {professionalList.map((item) => (
              <span
                class={`tag ${
                  item.value === searchData.professionalId ? "active" : ""
                }`}
                onclick={() => this.onProfessionalClick(item)}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div class="searchItem">
          <span class="label blue">名师分类</span>
          <div class="tagGroup">
            {teacherTypeList.map((item) => (
              <span
                class={`tag ${
                  item.value === searchData.categoryId ? "active" : ""
                }`}
                onclick={() => this.onTeachTypeTagClick(item)}
              >
                {item.name}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.box {
  padding: 24px;
  background: #fff;
  border-radius: 16px;
  .searchItem {
    display: flex;
    align-items: baseline;
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
    .inputBox {
      width: 260px;
      height: 32px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      background: #f3f6fa;
      input {
        margin-left: 15px;
        color: #333333;
      }
    }
    .tagGroup {
      display: flex;
      flex-wrap: wrap;
      .tag {
        color: #333333;
        cursor: pointer;
        font-size: 14px;
        padding: 0 20px;
        height: 32px;
        line-height: 32px;
        margin-bottom: 5px;
      }
      .tag.active {
        background: #f0f7ff;
        border-radius: 8px;
        color: #006eeb;
        font-weight: bold;
      }
    }
    .label {
      display: block;
      min-width: 80px;
      text-align: left;
      font-size: 18px;
      margin-right: 25px;
      white-space: nowrap;
    }
    .label.black {
      color: #1a1a1a;
    }
    .label.blue {
      color: #006eeb;
    }
  }
}
</style>