<script>
export default {
  name: "SchoolItem",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="school-item">
        <img src={this.data.coverPhoto} />
        <div class="content-box">
          <div class="info-box">
            <p class="title">{this.data.name}</p>
            <p class="des">{this.data.abstract}</p>
            <div class="rank">
              <span class="red">QS排名:{this.data.QsRanking}</span>
              <span class="yellow">USNEWS排名:{this.data.UsnewsRanking}</span>
            </div>
            <div class="tags">
              {this.data.tags &&
                this.data.tags.split(",").map((item) => <span>{item}</span>)}
            </div>
          </div>
          <div class="btnBox">
            <span
              class="btn"
              onclick={() =>
                this.$router.push({
                  path: `/university/detail?id=${this.data.id}`,
                })
              }
            >
              学院详情
            </span>
          </div>
        </div>
      </div>
    );
  },
};
</script>

<style lang="less" scoped>
.school-item {
  display: flex;
  padding: 16px;
  background: #f4f9ff;
  margin-bottom: 24px;
  border-radius: 16px;
  img {
    width: 124px;
    height: 154px;
    object-fit: contain;
  }
  .content-box {
    width: 100%;
    display: flex;
    margin-left: 17px;
    justify-content: space-between;
    .info-box {
      width: 442px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        font-size: 20px;
        font-weight: 400;
        color: #262626;
      }
      .des {
        font-size: 14px;
        margin-top: 8px;
        color: #8a9099;
        font-weight: 400;
      }
      .rank {
        width: 261px;
        height: 31px;
        background: #ffffff;
        border-radius: 8px;
        padding: 0 14px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-size: 16px;
          font-weight: 400;
          position: relative;
        }

        span.red {
          color: #f94164;
          padding-right: 12px;
          &::before {
            display: block;
            content: "";
            width: 1px;
            height: 17px;
            background: #c4cbd2;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        span.yellow {
          color: #ff833e;
          margin-left: 13px;
        }
      }
      .tags {
        display: flex;
        align-items: center;
        margin-top: 10px;
        span {
          padding: 0 9px;
          height: 23px;
          line-height: 23px;
          font-size: 16px;
          color: #006eeb;
          border: 1px solid #006eeb;
          border-radius: 8px;
        }
      }
    }

    .btnBox {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .btn {
        min-width: 116px;
        padding: 0 20px;
        box-sizing: border-box;
        height: 30px;
        background: #066eeb;
        border-radius: 8px;
        line-height: 30px;
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        color: #ffffff;
        &::after {
          content: ">";
          margin-left: 5px;
        }
      }
    }
  }
}
</style>