<script>
export default {
  name: "teahcerOnline",
  props: {
    live: Boolean,
    data: Object,
  },
  render() {
    return (
      <div class="item-box">
        <div class="top">
          <div class="userInfo-box">
            <img src={this.data.head} />
            <div class="useInfo">
              <p class="name">{this.data.teacherName}</p>
              <p class="des">{this.data.abstract}</p>
            </div>
          </div>
          {this.data.nowTab === "8" ? null : (
            <a
              class="btn"
              target="_bank"
              href="https://live800.eiceducation.com.cn/live800/chatClient/chatbox.jsp?companyID=8940&configID=79"
            >
              预约咨询
            </a>
          )}
        </div>
        <div class="bottom">
          <div class="imgBox">
            <img
              src={this.data.coverPhoto}
              onclick={() =>
                this.$router.push({
                  path: `/live/detail?id=${this.data.id}`,
                })
              }
            />
            {this.live ? (
              <div class="shadow">
                <i class="icon-live-text" />
                <div class="people">
                  <i class="icon-people" />
                  {this.data.subscribeCount}人预约
                </div>
              </div>
            ) : (
              <div class="shadow">
                <div class="watch">
                  <i class="icon-eye" />
                  <p>8000人已观看</p>
                </div>
              </div>
            )}
          </div>
          <div class="content-box">
            <div class="info-box">
              <p class="title">{this.data.name}</p>
              {this.live ? (
                <p class="time">
                  <i class="icon-time" />
                  {this.$moment
                    .unix(this.data.liveTime)
                    .format("YYYY-MM-DD HH:mm:ss")}
                </p>
              ) : (
                <p class="time">
                  <i class="icon-play" />
                  直播回放 or 课程
                </p>
              )}
            </div>
            <div class="tags">
              {this.data.tags &&
                this.data.tags.split(",").map((item) => <span>{item}</span>)}
            </div>
          </div>
        </div>
      </div>
    );
  },
};
</script>

<style lang="less" scoped>
.item-box {
  width: 514px;
  max-width: 514px;
  background: #f0f7ff;
  border-radius: 16px;
  padding: 18px;
  display: flex;
  margin-right: 24px;
  box-sizing: border-box;
  flex-direction: column;
  margin-bottom: 24px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .userInfo-box {
      display: flex;
      img {
        width: 58px;
        height: 58px;
        object-fit: cover;
        border-radius: 50%;
      }
      .useInfo {
        display: flex;
        flex-direction: column;
        margin-left: 17px;
        width: 270px;
        .name {
          font-size: 20px;
          font-weight: 400;
          color: #000000;
        }
        .des {
          color: #8a9099;
          font-size: 16px;
          font-weight: 400;
          margin-top: 7px;
          white-space: nowrap; /* 规定文本是否折行 */
          overflow: hidden; /* 规定超出内容宽度的元素隐藏 */
          text-overflow: ellipsis;
        }
      }
    }
    .btn {
      display: block;
      height: 36px;
      background: #006eeb;
      text-decoration: none;
      border-radius: 18px;
      line-height: 36px;
      padding: 0 20px;
      font-size: 16px;
      cursor: pointer;
      color: #ffffff;
    }
  }
  .bottom {
    display: flex;
    margin-top: 10px;
    .imgBox {
      width: 227px;
      height: 144px;
      min-width: 227px;
      overflow: hidden;
      position: relative;
      border-radius: 16px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
      .shadow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 42px;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        padding: 17px 12px 0px 12px;
        box-sizing: border-box;
        .icon-live-text {
          display: block;
          width: 37px;
          height: 13px;
          background-image: url("../../../assets/images/live-text.png");
          background-size: 100%;
          background-repeat: no-repeat;
        }
        .people {
          display: flex;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          .icon-people {
            display: block;
            width: 17px;
            height: 17px;
            background-image: url("../../../assets/images/icon-people.png");
            background-size: 100%;
            background-repeat: no-repeat;
            margin-right: 8px;
          }
        }
        .watch {
          display: flex;
          align-items: center;
          .icon-eye {
            width: 16px;
            height: 13px;
            display: block;
            background-image: url("../../../assets/images/icon-eye.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-right: 8px;
          }
          p {
            color: #ffffff;
            font-size: 14px;
            font-weight: 300;
          }
        }
      }
    }
    .content-box {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .info-box {
        display: flex;
        flex-direction: column;
        .title {
          font-size: 18px;
          font-weight: 400;
          color: #2b2b2b;
          margin-top: 6px;
        }
        .time {
          margin-top: 15px;
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          color: #a4aab3;
          .icon-time {
            display: block;
            width: 17px;
            height: 17px;
            background-image: url("../../../assets/images/time.png");
            background-size: 100%;
            background-repeat: no-repeat;
            margin-right: 4px;
          }
          .icon-play {
            display: block;
            width: 17px;
            height: 17px;
            background-image: url("../../../assets/images/play.png");
            background-size: 100%;
            background-repeat: no-repeat;
            margin-right: 4px;
          }
        }
      }
      .tags {
        display: flex;
        span {
          display: block;
          border: 1px solid #006eeb;
          border-radius: 8px;
          font-size: 14px;
          font-weight: 300;
          color: #006eeb;
          height: 24px;
          line-height: 24px;
          padding: 0 11px;
          margin-right: 8px;
        }
      }
    }
  }
  &:nth-child(2n) {
    margin-right: 0;
  }
}
</style>