<script>
export default {
  name: "teacherItem",
  props: {
    data: Object,
  },
  methods: {
    toDetail(type, id, item) {
			// console.log(type, id, item)
    	if (type == 11 && item == 0) {
    		return
    	}
    	  this.$router.push({
    	    path: `/teacher/detail?id=${this.data.Id}`,
    	  })
    },
  },
  render() {
    return (
      <div class="teacherItem">
        <div class="photo">
          <img
            src={this.data.ImageUrls}
            onclick={() => this.toDetail(11,this.data.Id,this.data.R1)}
          />
        </div>
        <div class="content">
          <div class="infoBox">
            <p class="teachName">{this.data.Title}</p>
            <p class="teachTitle">{this.data.Introduction}</p>
          </div>
          {/*
          <div class="classBox">
            <div class="item">
              <div class="className">
                <i class="icon-play" />
                <span>解锁日本语言学校留学，宝藏升学...</span>
              </div>
              <div class="price free">免费</div>
            </div>
            <div class="item">
              <div class="className">
                <i class="icon-play" />
                <span>
                  解锁日本语言学校留学，宝藏升学...解锁日本语言学校留学，宝藏升学...
                </span>
              </div>
              <div class="price">¥ 9.9</div>
            </div>
          </div>
           */}
          <div
            class="allClass"
            onclick={() => this.toDetail(11,this.data.Id,this.data.R1)}
          >
            {"老师全部课程 >"}
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.teacherItem {
  width: 514px;
  background: #f0f7ff;
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-right: 24px;
  margin-bottom: 24px;

  &:nth-child(2n) {
    margin-right: 0;
  }

  &:nth-last-child(1) {
    margin-bottom: 0px;
  }

  .photo {
    width: 124px;
    height: 154px;
    overflow: hidden;
    border-radius: 8px;

    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
      object-fit: cover;
      background: #fff;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .infoBox {
      display: flex;
      flex-direction: column;
      width: 340px;

      .teachName {
        font-size: 20px;
        font-weight: 400;
        color: #262626;
      }

      .teachTitle {
        margin-top: 5px;
        font-size: 14px;
        font-weight: 400;
        color: #8a9099;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 300px;
      }
    }

    .classBox {
      display: flex;
      flex-direction: column;
      width: 340px;
      margin-top: 15px;

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0px;
        }

        .className {
          display: flex;
          align-items: center;

          .icon-play {
            display: block;
            width: 15px;
            height: 15px;
            background-image: url("../../assets/images/icon-player.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-right: 7px;
          }

          span {
            width: 250px;
            font-size: 16px;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #262626;
          }
        }

        .price {
          font-size: 16px;
          font-weight: bold;
          color: #ff833e;
        }

        .price.free {
          color: #51b3ac;
        }
      }
    }

    .allClass {
      background: #006eeb;
      border-radius: 12px;
      font-weight: 400;
      color: #ffffff;
      font-size: 14px;
      height: 24px;
      padding: 0 13px;
      line-height: 24px;
      width: fit-content;
      cursor: pointer;
      margin-top: 14px;
    }
  }
}
</style>