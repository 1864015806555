
<script>
import Loading from "@components/Loading";
import BreadCrumbs from "@components/BreadCrumbs.vue";
import Abstract from "./abstract";
import Education from "./education";
import Policy from "./policy";
import Study from "./study";
import { randomNumber,GetQueryString,urldelCode,urlNocanshu } from "../../utils/index.js";
export default {
  name: "CountryDetail",
  async mounted() {
    window.scrollTo(0, 0);
    if (window.$cookies.get("token")) {
        urldelCode()
    }
    const { id, pageInfoId } = this.$route.query;
    try {
      await this.$store.dispatch("CountryDetailModel/getCountryDetail", {
        id,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    try {
      await this.$store.dispatch("CountryDetailModel/getPageInfoList", {
        id: pageInfoId,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "success" });
      return;
    }
    try {
        await this.$store.dispatch("CountryDetailModel/recordAllPage", {
          app:3, 
          uvId:window.$cookies.get("openId") || window.$cookies.get("uuid"),
          pvId:randomNumber(),
          medium: window.$cookies.get("datachannelmed") || '',
          extendurl: window.$cookies.get("dataurl") || '',
          pageurl:urlNocanshu(),
          fullpageurl:window.location.href,
          accountId:window.$cookies.get("accountId") || ''
        });
    } catch (msg) {
      // this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  render() {
    const {
      isLoadingShow,
      detailData,
      encyclopediaList,
      eduSystemList,
      newPolicyList,
    } = this.$store.state.CountryDetailModel;
    return (
      <div class="container">
        <BreadCrumbs />
        <div class="main">
          {detailData ? (
            <div class="banner">
              <img src={detailData.coverPhoto_PC} />
              <div class="content-box">
                <div class="title-box">
                  <h3>{detailData.name}</h3>
                  <i class="icon address" />
                </div>
                <p>{detailData.enname}</p>
                <div class="tags">
                  {detailData.tags &&
                    detailData.tags
                      .split(",")
                      .map((item) => <span>{item}</span>)}
                </div>
              </div>
            </div>
          ) : null}
          {detailData ? <Abstract /> : null}
          {eduSystemList.length ? <Education list={eduSystemList} /> : null}
          {newPolicyList.length ? <Policy list={newPolicyList} /> : null}
          {encyclopediaList.length ? <Study list={encyclopediaList} /> : null}
        </div>
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 1100px;
  margin: 0 auto;
  .main {
    display: flex;
    flex-direction: column;
    .banner {
      width: 100%;
      height: 400px;
      border-radius: 16px;
      overflow: hidden;
      position: relative;
      margin-bottom: 24px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .content-box {
        position: absolute;
        display: flex;
        top: 32px;
        left: 38px;
        flex-direction: column;

        .title-box {
          display: flex;
          align-items: center;
          h3 {
            font-size: 60px;
            font-weight: 400;
            color: #ffffff;
            margin-right: 24px;
          }
          .icon.address {
            width: 25px;
            height: 30px;
            background-image: url("../../assets/images/address@2x.png");
          }
        }
        p {
          font-size: 38px;
          font-weight: 400;
          color: #ffffff;
          font-style: italic;
          margin-bottom: 14px;
        }
        .tags {
          display: flex;
          span {
            display: block;
            margin-right: 10px;
            padding: 0 12px;
            background: rgba(0, 0, 0, 0.2);
            font-size: 14px;
            font-weight: 400;
            border-radius: 8px;
            height: 24px;
            line-height: 24px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>