<script>
import Pagination from "@components/Pagination.vue";
import PopQr from "@components/PopQr.vue";
import eicdata2empty from "@components/eicdata2empty.vue";
export default {
  name: "reportItem",
  props: {
    data: Object,
  },
  async mounted() {
    try {
      await this.$store.dispatch("MyModel/getBookList", {
        page: 1,
        limit: this.pageSize,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  data() {
    return {
      pageSize: 3,
      isPopQrShow: false,
      id: "",
      type: "" /*type为1，我的奖品留学报告; type为2，直播; type为3，课堂课程*/,
      isView: false, //是否有启德课堂小程序浏览留学报告授权
    };
  },
  methods: {
    async viewReport(id, type) {
      this.isPopQrShow = true;
      this.id = id;
      this.type = type;
      if (window.$cookies.get("token")) {
          await this.$store.dispatch("MyModel/getBookDetail", {
            "X-EICCHANNEL-TOKEN": window.$cookies.get("token"),
            id,
          })
      }
      const { isViewReport } = this.$store.state.MyModel;
      console.log("isViewReport" + ":" + isViewReport)
      this.isView = isViewReport;
      //console.log(this.isView)
    },
  },
  render() {
    const { bookList, bookListCount } = this.$store.state.MyModel;
    const paginationObj = {
      totalPage: Math.ceil(bookListCount / this.pageSize),
      maxButton: 5,
      pageChange: async (record) => {
        try {
          await this.$store.dispatch("MyModel/getBookList", {
            page: record,
            limit: this.pageSize,
          });
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      },
    };
    return (
      <div class="box">
        <div class="reportList">
          {bookList.map((item) => (
            <div class="item">
              <img src={item.ImageUrl} />
              <div class="info">
                <p class="title">{item.Title}</p>
                <p class="des">27800次领取</p>
                <span class="btn" onclick={() => this.viewReport(item.Id, "1")}>
                  立即阅读
                </span>
              </div>
            </div>
          ))}
        </div>
        {bookListCount > this.pageSize ? (
          <div class="paginationBox">
            <Pagination paginationObj={paginationObj} />
          </div>
        ) : null}
        {bookListCount == 0 ? (
            <div>
              <eicdata2empty/>
            </div>
          ) : null}
        {this.isPopQrShow ? (
          <PopQr
            isShow={this.isPopQrShow}
            closeFunc={() => (this.isPopQrShow = false)}
            id={1}
            typeId={this.type}
            title={'微信扫一扫，立即阅读'}
            desc={this.isView? ('进入启德课堂小程序阅读报告') : ('进入启德小程序阅读报告')}
            isView = {this.isView}
          />
        ) : null}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.item {
  display: flex;
  border-bottom: 1px solid #e6ebf3;
  padding: 21px 0;
  &:last-child {
    border-bottom: 0;
  }
  img {
    width: 225px;
    height: 126px;
    border-radius: 16px;
    object-fit: contain;
  }
  .info {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      font-weight: 400;
      padding-top: 10px;
    }
    .des {
      font-size: 14px;
      font-weight: 300;
      color: #a4aab3;
      margin-top: 20px;
    }
    .btn {
      margin-top: 25px;
      padding: 0 14px;
      color: #ffffff;
      font-weight: 400;
      font-size: 16px;
      height: 27px;
      line-height: 27px;
      background: #006eeb;
      border-radius: 10px;
      width: 64px;
      cursor: pointer;
    }
  }
}
.paginationBox {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
