<script>
import ModelBox from "@components/ModelBoxMy";
import Item from "./item";
import Pagination from "@components/Pagination.vue";
import Loading from "@components/Loading";
import eicdata1empty from "@components/eicdata1empty.vue";
export default {
  name: "learnRecord",
  data() {
    return {
      pageSize: 6,
      tabbarData: {
        list: [
          { id: "1", value: "课程" },
          { id: "2", value: "直播" },
        ],
        nowTab: "1",
      },
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("MyModel/getUserLearnRecord", {
        type: this.tabbarData.nowTab,
        page: 1,
        pageSize: this.pageSize,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  methods: {
    async onTabClick(record) {
      try {
        await this.$store.dispatch("MyModel/getUserLearnRecord", {
          type: record.id,
          page: 1,
          pageSize: this.pageSize,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.$refs.paginationObj.currentPage = 1
    },
  },
  render() {
    const {
      learnRecordList,
      learnRecordListCount,
      isLoadingShow,
    } = this.$store.state.MyModel;
    const paginationObj = {
      totalPage: Math.ceil(learnRecordListCount / this.pageSize),
      maxButton: 5,
      pageChange: async (record) => {
        try {
          await this.$store.dispatch("MyModel/getUserLearnRecord", {
            type: this.tabbarData.nowTab,
            page: record,
            pageSize: this.pageSize,
          });
        } catch (msg) {
          this.$message({ showClose: true, message: msg, type: "error" });
          return;
        }
      },
    };
    const modelBoxData = {
      blue: "学习",
      yellow: "记录",
      ontabclick: this.onTabClick,
      tabbar: this.tabbarData,
      content: () => (
        <div>
          <div class="list">
    
            {learnRecordList.map((item) => (
              <Item data={{ ...item, nowTab: this.tabbarData.nowTab }} />
            ))}
          </div>
          {learnRecordListCount > this.pageSize ? (
            <div class="paginationBox">
              <Pagination paginationObj={paginationObj} ref="paginationObj" />
            </div>
          ) : null}
          {learnRecordListCount == 0 ? (
            <div>
              <eicdata1empty/>
            </div>
          ) : null}
          <Loading isShow={isLoadingShow} />
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>

<style lang="less" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
}
.paginationBox {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>