
<script>
import ModelBox from "@components/ModelBox";
export default {
  name: "abstract",
  render() {
    const { detailData } = this.$store.state.UniversityDetail;
    const modelBoxData = {
      blue: "学校",
      yellow: "简介",
      content: () => (
        <div class="main">
          <div class="left">
            <img src={detailData.badge} />
            <div class="info-box">
              <p domPropsInnerHTML={detailData.introduction}></p>
              <div class="tags">
                {detailData.tags &&
                  detailData.tags.split(",").map((item) => <span>{item}</span>)}
              </div>
            </div>
          </div>
          <div class="right">
            <div class="rank yellow">
              <h3>QS排名</h3>
              <span>{detailData.QsRanking}</span>
            </div>
            <div class="rank blue">
              <h3>USNEWS</h3>
              <span>{detailData.UsnewsRanking}</span>
            </div>
            <div class="rank red">
              <h3>THE排名</h3>
              <span>{detailData.TheRanking}</span>
            </div>
          </div>
        </div>
      ),
    };
    return <ModelBox data={modelBoxData} />;
  },
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  align-items: flex-start;
  padding-top: 18px;
  .left {
    display: flex;
    margin-right: 22px;
    img {
      width: 89px;
      height: 113px;
      object-fit: contain;
      margin-right: 17px;
    }
    .info-box {
      display: flex;
      flex-direction: column;
      p {
        width: 554px;
        box-sizing: border-box;
        border-radius: 8px;
        background: #f8f8f8;
        padding: 13px 19px 12px 17px;
        font-size: 14px;
        color: #8a9099;
        margin-bottom: 8px;
      }
      .tags {
        display: flex;
        align-items: center;
        span {
          display: block;
          margin-right: 11px;
          line-height: 24px;
          height: 24px;
          background: #d9e9fc;
          font-size: 14px;
          color: #006eeb;
          padding: 0 9px;
          border-radius: 5px;
        }
      }
    }
  }
  .right {
    display: flex;
    .rank {
      display: flex;
      flex-direction: column;
      width: 114px;
      padding-bottom: 2px;
      border-radius: 8px;
      text-align: center;
      margin-right: 14px;
      h3 {
        font-size: 22px;
        color: #ffffff;
        line-height: 40px;
      }
      span {
        display: block;
        border-radius: 8px;
        background: #ffffff;
        font-size: 24px;
        color: #444444;
        margin: 0 2px;
        text-align: center;
        line-height: 35px;
      }
    }
    .rank.yellow {
      background: #ee7837;
    }
    .rank.blue {
      background: #006eeb;
    }
    .rank.red {
      background: #e32c4f;
    }
  }
}
</style>