
<script>
export default {
  name: "mainItem",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="item-box">
        <div class="imgBox">
          <img src={this.data.coverPhoto} onclick={()=>{
            if(this.data.islive){
               this.$router.push({ path: `/live/detail?id=${this.data.id}` })
            }
            if(!this.data.islive){
               this.$router.push({ path: `/course/detail?id=${this.data.id}` })
            }
          }}/>
          {this.data.islive ? (
            <div class="shadow">
              <i class="icon-live-text" />
              <div class="people">
                <i class="icon-people" />
                {this.data.subscribeCount}人预约
              </div>
            </div>
          ) : (
            <div class="shadow">
              <div class="watch">
                <i class="icon-eye" />
                <p>{this.data.viewTimes}人已观看</p>
              </div>
            </div>
          )}

          {/*<i class="icon-pay" />*/}
        </div>
        <div class="content-box">
          <div class="title-box">
            <p>{this.data.name}</p>
            {/*<span>主讲人：Lydia Wang</span>*/}
            {this.data.islive ? (
              <p class="time">
                <i class="icon-time" />
                {this.data.liveTime}
              </p>
            ) : null}
          </div>
          <div class="tags">
            {this.data.tags &&
              this.data.tags.split(",").map((item,index) => <span>{item}</span>)}
          </div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.item-box {
  padding: 16px;
  border-radius: 16px;
  background: #f0f7ff;
  margin-bottom: 16px;
  display: flex;
  .imgBox {
    width: 242px;
    min-width: 242px;
    height: 154px;
    overflow: hidden;
    position: relative;
    .icon-pay {
      display: block;
      width: 53px;
      height: 53px;
      position: absolute;
      top: 0;
      right: 0;
      background-image: url("../../assets/images/pay.png");
      background-size: 100%;
      background-repeat: no-repeat;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
    .shadow {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 42px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      padding: 17px 11px 0px 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .watch {
        display: flex;
        align-items: center;
        .icon-eye {
          width: 16px;
          height: 13px;
          display: block;
          background-image: url("../../assets/images/icon-eye.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 8px;
        }
        p {
          color: #ffffff;
          font-size: 14px;
          font-weight: 300;
        }
      }
      .icon-live-text {
        display: block;
        width: 37px;
        height: 13px;
        background-image: url("../../assets/images/live-text.png");
        background-size: 100%;
        background-repeat: no-repeat;
      }
      .people {
        display: flex;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        .icon-people {
          display: block;
          width: 17px;
          height: 17px;
          background-image: url("../../assets/images/icon-people.png");
          background-size: 100%;
          background-repeat: no-repeat;
          margin-right: 8px;
        }
      }
    }
  }
  .content-box {
    margin-left: 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title-box {
      display: flex;
      flex-direction: column;
      .time {
        margin-top: 15px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        color: #a4aab3;
        .icon-time {
          display: block;
          width: 17px;
          height: 17px;
          background-image: url("../../assets/images/time.png");
          background-size: 100%;
          background-repeat: no-repeat;
          margin-right: 4px;
        }
      }
      p {
        font-size: 18px;
        color: #262626;
        line-height: 22px;
        margin-top: 9px;
        font-weight: bold;
      }
      span {
        display: block;
        margin-top: 12px;
        font-size: 16px;
        color: #a4aab3;
      }
    }

    .tags {
      display: flex;
      margin-top: 10px;
      span {
        display: block;
        padding: 0 9px;
        font-size: 14px;
        font-weight: 400;
        color: #006eeb;
        border: 1px solid #006eeb;
        border-radius: 8px;
        height: 23px;
        box-sizing: border-box;
        margin-right: 8px;
      }
    }
  }
}
</style>