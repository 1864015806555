<script>
  import logo from "@assets/images/eicdata1empty.png";
  export default {
    name: "eicdata1empty",
    props:{
    },
    render() {
      return (
        <div class="background">
          <div> 
            <img class="logo" src={logo} />
            <p>这个家伙很懒  还没开始学习...</p>
          </div>
        </div>
      );
    },
  };
  </script>
  <style lang="less" scoped>
  .background {
    height: 300px;
    text-align: center;
    margin-top: 140px;
  }
  </style>